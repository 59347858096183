import React, { Component, useEffect } from "react";
import LoginHeader from "./Components/LoginHeader";
import Footer from "./Components/Footer";
import axios from "axios";
import { Redirect } from "react-router-dom";
import FormWizard from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css";
import Accordion from "react-bootstrap/Accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faUndo } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert";
import Swal2 from 'sweetalert2';
import * as XLSX from "xlsx";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import jwtEncode from 'jwt-encode';
import { jwtDecode } from 'jwt-decode';
let fs;
if (typeof window === "undefined") {
    fs = require("fs");
}
require("dotenv").config();
class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ip:"",
           uniqueformid:'',
           mycustomcu:
                localStorage.getItem("CustomerType") &&
                localStorage.getItem("CustomerType") == "Credit Union"
                    ? localStorage.getItem("CuDefaultPercentage")
                        ? localStorage.getItem("CuDefaultPercentage")
                        : 6
                    : 6,
            mycustomfu:
                localStorage.getItem("CustomerType") &&
                localStorage.getItem("CustomerType") == "Credit Union"
                    ? localStorage.getItem("CuDefaultPercentage")
                        ? +localStorage.getItem("CuDefaultPercentage") + 0.5
                        : 6.5
                    : 6.5,
            dealerStatus: false,
            getselectr: false,
            vpnbygetquote: false,
            showBankInfo: false,
            coapplicantmember: false,
            msrppercentagedata: "",
            loading: false,
            isvinChecked: false,
            carname: "",
            model: "",
            year: "",
            currentTab: 0,
            totalTabs: 2,
            showCancelMessage: false,
            showAcceptMessage: false,
            quoteStatus: false,
            printStatus: false,
            name: [],
            email: [],
            name1: [],
            email1: [],
            msrpvalue: "",
            selectedOption: "84",
            vinno: "",
            phone: [],
            address: [],
            address2: [],
            state: [],
            city: [],
            zip: [],
            phone1: [],
            address1: [],
            zip1: [],
            state1: [],
            city1: [],
            address21: [],
            pifrate: 6.5, // Initial value for fuse rate
            conventionalrate: 6,
            expandedIndex: null,
            estimatedresidualvalue: "",
            estimatedresidualvalue2: "",
            estimatedresidualvaluemyname: "",
            prevestimatedresidualvalue: "",
            prevestimatedresidualvalue12: "",
            prevestimatedresidualvalue24: "",
            prevestimatedresidualvalue36: "",
            prevestimatedresidualvalue48: "",
            prevestimatedresidualvalue60: "",
            prevestimatedresidualvalue72: "",
            prevestimatedresidualvalue84: "",
            purchasepricevalue: "",
            totalamountfinanced: 0,
            tradeinvalue: "",
            tradeinpayoff: "",
            downpayment: "",
            insurance: "",
            otherfees: "",
            salestax: "",
            salestaxpercentage: "",
            monthlypayment: "",
            monthlypaymentc: "",
            showPopup: false,
            showPopup1: false,
            showcustomerPopup: false,
            showcustomerPopup2: false,
            showReset12: false,
            showReset24: false,
            showReset36: false,
            showReset48: false,
            showReset60: false,
            showReset72: false,
            showReset84: false,
            selectedTerms: "84",
            selectedIndex: "6",
            notes: "",
            pdf_url: "",
            expandedPanel: "panel1",
            yearData: "",
            makeOptions: [],
            selectedMake: "",
            selectedModel: "",
            excelData: [],
            modelOptions: [],
            profitAmount: "",
            cprofitAmount: "",
            profitAmountPercentage: 0.5,
            amazonStatus: "0",
            preApprovedStatus: "1",
            branch:
                localStorage.getItem("CuDefaultBranch") != "" &&
                localStorage.getItem("CuDefaultBranch") != undefined
                    ? localStorage.getItem("CuDefaultBranch")
                    : "",
            cu_referral: "",
            quotedefineStatus: "",
            quotedefineStatus1: "",
            quoteid: "",
            quoteIDs: [],
            suggestions: [],
            isExpanded: false,
            bankData: [],
            selectedBankId: "",
            filteredBankData: [],
            cuimagepath:'',
            signedPdf:null,
            userName:'',
            showSignedPopup:false,
            documentId :null,
            IsEmail:false,
            signingUrl:null,
            docusealemail:null,
            estimateresidualvaluepopup:'',
             isVisibleCalculator: window.innerWidth > 768,
             popupmonth:0,
             cunamedefaultsuperadmin:'',
             cuaddressdefaultsuperadmin:'',
             cuaddress2defaultsuperadmin:'',
             cucitydefaultsuperadmin:'',
             custatedefaultsuperadmin:'',
             cuzipdefaultsuperadmin:''
        };
        this.handleTradeinvalue = this.handleTradeinvalue.bind(this);
        this.handleTradeinpayoff = this.handleTradeinpayoff.bind(this);
        this.handleDownpayment = this.handleDownpayment.bind(this);
        this.handleInsurance = this.handleInsurance.bind(this);
        this.handleAllotherfees = this.handleAllotherfees.bind(this);
        this.handlePurchasevalue = this.handlePurchasevalue.bind(this);
        this.handlePhone = this.handlePhone.bind(this);
        this.handlePhone1 = this.handlePhone1.bind(this);
        this.handleSalestax = this.handleSalestax.bind(this);
        this.handleSalestaxpercentage = this.handleSalestaxpercentage.bind(this);
        this.handleOverrideCheckboxChange =
        this.handleOverrideCheckboxChange.bind(this);
        this.handleVinno = this.handleVinno.bind(this);
        this.handleGetVin = this.handleGetVin.bind(this);
        this.addTextToPage = this.addTextToPage.bind(this);
        this.blobToBase64 = this.blobToBase64.bind(this);
        this.signedblobToBase64 = this.signedblobToBase64.bind(this);
        this.addAgreementInfo = this.addAgreementInfo.bind(this);
        this.addAgreementInfo2 = this.addAgreementInfo2.bind(this);
        this.handleReceiveAmazonChange = this.handleReceiveAmazonChange.bind(this);
        this.handleReceiveAmazonCouponChange = this.handleReceiveAmazonCouponChange.bind(this);
        this.preApprovedChange = this.preApprovedChange.bind(this);
        this.handleQuoteDefineStatusChange =
        this.handleQuoteDefineStatusChange.bind(this);
        this.handleNextClick1Update = this.handleNextClick1Update.bind(this);
        this.handlequoteidchange = this.handlequoteidchange.bind(this);
        this.handleSuggestionClick = this.handleSuggestionClick.bind(this);
        this.handleSelectChangeBank = this.handleSelectChangeBank.bind(this);
        this.handleSignPop = this.handleSignPop.bind(this);
        this.handleSignature = this.handleSignature.bind(this);
        this.closesignedPopup = this.closesignedPopup.bind(this);
        this.toggleCalculator = this.toggleCalculator.bind(this);
       this.logentry = this.logentry.bind(this);
        this.inputRefs = {};
    }
    toggleCalculator = () => {
         this.setState(prevState => ({
                        isVisibleCalculator: !prevState.isVisibleCalculator
                }));
                        };

 logentry = (apiname, requestdata, responsedata, usermemberid, clientip, status) => {
 
 const logData = {
                        apiname: apiname,
                        requestdata: requestdata,
                        responsedata: responsedata,
                        usermemberid: usermemberid,
                        clientip: clientip,
                        status: status,
                    };

   axios
                        .post(
                            `${process.env.REACT_APP_BASE_URL}/authenticationAPI/InsertLog`,
                            logData
                        )
                        .then((res) => {
                            if (res.data.status) 
                            {


                            }
                    });
}



signedblobToBase64 = (blobUrl) => {
    return axios
        .get(blobUrl, { responseType: "arraybuffer" })
        .then((response) => {
            // Convert ArrayBuffer to Base64 string
            const base64data = Buffer.from(response.data).toString("base64");
            return base64data;
        })
        .catch((error) => {
            console.error("Error converting blob to base64:", error);
            throw error;
        });
};


handleSignPop    = (event)=>
{
        this.setState({ showSignedPopup: true })
}

 closesignedPopup = () => {
        this.setState({ showSignedPopup: false });
    }

handleSignature = (signatureData) => {
    this.setState({ userName: localStorage.getItem("signedattach") });
};

    handlequoteidchange = (event) => {
        this.setState({ quoteid: event.target.value }, () => {
            var abcd = event.target.value;
            axios
                .get(
                    `${
                        process.env.REACT_APP_BASE_URL
                    }/authenticationAPI/GetExistingQuoteDetailsById?user_member_id=${localStorage.getItem(
                        "customer_id"
                    )}&auth_token=${localStorage.getItem(
                        "auth_token"
                    )}&quote_id=${String(this.state.quoteid)}`
                )
                .then((res) => {
                    console.log("first check");
                    console.log(res.data);
                    if (res && res.data && res.data.length > 0) {
                        const quoteIDs = res.data.map(
                            (item) =>
                                `${
                                    item.ApplicationID +
                                    "--" +
                                    item.FirstName +
                                    "--" +
                                    item.CarName +
                                    "--" +
                                    item.TotalFinancedAmount
                                }`
                        );
                        this.setState({ quoteIDs });
                        const filteredSuggestions = this.state.quoteIDs.filter((item) =>
                            item.includes(this.state.quoteid)
                        );
                        if (event.target.value !== "") {
                            this.setState({ suggestions: filteredSuggestions });
                        } else {
                            this.setState({ suggestions: [] });
                        }
                        if (res.data.length > 0 && res.data.length < 2) {
                            this.setState({ name: res.data[0].FirstName });
                            this.setState({ email: res.data[0].EmailAddress });
                            this.setState({ address: res.data[0].Address });
                            this.setState({ phone: res.data[0].Phone });
                            this.setState({ address2: res.data[0].Address2 });
                            this.setState({ city: res.data[0].City });
                            this.setState({ zip: res.data[0].Zip });
                            this.setState({ state: res.data[0].State });
                        }
                    } else {
                        this.setState({ suggestions: [] });
                    }
                });
        });
    };

    handleNextClick1Update = () => {
        if (this.state.quoteid.length == 0) {
            Swal({
                text: "Please enter quote id .",
                icon: "error",
                button: "OK",
            });
            return false;
        }
  
        if (this.state.name.length == 0 ) {
            Swal({
                text: "Please enter name.",
                icon: "error",
                button: "OK",
            });
            return false;
        }

        if (this.state.email.length == 0 ) {
            Swal({
                text: "Please enter email.",
                icon: "error",
                button: "OK",
            });
            return false;
        }

        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if (reg.test(this.state.email) === false) {
            Swal({
                text: "Please enter correct email.",
                icon: "error",
                button: "OK",
            });
            return false;
        }

        if (this.state.phone.length == 0 ) {
            Swal({
                text: "Please enter phone.",
                icon: "error",
                button: "OK",
            });
            return false;
        }

        const phoneNumberLength = this.state.phone.length; // Get the length of the phone number string
        if (parseInt(phoneNumberLength) > 12) {
            Swal({
                text: "Please enter a 10-digit phone number.",
                icon: "error",
                button: "OK",
            });
            return false;
        }



if (this.state.coapplicantmember == true && (this.state.name1.length == 0  ||  this.state.name1.every(item => item === ''))) 
        {
            Swal({
                text: "Please enter co applicant name.",
                icon: "error",
                button: "OK",
            });
            return false;
        }

        if (this.state.coapplicantmember == true && (this.state.email1.length == 0 ||  this.state.email1.every(item => item === ''))) {
            Swal({
                text: "Please enter co applicant email.",
                icon: "error",
                button: "OK",
            });
            return false;
        }

     let reg1 = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if (this.state.coapplicantmember == true &&    reg1.test(this.state.email1) === false) {
             Swal({
                text: "Please enter correct co applicant email.",
                icon: "error",
                button: "OK",
            });
            return false;
        }

        if (this.state.coapplicantmember == true && (this.state.phone1.length == 0 ||  this.state.phone1.every(item => item === ''))) {
            Swal({
                text: "Please enter co applicant phone.",
                icon: "error",
                button: "OK",
            });
            return false;
        }

        const phone1NumberLength =    this.state.phone1[0] && this.state.phone1[0].length; // Get the length of the phone number string
        if (this.state.coapplicantmember == true && parseInt(phone1NumberLength) !== 12) {
            Swal({
                text: "Please enter a 10-digit phone number.",
                icon: "error",
                button: "OK",
            });
            return false;
        }

        if (
            this.state.coapplicantmember == true &&
           

(this.state.address1.length == 0 ||  this.state.address1.every(item => item === ''))

        ) {
            Swal({
                text: "Please enter co applicant address.",
                icon: "error",
                button: "OK",
            });
            return false;
        }

        if (this.state.coapplicantmember == true && (this.state.city1.length == 0 ||  this.state.city1.every(item => item === ''))) {
            Swal({
                text: "Please enter co applicant city.",
                icon: "error",
                button: "OK",
            });
            return false;
        }

        if (this.state.coapplicantmember == true && (this.state.state1.length == 0 ||  this.state.state1.every(item => item === ''))) {
            Swal({
                text: "Please enter co applicant state.",
                icon: "error",
                button: "OK",
            });
            return false;
        }
        if (this.state.coapplicantmember == true && (this.state.zip1.length == 0 ||  this.state.zip1.every(item => item === ''))) {
            Swal({
                text: "Please enter co applicant zip.",
                icon: "error",
                button: "OK",
            });
            return false;
        }





        const profitAmount1 = parseFloat(localStorage.getItem("profitAmount"));
        const cprofitAmount1 = parseFloat(localStorage.getItem("cprofitAmount"));
        const profitAmountPercentage1 = profitAmount1 - cprofitAmount1;
        localStorage.setItem("tprofitAmount", profitAmountPercentage1);
        const clientData1 = new URLSearchParams({
            dealer_id: localStorage.getItem("customer_id"),
            auth_token: localStorage.getItem("auth_token"),
            customer_id: "",
            profit_amount: profitAmountPercentage1,
            profit_amount_percentage: this.state.profitAmountPercentage,
            car_name: this.state.carname,
            model: this.state.model,
            year: this.state.year,
            msrp_value: this.state.msrpvalue,
            msrp_percentage: "",
            residual_value: localStorage.getItem("residual_value"),
            purchasepricevalue: this.state.purchasepricevalue,
            trade_in_value: this.state.tradeinvalue,
            trade_in_payoff: this.state.tradeinpayoff,
            downpayments: this.state.downpayment,
            insurance: this.state.insurance,
            salestax: this.state.salestax,
            terms: this.state.selectedTerms,
            total_financed_amount: this.state.totalamountfinanced
                ? parseFloat(this.state.totalamountfinanced).toFixed(2)
                : "",
            conventional_rate: this.state.conventionalrate,
            pif_rate: this.state.pifrate,
            notes: this.state.notes,
            all_other_fees: this.state.otherfees,
            quote_status: this.state.quoteStatus
                ? 2
                : this.state.quoteStatus == true
                ? 1
                : 0,
            vin_number: localStorage.getItem("vinno"),
            rvi_segment: localStorage.getItem("rvisegment"),
            per_month_pif_amount:
                this.state.monthlypayment[this.state.selectedIndex] !== undefined &&
                this.state.monthlypayment[this.state.selectedIndex] > 0
                    ? `${this.state.monthlypayment[this.state.selectedIndex].toFixed(2)}`
                    : "",
            per_month_conventioal_amount:
                this.state.monthlypaymentc[this.state.selectedIndex] !== undefined &&
                this.state.monthlypaymentc[this.state.selectedIndex] !== 0
                    ? `${this.state.monthlypaymentc[this.state.selectedIndex].toFixed(2)}`
                    : "",
            quoteid: this.state.quoteid,
        });
        if (this.state.name[0]) {
            clientData1.append("name[0]", this.state.name);
        }
        if (this.state.email[0]) {
            clientData1.append("email[0]", this.state.email);
        }
        if (this.state.phone[0]) {
            clientData1.append(
                "phone[0]",
                this.state.phone != undefined
                    ? this.state.phone
                            .replace(/\D/g, "")
                            .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
                    : ""
            );
        }
        if (this.state.address[0]) {
            clientData1.append("address[0]", this.state.address);
        }
        if (this.state.address2[0]) {
            clientData1.append("address2[0]", this.state.address2);
        }
        if (this.state.city[0]) {
            clientData1.append("city[0]", this.state.city);
        }

        if (this.state.state[0]) {
            clientData1.append("state[0]", this.state.state);
        }
        if (this.state.zip[0]) {
            clientData1.append("zip[0]", this.state.zip);
        }
        if (this.state.name1[0]) {
            clientData1.append("name[1]", this.state.name1);
        }
        if (this.state.email1[0]) {
            clientData1.append("email[1]", this.state.email1);
        }
        if (this.state.phone1[0]) {
            // clientData1.append('phone[1]', this.state.phone1);
            clientData1.append(
                "phone[1]",
                this.state.phone1[0] != undefined
                    ? this.state.phone1[0]
                            .replace(/\D/g, "")
                            .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
                    : ""
            );
        }

        if (this.state.address1[0]) {
            clientData1.append("address[1]", this.state.address1);
        }

        if (this.state.city1[0]) {
            clientData1.append("city[1]", this.state.city1);
        }
        if (this.state.state1[0]) {
            clientData1.append("state[1]", this.state.state1);
        }
        if (this.state.zip1[0]) {
            clientData1.append("zip[1]", this.state.zip1);
        }
        if (this.state.address21[0]) {
            clientData1.append("address2[1]", this.state.address21);
        }
        axios
            .post(
                `${process.env.REACT_APP_BASE_URL}/authenticationAPI/UpdateAcceptQuote`,
                clientData1
            )
            .then((res) => {
                if (res.data.Status) {
                    this.setState({ purchasepricevalue: "" });
                    this.setState({ tradeinvalue: "" });
                    this.setState({ tradeinpayoff: "" });
                    this.setState({ downpayment: "" });
                    this.setState({ salestax: "" });
                    this.setState({ insurance: "" });
                    this.setState({ otherfees: "" });
                    this.setState({ totalamountfinanced: 0 });
                    this.setState({ pifrate: 6.5 });
                    this.setState({ conventionalrate: 6 });
                    this.setState({ notes: "" });
                    this.setState({ salestaxpercentage: "" });
                    this.setState({ coapplicantmember: false });
                    this.setState({ name: [] });
                    this.setState({ email: [] });
                    this.setState({ phone: [] });
                    this.setState({ address: [] });
                    this.setState({ name1: [] });
                    this.setState({ email1: [] });
                    this.setState({ phone1: [] });
                    this.setState({ address1: [] });
                    this.setState({ quoteid: "" });
                    this.setState({ branch: "" });
                    this.setState({ cu_referral: "" });
                    localStorage.removeItem("profitAmount");
                    localStorage.removeItem("vinno");
                    localStorage.removeItem("rvisegment");
                    localStorage.removeItem("residual_value");
                    this.setState({ showcustomerPopup: false });
                    this.setState({ showAcceptMessage: true });
                    // Revert back to the original state after 5 seconds
                    setTimeout(() => {
                        this.setState({ showAcceptMessage: false });
                    }, 5000);

                    Swal({
                        text:
                            res.data.Message + " Here is your quote id:-" + res.data.QuoteID,
                        icon: "success",
                        button: "OK",
                    }).then(() => {
                        window.location.reload();
                    });
                }
                else
                    Swal({
                        text: res.data.Message,
                        icon: "error",
                        button: "OK",
                    }).then(() => {
                        window.location.reload();
                    });
            })
            .catch((error) => {
                console.error("Error posting data:", error);
                Swal({
                    text: `Error: ${error.message}`,
                    icon: "error",
                    button: "OK",
                }).then(() => {
                    window.location.reload();
                });
            });
    };
    handleReceiveAmazonChange = (event) => {
        this.setState({ amazonStatus: event.target.value });
    };
     handleReceiveAmazonCouponChange = (event) => {
        this.setState({ amazonCouponStatus: event.target.value });
    };
    preApprovedChange = (event) => {
        this.setState({ preApprovedStatus: event.target.value });
    };
    handleQuoteDefineStatusChange = (event) => 
    {
        this.setState({ quotedefineStatus: event.target.value });

        if (
            this.state.selectedOption === "84" &&
            this.state.monthlypayment[6] !== undefined &&
            this.state.monthlypayment[6] > 0
        ) {
            // Do nothing if the conditions are met
            this.setState({ showPopup: true });
        } else if (
            this.state.selectedOption === "72" &&
            this.state.monthlypayment[5] !== undefined &&
            this.state.monthlypayment[5] > 0
        ) {
            // Do nothing if the conditions are met
            this.setState({ showPopup: true });
        } else if (
            this.state.selectedOption === "60" &&
            this.state.monthlypayment[4] !== undefined &&
            this.state.monthlypayment[4] > 0
        ) {
            // Do nothing if the conditions are met
            this.setState({ showPopup: true });
        } else if (
            this.state.selectedOption === "48" &&
            this.state.monthlypayment[3] !== undefined &&
            this.state.monthlypayment[3] > 0
        ) {
            // Do nothing if the conditions are met
            this.setState({ showPopup: true });
        } else if (
            this.state.selectedOption === "36" &&
            this.state.monthlypayment[2] !== undefined &&
            this.state.monthlypayment[2] > 0
        ) {
            // Do nothing if the conditions are met
            this.setState({ showPopup: true });
        } else if (
            this.state.selectedOption === "24" &&
            this.state.monthlypayment[1] !== undefined &&
            this.state.monthlypayment[1] > 0
        ) {
            // Do nothing if the conditions are met
            this.setState({ showPopup: true });
        } else if (
            this.state.selectedOption === "12" &&
            this.state.monthlypayment[0] !== undefined &&
            this.state.monthlypayment[0] > 0
        ) {
            // Do nothing if the conditions are met
            this.setState({ showPopup: true });
        } else {
            // Hide the popup if the conditions are not met
            this.setState({ showPopup: false });
            Swal({
                text: "You should have PIF monthly rate to confirm.",
                icon: "error",
                button: "OK",
            });
        }
    };

    addTextToPage = async (page, texts, font) => {
        for (const { text, x, y, size } of texts) {
            page.drawText(text, {
                x: x,
                y: y,
                size: size,
                font: font,
                color: rgb(0, 0, 0),
            });
        }
    };

    blobToBase64 = async (blob, callback) => {
        const reader = new FileReader();
        reader.onloadend = function () {
            const base64data = reader.result.split(",")[1]; // Removes the Data URL part
            callback(base64data);
        };
        reader.readAsDataURL(blob);
    };

    addAgreementInfo2 = async (
        borrowerName,
        borrowerAddress,
        borrowerAddress2,
        borrowerCity,
        borrowerState,
        borrowerZip,
        coBorrowerName,
        coBorrowerAddress,
        coborrowerAddress2,
        coborrowerCity,
        coborrowerState,
        coborrowerZip,
        creditorName,
        creditorAddress,
        creditorAddress2,
        creditorCity,
        creditorState,
        creditorZip,
        quoteNumber,
        year,
        make,
        model,
        style,
        vin,
        terms,
        contractStartDate,
        finalSchedulePayment,
        finalSchedulePaymentDate,
        residualPayment,
        quotestatus,
        memberid,
        pifrate,
        permonthpifamount,
        pdftype,
        applicant_email
    ) => 
    {
                    const filePath = window.location.origin + "/PIFAddendum.pdf";
                    const response = await fetch(filePath);
                    const arrayBuffer = await response.arrayBuffer();
                    const pdfDoc = await PDFDocument.load(arrayBuffer);
                    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
                    const page = pdfDoc.getPages()[0];
                    const borrowerInfo = [
                        { text: String(borrowerName), x: 36, y: 727, size: 7 },
                        { text: String(borrowerAddress), x: 36, y: 717, size: 7 },
                        { text: String(coBorrowerName), x: 206, y: 727, size: 7 },
                        { text: String(coBorrowerAddress), x: 206, y: 717, size: 7 },
                        { text: String(creditorName), x: 408, y: 726, size: 7 },
                        { text: String(creditorAddress), x: 408, y: 716, size: 7 },

                        { text: String(quoteNumber), x: 460, y: 758, size: 8 },
                        { text: String(year), x: 50, y: 650, size: 8 },
                        { text: String(make), x: 96, y: 650, size: 8 },
                        { text: String(model), x: 210, y: 650, size: 8 },
                        { text: String(style), x: 320, y: 650, size: 8 },
                        { text: String(vin), x: 470, y: 650, size: 8 },
                        { text: String(terms), x: 125, y: 624, size: 8 },
                        { text: String(pifrate), x: 220, y: 624, size: 8 },
                        { text: String(finalSchedulePayment), x: 340, y: 624, size: 8 },
                        { text: String(permonthpifamount), x: 490, y: 624, size: 8 },
                        { text: String(contractStartDate), x: 50, y: 570, size: 8 },
                        { text: residualPayment, x: 170, y: 570, size: 8 },
                        { text: String(finalSchedulePaymentDate), x: 300, y: 570, size: 8 },
                        { text: residualPayment, x: 450, y: 570, size: 8 },
                    ];
                    if (borrowerAddress2) 
                    {
                        borrowerInfo.push({ text: String(borrowerAddress2), x: 36, y: 709, size: 7 });
                        borrowerInfo.push({
                            text: String(borrowerCity + "," + borrowerState + " " + borrowerZip),
                            x: 36,
                            y: 701,
                            size: 7,
                        });
                    } 
                    else
                     {
                            borrowerInfo.push({
                                text: String(borrowerCity + "," + borrowerState + " " + borrowerZip),
                                x: 36,
                                y: 710, // Adjusted to move up in case address2 is empty
                                size: 7,
                            });
                    }
                     if (coborrowerAddress2) 
                     {
                        borrowerInfo.push({ text: String(coborrowerAddress2), x: 206, y: 709, size: 7 });
                        borrowerInfo.push({
                            text: String(coborrowerCity + "," + coborrowerState + " " + coborrowerZip),
                            x: 206,
                            y: 701,
                            size: 7,
                        });
                    } 
                    else
                     {
                        borrowerInfo.push({
                            text: String(coborrowerCity + "," + coborrowerState + " " + coborrowerZip),
                            x: 206,
                            y: 710, // Adjusted to move up in case address2 is empty
                            size: 7,
                        });
                    }


 if (creditorAddress2) 
                    {
                        borrowerInfo.push({ text: String(creditorAddress2), x: 408, y: 709, size: 7 });
                        borrowerInfo.push({
                            text: String(creditorCity + "," + creditorState + " " + creditorZip),
                            x: 408,
                            y: 702,
                            size: 7,
                        });
                    } 
                    else
                     {
                            borrowerInfo.push({
                                text: String(creditorCity + "," + creditorState + " " + creditorZip),
                                x: 408,
                                y: 709, // Adjusted to move up in case address2 is empty
                                size: 7,
                            });
                    }





                    await this.addTextToPage(page, borrowerInfo, font);
                    const pdfBytes = await pdfDoc.save();
                    const blob = new Blob([pdfBytes], { type: "application/pdf" });
                    const url = URL.createObjectURL(blob);
                    if (this.state.dealerStatus)
                     {
                        axios
                            .get(
                                `${process.env.REACT_APP_BASE_URL}/authenticationAPI/getsuperadminemail`,{
        params: {
            auth_token: localStorage.getItem("auth_token"),
            user_member_id: localStorage.getItem("customer_id"),
        },
    }
                            )
                            .then((response) => {
                                const API_KEY12 = process.env.REACT_APP_EMAIL_API_KEY;
                                var API_KEY = API_KEY12.replace(/"/g, "");
                                this.blobToBase64(blob, function (base64pdf) {
                                    // Attach the PDF to the email
                                    const MESSAGE = {
                                        key: API_KEY,
                                        message: {
                                            text: "Here Is Your Coupon",
                                            subject: "Coupon Status Update",
                                            from_email: "pifapp@myautoline.com",
                                            to: [
                                                {
                                                    email: response.data[0]["SuperAdminEmail"], // Use the customer's email here
                                                    name: "PIF",
                                                    type: "to",
                                                },
                                            ],
                                            attachments: [
                                                {
                                                    // Attach the PDF
                                                    type: "application/pdf",
                                                    name: quoteNumber + ".pdf",
                                                    content: base64pdf,
                                                },
                                            ],
                                        },
                                    };
                                    sendEmail(MESSAGE);
                                    // Mandrill API endpoint
                                });
                            });

                        function sendEmail(message) {
                            const MANDRILL_URL12 = process.env.REACT_APP_MANDRILL_API_URL;
                            var MANDRILL_URL = MANDRILL_URL12.replace(/"/g, "");
                            // Send email
                            axios
                                .post(MANDRILL_URL, message)
                                .then((response) => {
                                    console.log("Email sent successfully:", response.data);
                                    alert(1);
                                   alert(response.data);
                                    if (response.data[0].status) {
                                     // window.location.reload();
                                    }

 this.logentry(
      "send-email",
      JSON.stringify(MANDRILL_URL),
      response.data,
      localStorage.getItem("customer_id"),
      this.ip,
      "success"
   );

console.log( this.logentry(
      "send-email",
      JSON.stringify(MANDRILL_URL),
      response.data,
      localStorage.getItem("customer_id"),
      this.ip,
      "success"
   ));
                                })
                                .catch((error) => {
                                    console.error("Error sending email:", error);
                               
 this.logentry(
      "send-email",
      JSON.stringify(MANDRILL_URL),
      response.data,
      localStorage.getItem("customer_id"),
      this.ip,
      "error"
   );


console.log( this.logentry(
      "send-email",
      JSON.stringify(MANDRILL_URL),
      response.data,
      localStorage.getItem("customer_id"),
      this.ip,
      "error"
   )

);

                                });
                        }
                    }
            
                 
                if(pdftype=='signed')
                    {
                         this.promptUserForSigningMethod(url,applicant_email,quoteNumber); // Call function to place signature field
                    }
                    else
                    {
                        window.open(url, "_blank");
                    const a = document.createElement("a");
                    a.href = url;
                    var status = "";
                    if (this.state.dealerStatus) 
                    {
                        status = "completed";
                    } 
                    else
                     {
                        status = quotestatus === 0 ? "pending" : "accepted";
                    }
                                                 a.download =
                                "vehicle_loan_agreement_" +
                                status +
                                " " +
                                "(" +
                                quoteNumber +
                                ")" +
                                ".pdf";
                            a.click();
                             }
    };


 handleEmailChange = (event) => {
this.setState({ docusealemail: event.target.value });
    };
getFileFromBlobUrl = async    (blobUrl) => {
    const response = await fetch(blobUrl);
    const blob = await response.blob();
    // You can set a filename here if needed
    return new File([blob], 'filename.pdf', { type: blob.type });
}
     convertImageToBase64 = async (url) => 
     {
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const blob = await response.blob();
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
            reader.onloadend = () => {
                // Extract the base64 string from the data URL
                const base64String = reader.result.split(',')[1];
                resolve(base64String);
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    } catch (error) {
        console.error('Error converting image to Base64:', error);
        throw error; // Re-throw error to handle it where the function is called
    }
};

    sendEmail2 = async () => {
        if (!this.state.docusealemail) {
            alert('Please enter an email address.');
            return;
        }
 let documentId2=         this.state.documentId;
 let email2=                this.state.docusealemail;
        try {
            // API call to send the signing request via email
            await axios.post('https://api.docuseal.com/send-email', {
                documentId2,
                email2
            }, {
                headers: {
                    'Authorization': 'Bearer yy68fgTwvDVWzcYXJB94V4aTQYjFE2yj96G3GBiVusX',
                    'Content-Type': 'application/json'
                }
            });
            alert('Signing request sent to email.');

this.logentry(
      "signing-send-email",
      JSON.stringify('https://api.docuseal.com/send-email'),
      "Signing request sent to email.",
      localStorage.getItem("customer_id"),
      this.ip,
      "success"
   );


  console.log(this.logentry(
      "signing-send-email",
      JSON.stringify('https://api.docuseal.com/send-email'),
      "Signing request sent to email.",
      localStorage.getItem("customer_id"),
      this.ip,
      "success"
   ));

        } catch (error) {
            console.error('Error sending email:', error);
            alert('Failed to send signing request.');

this.logentry(
      "signing-send-email",
      JSON.stringify('https://api.docuseal.com/send-email'),
      error,
      localStorage.getItem("customer_id"),
      this.ip,
      "error"
   );


  console.log(this.logentry(
      "signing-send-email",
      JSON.stringify('https://api.docuseal.com/send-email'),
      error,
      localStorage.getItem("customer_id"),
      this.ip,
      "error"
   ));


        }
    };

     promptUserForSigningMethod = (file,applicant_email,quoteNumber) => {
        // const userPreference = window.confirm('Would you like to sign the document via email? Click OK for email, Cancel for live signing.');
        // this.setState({ isEmail: userPreference });
        // if (!userPreference) {
        //     this.createSigningRequest(file,'live',applicant_email,quoteNumber); // If live signing, create a signing request
        // }
        // else
        // {
        //     this.createSigningRequest(file,'email',applicant_email,quoteNumber);
        // }



    Swal2.fire({
      title: '',
      text: 'Would you like to sign the document via email or live signing?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Email Signing',
      cancelButtonText: 'Live Signing',
      customClass: {
    confirmButton: 'custom-button', // Same class for both buttons
    cancelButton: 'custom-button',   // Same class for both buttons
  },
    }).then((result) => {
      if (result.isConfirmed) {
        // User clicked 'Sign via Email'
        this.setState({ isEmail: true });
        this.createSigningRequest(file, 'email', applicant_email, quoteNumber);
      } else if (result.dismiss === Swal2.DismissReason.cancel) {
        // User clicked 'Sign Live'
        this.setState({ isEmail: false });
        this.createSigningRequest(file, 'live', applicant_email, quoteNumber);
      }
    });









    };
     createSigningRequest = async (file,platform,applicant_email,quoteNumber) => 
     {
     
let Url = `${process.env.REACT_APP_BASE_URL}/authenticationAPI/uploadpdfondocusealapi`;
this.signedblobToBase64(file)
        .then(base64 => {
                console.log('Base64 Data:', base64);
let fileData = {
    file1: base64,
    platform:platform,
    applicant_email:applicant_email,
    quoteNumber:quoteNumber,
    user_member_id:localStorage.getItem("customer_id"),
    auth_token:localStorage.getItem("auth_token"),
};

this.setState({ loading: true });
 axios
                .post(Url, fileData)
                .then((response) => {
                    this.setState({ loading: false });
                    var temid = response.data.documentid;
                    var applicant_email=response.data.applicant_email;
if(response.data.platform =='live')
{
Swal2.fire({
        text: "Click ok to sign the document.",
        icon: "info",
        confirmButtonText: "OK"
}).then((result) => {
        if (result.isConfirmed) 
        {
             const opensignurl = 'https://docuseal.co/d/' + response.data.slug+'?email='+applicant_email;
                window.open(opensignurl, '_blank'); 
                 setTimeout(function() {
            window.location.reload();
            },3000);
        }
});
}

else
{

let fileData2 = {
    email: applicant_email,
    template_id:temid,
    user_member_id:localStorage.getItem("customer_id"),
    auth_token:localStorage.getItem("auth_token")
};

console.log(fileData2)
this.setState({ loading: true });
let Url2 = `${process.env.REACT_APP_BASE_URL}/authenticationAPI/send_pdf_to_sign_by_email`;
 axios
                .post(Url2, fileData2)
                .then((response) => {
this.setState({ loading: true });
if(response.data.status){
 Swal({
                text: "please check your email.",
                icon: "info",
                button: "OK",
            }).then(() => {
                        window.location.reload();
                    });;
}
});
}
                })
                .catch((error) => {
                    console.error("Error sending email:", error);
                });
});
    };
    addAgreementInfo = async (
        dealerName,
        terms,
        date,
        year,
        make,
        model,
        salesRep,
        signConfirm,
        receiveAmazonStatus,
        receiveAmazonCouponStatus,
        credit,
        customerName,
        customeEmail,
        cell,
        branch,
        cuReferral,
        preApprovedStatus
    ) => 
    {

        let filePath;
        if (
            localStorage.getItem("CuPdfFileName") &&
            localStorage.getItem("CuPdfFileName") != "undefined" &&
            localStorage.getItem("CuPdfFileName") !== undefined &&
            localStorage.getItem("CuPdfFileName") !== "" &&
            localStorage.getItem("CuPdfFileName") !== "null"
        ) {
         
    var filename =localStorage.getItem("CuPdfFileName");
    const bucketName="pifapp";
    const region = 'us-east-2';
    const baseUrl = `https://${bucketName}.s3.${region}.amazonaws.com/coupons/`;
    const fileUrl = `${baseUrl}${filename}.pdf`;
    
filePath = fileUrl
        } else {
            filePath = window.location.origin + "/image.pdf";
        }
    
        const response = await fetch(filePath,{
            method: 'GET',
            headers: {
                'Content-Type': 'application/pdf',
                'Access-Control-Allow-Origin': '*', // This header must be set on the server-side
            },
        });
        const arrayBuffer = await response.arrayBuffer();
        const pdfDoc = await PDFDocument.load(arrayBuffer);
        const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
        const page = pdfDoc.getPages()[0];
        console.log(receiveAmazonStatus);
        console.log(preApprovedStatus);
        const borrowerInfo = [
            { text: String(dealerName), x: 220, y: 150, size: 8 },
            { text: String(terms), x: 320, y: 170, size: 8 },
            { text: String(date), x: 510, y: 152, size: 8 },
            { text: String(year), x: 210, y: 130, size: 8 },
            { text: String(make), x: 290, y: 130, size: 8 },
            { text: String(model), x: 340, y: 130, size: 8 },
            { text: String(salesRep), x: 280, y: 115, size: 8 },
            { text: String(signConfirm), x: 420, y: 115, size: 8 },
            receiveAmazonStatus == 0
                ? { text: String("."), x: 383, y: 85, size: 80 }
                : { text: String("."), x: 423, y: 85, size: 80 },
            preApprovedStatus == 1
                ? { text: String("."), x: 181, y: 29, size: 50 }
                : { text: String("."), x: 0, y: 0, size: 0 },
            { text: String(credit), x: 520, y: 70, size: 8 },
            { text: String(customerName), x: 260, y: 70, size: 8 },
            { text: String(customeEmail), x: 360, y: 50, size: 8 },
            { text: String(cell), x: 240, y: 50, size: 8 },
            { text: String(branch), x: 340, y: 30, size: 8 },
            { text: String(cuReferral), x: 480, y: 30, size: 8 },
        ];
        await this.addTextToPage(page, borrowerInfo, font);
        const pdfBytes = await pdfDoc.save();
        const blobcoupon = new Blob([pdfBytes], { type: "application/pdf" });
        const urlcoupon = URL.createObjectURL(blobcoupon);
        if (receiveAmazonCouponStatus == 1) 
{
            const API_KEY12 = process.env.REACT_APP_EMAIL_API_KEY;
            var API_KEY = API_KEY12.replace(/"/g, "");
            await this.blobToBase64(blobcoupon, function (base64pdf) {
                // Attach the PDF to the email
                const MESSAGE = {
                    key: API_KEY,
                    message: {
                        text: "Here Is Your Coupon",
                        subject: "Coupon Status Update",
                        from_email: "pifapp@myautoline.com",
                        to: [
                            {
                                email: customeEmail, // Use the customer's email here
                                name: "PIF",
                                type: "to",
                            },
                        ],
                        attachments: [
                            {
                                // Attach the PDF
                                type: "application/pdf",
                                name: customerName + "_" + credit + ".pdf",
                                content: base64pdf,
                            },
                        ],
                    },
                };
                sendEmail(MESSAGE);
                // Mandrill API endpoint
            });
        }

else
{

console.log("textmessage test");
// Retrieve the base path from environment variables
const basepath = process.env.REACT_APP_URL;
const basepathurl = basepath ? basepath.replace(/"/g, "") : '';
const id = credit;

var token = jwtEncode({"id": id,"exp":Math.floor(Date.now() / 1000) + 3 * 60 * 60}, 'test');


var coupontokenData =
{
 'id':id,
 'token':token,
 'user_member_id':localStorage.getItem("customer_id"),
 'auth_token':localStorage.getItem('auth_token')
 }
var update_coupon_token_url= process.env.REACT_APP_BASE_URL+'/authenticationAPI/updatecoupontoken';
 axios
                .post(update_coupon_token_url, coupontokenData)
                .then((response) => {
                    
                 console.log(response.data);
                })
                .catch((error) => {
                    console.error("Error sending sms:", error);
                });


// Construct the message text
const textmessage = `${basepathurl}/couponid?id=${id}&token=${token}`;
console.log("textmessage");
console.log(textmessage);
var sms_url =  process.env.REACT_APP_BASE_URL+'/authenticationAPI/send-sms';
var smsData =
{
 'to':cell,
 'message':`Here is your coupon: ${textmessage}`,
 'user_member_id':localStorage.getItem('customer_id'),
 }

 axios
                .post(sms_url, smsData)
                .then((response) => {
                    
                 console.log(response.data);
                })
                .catch((error) => {
                    console.error("Error sending sms:", error);
                });
}



        function sendEmail(message) {
            const MANDRILL_URL12 = process.env.REACT_APP_MANDRILL_API_URL;
            var MANDRILL_URL = MANDRILL_URL12.replace(/"/g, "");
            // Send email
            axios
                .post(MANDRILL_URL, message)
                .then((response) => {
                    alert(response.data);
                    console.log("Email sent successfully:", response.data);
                   
    console.log(this.logentry(
      "send-email",
      JSON.stringify(MANDRILL_URL),
      response.data,
      localStorage.getItem("customer_id"),
      this.ip,
      "success"
   ));

    
                    if (response.data[0].status) {
                        // window.location.reload();
                    }
                })
                .catch((error) => {
                    console.error("Error sending email:", error);
               
   console.log( this.logentry(
      "send-email",
      JSON.stringify(MANDRILL_URL),
      response.data,
      localStorage.getItem("customer_id"),
      this.ip,
      "error"
   ));

 
                });
            // setTimeout(function() {
            // window.location.reload();
            // },2000);
        }
        // Open the downloaded PDF in a new tab
        window.open(urlcoupon, "_blank");
        const a = document.createElement("a");
        a.href = urlcoupon;
        a.download = customerName + "_" + credit + ".pdf";
        a.click();
        this.setState({ name: [] });
        this.setState({ email: [] });
        this.setState({ phone: [] });
        this.setState({ address: [] });
        this.setState({ address2: [] });
        this.setState({ city: [] });
        this.setState({ state: [] });
        this.setState({ zip: [] });
        this.setState({ branch: "" });
        this.setState({ cu_referral: "" });
        this.setState({ showcustomerPopup: false });
        this.setState({ purchasepricevalue: "" });
        this.setState({ tradeinvalue: "" });
        this.setState({ tradeinpayoff: "" });
        this.setState({ downpayment: "" });
        this.setState({ salestax: "" });
        this.setState({ insurance: "" });
        this.setState({ otherfees: "" });
        this.setState({ totalamountfinanced: 0 });
        this.setState({ pifrate: 6.5 });
        this.setState({ conventionalrate: 6 });
        this.setState({ notes: "" });
        this.setState({ salestaxpercentage: "" });
        this.setState({ coapplicantmember: false });
        this.setState({ name1: [] });
        this.setState({ email1: [] });
        this.setState({ phone1: [] });
        this.setState({ address1: [] });
        this.setState({ city1: [] });
        this.setState({ state1: [] });
        this.setState({ zip1: [] });
        this.setState({ address21: [] });
        localStorage.removeItem("profitAmount");
        localStorage.removeItem("vinno");
        localStorage.removeItem("rvisegment");
        localStorage.removeItem("residual_value");
        setTimeout(function() {
        window.location.reload();
        },2000);
    };

    responseCalcualtion(msrpprice, vehicle_name, image_url) {
        // alert("hello");
        this.setState({ msrpvalue: "" });
        this.setState({ model: "" });
        this.setState({ carname: "" });
        this.setState({ year: "" });
        this.setState({ pdf_url: "" });
        this.setState({ purchasepricevalue: "" });
        this.setState({ tradeinvalue: "" });
        this.setState({ tradeinpayoff: "" });
        this.setState({ downpayment: "" });
        this.setState({ insurance: "" });
        this.setState({ otherfees: "" });
        this.setState({ salestax: "" });
        this.setState({ totalamountfinanced: "" });
        const msrpValue = msrpprice;
        this.setState({ msrpvalue: msrpValue });
        const vehicleName = vehicle_name;
        const carArray = vehicleName.split(" ");
        
    if (carArray.length ==3) 
{
       
                        this.setState({ model: carArray[2] });

                        this.setState({ carname: carArray[1] });
                        this.setState({ year: carArray[0] });



    }

else
{
  this.setState({ model: carArray[3] });
                        this.setState({ carname: carArray[1] + " " + carArray[2] });
                        this.setState({ year: carArray[0] });

}



        // this.setState({ model: carArray[2] });
        // this.setState({ carname: carArray[1] + " " + carArray[2] });
        // this.setState({ year: carArray[0] });



        if (image_url == "/test.png") {
            this.setState({ pdf_url: "./assets/img/sticker.png" });
        } else {
            this.setState({
                pdf_url: process.env.REACT_APP_EXTERNAL_URL + image_url,
            });
        }
        const numericValue = parseFloat(msrpValue.replace(/\$|,/g, "")).toFixed(2);
        var msrp = numericValue;
        var carName =
            carArray[0] +
            " " +
            carArray[1] +
            " " +
            carArray[2] +
            " " +
            carArray.slice(3).join(" ");
        // Call the fetchData function and handle the promise
        this.fetchData(carName).then((data) => {
            var msrppercentage = data.map((value) => value / 2);
        
            this.setState({
                msrppercentagedata: msrppercentage1,
            });
            var msrppercentage1 = msrppercentage.map((value) => value / 4);
            var estimatedresidualvalue1 = [];
            // Calculate estimated residual value for each percentage
            msrppercentage.forEach((percentage) => {
                var estimaterv = (msrp * percentage) / 100;
                estimatedresidualvalue1.push(estimaterv);
            });
            estimatedresidualvalue1 = estimatedresidualvalue1.map((value) =>
                parseFloat(value.toFixed(2))
            );
            this.setState({
                estimatedresidualvalue: estimatedresidualvalue1,
            });
            this.setState({
                estimatedresidualvaluemyname: estimatedresidualvalue1,
            });
            for (let i = 0; i < estimatedresidualvalue1.length; i++) {
                // Construct state key dynamically
                const stateKey = `prevestimatedresidualvalue${(i + 1) * 12}`;
                // Set state with the corresponding value
                this.setState({
                    [stateKey]: estimatedresidualvalue1[i],
                });
            }
            const calculateMonthlyPaymentC = (
                totalLoanAmount,
                termMonths,
                monthlyInterestRate
            ) => {
                return (
                    (totalLoanAmount * monthlyInterestRate) /
                    (1 - Math.pow(1 + monthlyInterestRate, -termMonths))
                );
            };
            const calculateMonthlyPayment = (
                totalLoanAmount,
                termMonths,
                pifRate,
                endBalance
            ) => {
                var loanAmount = totalLoanAmount;
                var endBalance = endBalance;
                var monthlyInterestRate = pifRate;
                var termMonths = termMonths;
                let monthlyPayment = 0;
                let epsilon = 0.01; // Tolerance for convergence
                let maxIterations = 1000; // Maximum number of iterations

                for (let i = 0; i < maxIterations; i++) 
                {
                    let remainingBalance = loanAmount;
                    // Calculate remaining balance using the current monthly payment
                    for (let j = 0; j < termMonths; j++) {
                        let interest = remainingBalance * monthlyInterestRate;
                        let principal = monthlyPayment - interest;
                        remainingBalance -= principal;
                    }
                    // Check if the remaining balance is close enough to the end balance
                    if (Math.abs(remainingBalance - endBalance) < epsilon) {
                        break; // Stop iteration if close enough
                    }
                    // Adjust monthly payment based on the difference between calculated remaining balance and end balance
                    monthlyPayment += (remainingBalance - endBalance) / termMonths;
                }
            };

            // Terms for calculation
            const terms = [12, 24, 36, 48, 60, 72, 84];
            const conventionalRate = this.state.conventionalrate / 100 / 12;
            const pifRate2 = this.state.pifrate / 100 / 12;
            //const estimatervcArray = this.state.estimatedresidualvalue;
            const estimatervcArray = this.state.estimatedresidualvalue;
            let pifMonthlyPayments = [];
            let conventionalMonthlyPayments = [];
            // estimatervcArray.forEach(estimatervcValue => {
            terms.forEach((term, index) => {
                // // Calculate monthly payment for PIF rate
                // var toalamountfinceddata = this.state.totalamountfinanced.toFixed(2)- this.state.estimatedresidualvalue[index]
                var toalamountfinceddata = this.state.totalamountfinanced.toFixed(2);
                const pifMonthlyPayment = calculateMonthlyPayment(
                    toalamountfinceddata,
                    term,
                    pifRate2,
                    this.state.estimatedresidualvalue[index]
                );
                pifMonthlyPayments.push(pifMonthlyPayment);
                // Calculate monthly payment for conventional rate
                const conventionalMonthlyPayment = calculateMonthlyPaymentC(
                    toalamountfinceddata,
                    term,
                    conventionalRate
                );
                conventionalMonthlyPayments.push(conventionalMonthlyPayment);
            });
            //});
            this.setState({
                monthlypayment: pifMonthlyPayments,
                monthlypaymentc: conventionalMonthlyPayments,
            });
        });
    }

    handleSelectChangeBank = (event) => {
        this.setState({ selectedBankId: event.target.value });

        // alert(event.target.value);
        const filteredBankData = this.state.bankData.filter(
            (bank) => bank.Cu_ID == event.target.value
        );
// alert(filteredBankData[0]['Cu_ID']);
//         alert(filteredBankData[0]['CuDefaultPercentage']);
        
        this.setState({ filteredBankData });
     localStorage.setItem('selectedBank',filteredBankData[0].CompanyName);
localStorage.setItem('BankAddress',filteredBankData[0].BankAddress);
localStorage.setItem('BankAddress2',filteredBankData[0].BankAddress2);
localStorage.setItem('BankState',filteredBankData[0].BankState);
localStorage.setItem('BankZip',filteredBankData[0].BankZip);
localStorage.setItem('BankCity',filteredBankData[0].BankCity);

// console.log("filteredBankData");
// console.log(filteredBankData);

        let formattedValuepft = "";
        if (localStorage.getItem("CustomerType") === "Dealer") 
        {
            // Check if filteredBankData exists and has at least one element
            if (
                filteredBankData &&
                filteredBankData.length > 0
            ) {
                formattedValuepft =
                    +filteredBankData[0]["CuDefaultPercentage"] + 0.5;
            } else {
                formattedValuepft = 6.5; // Handle case when filteredBankData is empty or undefined
            }
        }
        let formattedValuect = "";
        if (localStorage.getItem("CustomerType") === "Dealer") {
            // Check if filteredBankData exists and has at least one element
            if (
                filteredBankData &&
                filteredBankData.length > 0
            ) {
                formattedValuect =
                    filteredBankData[0]["CuDefaultPercentage"];
            } else {
                formattedValuect = 6; // Handle case when filteredBankData is empty or undefined
            }
        }

         // alert(formattedValuect);
        this.setState({ mycustomcu: formattedValuect });
        this.setState({ mycustomfu: formattedValuepft });
        // const adjustedvehicleprice = parseFloat(this.state.purchasepricevalue) - (parseFloat(this.state.tradeinvalue) || 0) + (parseFloat(this.state.tradeinpayoff) || 0);
        //    const totalamounttobefinanced = adjustedvehicleprice - (parseFloat(this.state.downpayment) || 0) + (parseFloat(this.state.insurance) || 0) + (parseFloat(this.state.otherfees) || 0) + (parseFloat(this.state.salestax) || 0);
        const adjustedvehicleprice =
            parseFloat(this.state.purchasepricevalue.replace(/,/g, "")) -
            (parseFloat(this.state.tradeinvalue.replace(/,/g, "")) || 0) +
            (parseFloat(this.state.tradeinpayoff.replace(/,/g, "")) || 0);
        const totalamounttobefinanced =
            adjustedvehicleprice -
            (parseFloat(this.state.downpayment.replace(/,/g, "")) || 0) +
            (parseFloat(this.state.insurance.replace(/,/g, "")) || 0) +
            (parseFloat(this.state.otherfees.replace(/,/g, "")) || 0) +
            (parseFloat(this.state.salestax) || 0);

        // alert(totalamounttobefinanced);
        this.setState({ totalamountfinanced: totalamounttobefinanced });
        const calculateMonthlyPaymentC = (
            totalLoanAmount,
            termMonths,
            monthlyInterestRate
        ) => {
            return (
                (totalLoanAmount * monthlyInterestRate) /
                (1 - Math.pow(1 + monthlyInterestRate, -termMonths))
            );
        };
        const calculateMonthlyPayment = (
            totalLoanAmount,
            termMonths,
            pifRate,
            endBalance
        ) => {
            var loanAmount = totalLoanAmount;
            var endBalance = endBalance;
            var monthlyInterestRate = pifRate;
     
            var termMonths = termMonths;
            let monthlyPayment = 0;
            let epsilon = 0.01; // Tolerance for convergence
            let maxIterations = 1000; // Maximum number of iterations
            for (let i = 0; i < maxIterations; i++) {
                let remainingBalance = loanAmount;
                // Calculate remaining balance using the current monthly payment
                for (let j = 0; j < termMonths; j++) {
                    let interest = remainingBalance * monthlyInterestRate;
                    let principal = monthlyPayment - interest;
                    remainingBalance -= principal;
                }
                // Check if the remaining balance is close enough to the end balance
                if (Math.abs(remainingBalance - endBalance) < epsilon) {
                    break; // Stop iteration if close enough
                }
                // Adjust monthly payment based on the difference between calculated remaining balance and end balance
                monthlyPayment += (remainingBalance - endBalance) / termMonths;
            }
        
            return monthlyPayment;
        };

        // Terms for calculation
        const terms = [12, 24, 36, 48, 60, 72, 84];
        const conventionalRate = formattedValuect / 100 / 12;
        const pifRate2 = formattedValuepft / 100 / 12;
        const estimatervcArray = this.state.estimatedresidualvalue;
        let pifMonthlyPayments = [];
        let conventionalMonthlyPayments = [];
        // estimatervcArray.forEach(estimatervcValue => {
        terms.forEach((term, index) => {
            // // Calculate monthly payment for PIF rate
            //var toalamountfinceddata = this.state.totalamountfinanced- this.state.estimatedresidualvalue[index]
            var toalamountfinceddata = totalamounttobefinanced;
            const pifMonthlyPayment = calculateMonthlyPayment(
                toalamountfinceddata,
                term,
                pifRate2,
                this.state.estimatedresidualvalue[index]
            );
            pifMonthlyPayments.push(pifMonthlyPayment);
            // Calculate monthly payment for conventional rate
            const conventionalMonthlyPayment = calculateMonthlyPaymentC(
                toalamountfinceddata,
                term,
                conventionalRate,
                this.state.estimatedresidualvalue[index]
            );
            conventionalMonthlyPayments.push(conventionalMonthlyPayment);
        });
        //});
        this.setState({
            monthlypayment: pifMonthlyPayments,
            monthlypaymentc: conventionalMonthlyPayments,
        });
    };

    handleAccordionClick = (event) => {
        const accordionButton = event.target.closest(".accordion-header");
        if (accordionButton) {
            const accordionPanel = accordionButton.nextElementSibling;
            if (accordionPanel.classList.contains("collapse")) {
                accordionPanel.classList.remove("collapse");
            } else {
                accordionPanel.classList.add("collapse");
            }
        }
    };

    readFile = () => {
        //const file = 'http://localhost:3000/assets/file/WindowStickerUsageReport.xlsx'; // Replace with your file path
        // alert("hello");
        const baseurl =
            window.location.protocol +
            "//" +
            window.location.hostname +
            (window.location.port ? ":" + window.location.port : "");
        fetch(baseurl + "/assets/file/WindowStickerUsageReport.xlsx")
            .then((response) => response.arrayBuffer())
            .then((buffer) => {
                // alert("hello");
                const workbook = XLSX.read(buffer, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const parsedData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                const excelData = parsedData.slice(1).map((row) => ({
                    Year: row[3],
                    Make: row[1],
                    Model: row[2],
                }));
                this.setState({ excelData });
                // alert("hello1");
            })
            .catch((error) => {
                // alert("hello2");
                console.error("Error fetching Excel file:", error);
            });
    };

    // Extract unique years from the data
    getYears = () => {
        const { excelData } = this.state;
        const uniqueYears = [
            ...new Set(
                excelData.map((item) => item.Year).filter((year) => year > 2015)
            ),
        ];
        const sortedYears = uniqueYears.sort((a, b) => a - b);
        return sortedYears;
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevState.quotedefineStatus !== this.state.quotedefineStatus) {
            this.doSomethingAfterStateUpdate();
        }
    }

    doSomethingAfterStateUpdate = () => {
        this.setState({
            quotedefineStatus1: JSON.parse(this.state.quotedefineStatus),
        });
    };

    componentWillUnmount() {
        document.removeEventListener("click", this.handleAccordionClick);
    }
    componentDidMount() {

axios.get('https://api.ipify.org?format=json')
      .then((response) => {
        this.setState({ ip: response.data.ip });
      })
      .catch((error) => {
        console.error('Error fetching IP:', error);
      });
 axios
    .get(`${process.env.REACT_APP_BASE_URL}/authenticationAPI/GetDefaultCUDetailsByBankID`, {
        params: {
            bank_id: 4,
            user_member:localStorage.getItem('customer_id'),
            auth_token:localStorage.getItem("auth_token")
        }
    })
    .then((response) => 
    {
        this.setState({cunamedefaultsuperadmin: response.data[0].CompanyName });
        this.setState({cuaddressdefaultsuperadmin: response.data[0].Address });
        this.setState({cuaddress2defaultsuperadmin: response.data[0].Address2 });
        this.setState({cucitydefaultsuperadmin: response.data[0].City });
        this.setState({custatedefaultsuperadmin: response.data[0].State });
        this.setState({cuzipdefaultsuperadmin: response.data[0].Zip });
    })
    .catch((error) => {
        console.error('Error fetching data:', error);
    });



const tempPasswordStatus = localStorage.getItem("TempPasswordStatus");
    console.log("TempPasswordStatus:", tempPasswordStatus);
  
    if ((window.location.pathname == '/dashboard') && localStorage.getItem("CustomerType") && localStorage.getItem("CustomerType")!=='None' && tempPasswordStatus && tempPasswordStatus == 1)
     { 
         // Check if the alert has already been shown
       const alertShown = localStorage.getItem("PasswordAlertShown");
      if (!alertShown) 
      {
          Swal({
            text: "Change the Temporary Password. Click on Reset Password Button in Roster/Customer tab.",
            icon: "info",
            buttons: {
              confirm: {
                text: "OK",
                value: true,
                visible: true,
                className: "",
                closeModal: true
              }
            },
            dangerMode: true
          }).then(() => 
          {
            if (window.location.pathname == '/dashbaord') {
              Redirect('/dashboard');
            }
                // Set the flag in localStorage to prevent future alerts
            localStorage.setItem("PasswordAlertShown", "true");
          });
        }
    }










         // Get the element(s) by class name
const containers = document.getElementsByClassName('customer'); // No dot needed
// Check if any elements are found
if (containers.length > 0) {
    // Access the first element in the collection
    const container = containers[0];
    // Get the height of the container
    const containerHeight = container.clientHeight;
    // Add or remove the 'scrollable' class based on the height
    if (containerHeight > 870) {
        container.classList.add('scrollable');
    } else {
        container.classList.remove('scrollable');
    }
} else {
    console.error('No elements found with class "customer".');
}
 if (localStorage.getItem('CuPdfFileName') !== 'null' &&localStorage.getItem('CuPdfFileName') !== null && localStorage.getItem('CuPdfFileName') !== undefined && localStorage.getItem('CuPdfFileName').trim() !== '') {
 var filename =localStorage.getItem("CuPdfFileName");
const bucketName="pifapp";
const region = 'us-east-2';
    const baseUrl = `https://${bucketName}.s3.${region}.amazonaws.com/coupons/`;
    const fileUrl = `${baseUrl}${filename}.pdf`;
    var pdfData=
{
 'pdfpath':fileUrl
 }
 axios
            .post(
                `${process.env.REACT_APP_BASE_URL}/authenticationAPI/convert`,
                pdfData
            )
                .then((res) => {
        localStorage.setItem("imagePath",process.env.REACT_APP_BASE_URL+'/public'+res.data.imagePath);
                    this.setState({ cuimagepath:process.env.REACT_APP_BASE_URL+'/public'+res.data.imagePath});
                })
             } 

        const customerType = localStorage.getItem("CustomerType");
        const dealerId =
            customerType === "Dealer" ? localStorage.getItem("DealerId") : "";
        if (dealerId !== "") {
            this.setState({ dealerStatus: true });
            this.setState({ showBankInfo: true });
            localStorage.setItem('selectedBank','');
         localStorage.setItem('BankAddress','');
         localStorage.setItem('BankAddress2','');
         localStorage.setItem('BankCity','');
         localStorage.setItem('BankState','');
         localStorage.setItem('BankZip','');
            axios
                .get(
                    `${process.env.REACT_APP_BASE_URL}/authenticationAPI/GetBankByDealerId?dealer_id=${dealerId}`
                )
                .then((response) => {
                
                    this.setState({ bankData: response.data });
                    this.setState({
                        selectedBankId:
                            response.data.length > 0 ? response.data[0].Cu_ID : "",
                    });
localStorage.setItem('selectedBank',response.data[0].CompanyName);
localStorage.setItem('BankAddress',response.data[0].BankAddress);
localStorage.setItem('BankAddress2',response.data[0].BankAddress2);
localStorage.setItem('BankState',response.data[0].BankState);
localStorage.setItem('BankZip',response.data[0].BankZip);
localStorage.setItem('BankCity',response.data[0].BankCity);
                    let formattedValuepft = "";
                    if (localStorage.getItem("CustomerType") === "Dealer") {
                        // Check if filteredBankData exists and has at least one element
                        if (response.data && response.data.length > 0) {
                            var b = +response.data[0]["CuDefaultPercentage"] + 0.5;
                            formattedValuepft = b.toString();
                        } else {
                            formattedValuepft = 6.5; // Handle case when filteredBankData is empty or undefined
                        }
                    }
                    let formattedValuect = "";
                    if (localStorage.getItem("CustomerType") === "Dealer") {
                        // Check if filteredBankData exists and has at least one element
                        if (response.data && response.data.length > 0) {
                            var b1 = response.data[0]["CuDefaultPercentage"].toString();
                            formattedValuect = b1;
                        } else {
                            formattedValuect = 6; // Handle case when filteredBankData is empty or undefined
                        }
                    }

// alert(formattedValuect);


                    this.setState({ mycustomcu: formattedValuect });
                    this.setState({ mycustomfu: +formattedValuect + 0.5 });
                })
                .catch((error) => {
                    console.error("Error fetching bank data:", error);
                });
        }
        this.readFile();
        const observer = new MutationObserver((mutationsList, observer) => {
            const accordionPanel = document.querySelector(
                ".accordion-collapse.collapse"
            );
            if (accordionPanel) {
                // Execute your code here when the class is found
                accordionPanel.classList.remove("collapse");
                observer.disconnect(); // Disconnect the observer after executing the code
            }
        });

        // Start observing changes to the target node
        observer.observe(document.body, { childList: true, subtree: true });
        // Handle click event of accordion button to toggle class
        document.addEventListener("click", this.handleAccordionClick);
        // Check if loginName exists in localStorage
        const loginName = localStorage.getItem("LoginEmail");
        // If loginName does not exist, redirect to the login page
        if (!loginName) {
            this.props.history.push("/login");
        } else {
            // alert("hello");
        }
        var buttons = document.querySelectorAll(".wizard-btn");
        buttons.forEach((button) => {
            if (button.textContent === "Finish") {
                button.style.display = "none"; // Hide the button
            }
        });
        const pifRate = this.state.conventionalrate + 0.5; // Adding 0.5% difference
        this.setState({
            pifrate: pifRate,
        });
    }

    checkValidateTab = () => {
        // Validation passed
        return true; // Return true to indicate validation succes
    };

    // Method to generate error messages
    errorMessages = () => {
        const inputValue = this.state.vinno.trim(); // Trim leading and trailing spaces
        return inputValue !== "" ? {} : { tab1: "Field is required" }; // Return error message if input is empty
    };

    changeradio = (index, value) => {
        // alert(index);
        this.setState({ selectedTerms: value });
        this.setState({ selectedIndex: index });
        this.setState({ selectedOption: value });
        localStorage.setItem(
            "residual_value",
            this.state.estimatedresidualvalue[index]
        );
    };

    openInNewTab = (event) => {
        //window.open(stickerImage, '_blank');
        const imageUrl = event.target.src;
        window.open(imageUrl, "_blank");
    };

    finalQuote = (index) => {
        const customerType = localStorage.getItem("CustomerType");
        if (customerType === "Dealer") 
        {
                let selectedBank = localStorage.getItem("selectedBank");
                if (selectedBank === null || selectedBank === '') {
                            this.setState({ showPopup1: false });
                            this.setState({ showPopup: false });
                            Swal({
                                text: "Finance partner field should not be empty.",
                                icon: "error",
                                button: "OK",
                            });
                            return false;
                     } 
        if (
            this.state.selectedOption === "84" &&
            this.state.monthlypayment[6] !== undefined &&
            this.state.monthlypayment[6] > 0
        ) {
            this.setState({ showPopup: true });
        } else if (
            this.state.selectedOption === "72" &&
            this.state.monthlypayment[5] !== undefined &&
            this.state.monthlypayment[5] > 0
        ) {
                     this.setState({ showPopup: true });
        } else if (
            this.state.selectedOption === "60" &&
            this.state.monthlypayment[4] !== undefined &&
            this.state.monthlypayment[4] > 0
        ) {
            this.setState({ showPopup: true });
        } else if (
            this.state.selectedOption === "48" &&
            this.state.monthlypayment[3] !== undefined &&
            this.state.monthlypayment[3] > 0
        ) {
            this.setState({ showPopup: true });
        } else if (
            this.state.selectedOption === "36" &&
            this.state.monthlypayment[2] !== undefined &&
            this.state.monthlypayment[2] > 0
        ) {
                     this.setState({ showPopup: true });
        } else if (
            this.state.selectedOption === "24" &&
            this.state.monthlypayment[1] !== undefined &&
            this.state.monthlypayment[1] > 0
        ) {
            this.setState({ showPopup: true });
        } else if (
            this.state.selectedOption === "12" &&
            this.state.monthlypayment[0] !== undefined &&
            this.state.monthlypayment[0] > 0
        ) {
            this.setState({ showPopup: true });
        } 
        else {
            this.setState({ showPopup1: false });
            this.setState({ showPopup: false });
            Swal({
                text: "You should have PIF monthly rate to confirm.",
                icon: "error",
                button: "OK",
            });
            return false;
        }
 this.setState({
                    quotedefineStatus1: true,
            });
        } 
        else {
             if (
            this.state.selectedOption === "84" &&
            this.state.monthlypayment[6] !== undefined &&
            this.state.monthlypayment[6] > 0
        ) {
            this.setState({ showPopup1: true });
        } else if (
            this.state.selectedOption === "72" &&
            this.state.monthlypayment[5] !== undefined &&
            this.state.monthlypayment[5] > 0
        ) {
                     this.setState({ showPopup1: true });
        } else if (
            this.state.selectedOption === "60" &&
            this.state.monthlypayment[4] !== undefined &&
            this.state.monthlypayment[4] > 0
        ) {
            this.setState({ showPopup1: true });
        } else if (
            this.state.selectedOption === "48" &&
            this.state.monthlypayment[3] !== undefined &&
            this.state.monthlypayment[3] > 0
        ) {
            this.setState({ showPopup1: true });
        } else if (
            this.state.selectedOption === "36" &&
            this.state.monthlypayment[2] !== undefined &&
            this.state.monthlypayment[2] > 0
        ) {
                     this.setState({ showPopup1: true });
        } else if (
            this.state.selectedOption === "24" &&
            this.state.monthlypayment[1] !== undefined &&
            this.state.monthlypayment[1] > 0
        ) {
            this.setState({ showPopup1: true });
        } else if (
            this.state.selectedOption === "12" &&
            this.state.monthlypayment[0] !== undefined &&
            this.state.monthlypayment[0] > 0
        ) {
            this.setState({ showPopup1: true });
        } else {
            this.setState({ showPopup1: false });
            this.setState({ showPopup: false });
            Swal({
                text: "You should have PIF monthly rate to confirm.",
                icon: "error",
                button: "OK",
            });
            return false;
        }
        }
    };

    handleItemClick = (index) => {
        this.setState((prevState) => ({
            expandedIndex: prevState.expandedIndex === index ? null : index,
        }));
    };

    handleComplete = () => {
    };

    handleConventionalRateChange = (e) => {
        if (!this.state.purchasepricevalue) {
            //    alert("Please Fill Purchase Price Field");
            Swal({
                text: "Please Fill Purchase Price Field",
                icon: "error",
                button: "OK",
            });

            return false;
        }
        //const newConventionalRate = isNaN(parseFloat(e.target.value))?'':parseFloat(e.target.value);
        const { value } = e.target;
        if (/^-?\d*\.?\d{0,4}$/.test(value)) {
            this.setState({ mycustomcu: value });
            this.setState({ mycustomfu: +value + 0.5 });
            this.setState({ conventionalrate: value });
        }
        const pifRate = isNaN(value) ? "" : Number(value) + Number(0.5);
        this.setState({
            pifrate: pifRate,
            conventionalrate: value,
        });
        this.setState({ value }, () => {
            let numbersAndDecimalpft = value.toString();
            let sectionspft = numbersAndDecimalpft.split(".");
            let integerPartpft = sectionspft[0];
            let decimalPartpft = sectionspft[1] ? sectionspft[1].substring(0, 2) : "";
            let formattedValuepft =
                sectionspft.length === 2
                    ? `${integerPartpft}.${decimalPartpft}`
                    : integerPartpft;
            let numbersAndDecimalct = value;
            let sectionsct = numbersAndDecimalct.split(".");
            let integerPartct = sectionsct[0];
            let decimalPartct = sectionsct[1] ? sectionsct[1].substring(0, 2) : "";
            let formattedValuect =
                sectionspft.length === 2
                    ? `${integerPartpft}.${decimalPartpft}`
                    : integerPartpft;
            this.setState({ conventionalrate: value });
            const pifRate = isNaN(value) ? "" : Number(value) + Number(0.5);
            this.setState({
                pifrate: pifRate,
                conventionalrate: value,
            });

            const adjustedvehicleprice =
                parseFloat(this.state.purchasepricevalue.replace(/,/g, "")) -
                (parseFloat(this.state.tradeinvalue.replace(/,/g, "")) || 0) +
                (parseFloat(this.state.tradeinpayoff.replace(/,/g, "")) || 0);
            const totalamounttobefinanced =
                adjustedvehicleprice -
                (parseFloat(this.state.downpayment.replace(/,/g, "")) || 0) +
                (parseFloat(this.state.insurance.replace(/,/g, "")) || 0) +
                (parseFloat(this.state.otherfees.replace(/,/g, "")) || 0) +
                (parseFloat(this.state.salestax) || 0);
            // alert(totalamounttobefinanced);
            this.setState({ totalamountfinanced: totalamounttobefinanced });
            const calculateMonthlyPaymentC = (
                totalLoanAmount,
                termMonths,
                monthlyInterestRate
            ) => {
                return (
                    (totalLoanAmount * monthlyInterestRate) /
                    (1 - Math.pow(1 + monthlyInterestRate, -termMonths))
                );
            };
            const calculateMonthlyPayment = (
                totalLoanAmount,
                termMonths,
                pifRate,
                endBalance
            ) => {
                var loanAmount = totalLoanAmount;
                var endBalance = endBalance;
                var monthlyInterestRate = pifRate;
                var termMonths = termMonths;
                let monthlyPayment = 0;
                let epsilon = 0.01; // Tolerance for convergence
                let maxIterations = 1000; // Maximum number of iterations
                for (let i = 0; i < maxIterations; i++) {
                    let remainingBalance = loanAmount;
                    // Calculate remaining balance using the current monthly payment
                    for (let j = 0; j < termMonths; j++) {
                        let interest = remainingBalance * monthlyInterestRate;
                        let principal = monthlyPayment - interest;
                        remainingBalance -= principal;
                    }
                    // Check if the remaining balance is close enough to the end balance
                    if (Math.abs(remainingBalance - endBalance) < epsilon) {
                        break; // Stop iteration if close enough
                    }
                    // Adjust monthly payment based on the difference between calculated remaining balance and end balance
                    monthlyPayment += (remainingBalance - endBalance) / termMonths;
                }
                return monthlyPayment;
            };

            // Terms for calculation
            const terms = [12, 24, 36, 48, 60, 72, 84];
            const conventionalRate = this.state.conventionalrate / 100 / 12;
            const pifRate2 = this.state.pifrate / 100 / 12;
            const estimatervcArray = this.state.estimatedresidualvalue;
            let pifMonthlyPayments = [];
            let conventionalMonthlyPayments = [];
            // estimatervcArray.forEach(estimatervcValue => {
            terms.forEach((term, index) => {
                // // Calculate monthly payment for PIF rate
                //var toalamountfinceddata = this.state.totalamountfinanced- this.state.estimatedresidualvalue[index]
                var toalamountfinceddata = totalamounttobefinanced;
                const pifMonthlyPayment = calculateMonthlyPayment(
                    toalamountfinceddata,
                    term,
                    pifRate2,
                    this.state.estimatedresidualvalue[index]
                );
                pifMonthlyPayments.push(pifMonthlyPayment);
                // Calculate monthly payment for conventional rate
                const conventionalMonthlyPayment = calculateMonthlyPaymentC(
                    toalamountfinceddata,
                    term,
                    conventionalRate,
                    this.state.estimatedresidualvalue[index]
                );
                conventionalMonthlyPayments.push(conventionalMonthlyPayment);
            });
            //});
            this.setState({
                monthlypayment: pifMonthlyPayments,
                monthlypaymentc: conventionalMonthlyPayments,
            });
        });
    };

    // error messages
    editDataa = (month) => {
        // this.setState({ estimatedresidualvalue: 0});
        // document.getElementById('editableInput'+month).value = 0;
        if (month == 12) {
        

         this.setState({ popupmonth: month });
        
         this.setState({ showcustomerPopup2: true });
        

            this.setState({ showReset12: true });
        

        }
        if (month == 24) {

 this.setState({ popupmonth: month });
    this.setState({ showcustomerPopup2: true });


            this.setState({ showReset24: true });
        }
        if (month == 36) {


 this.setState({ popupmonth: month });
                this.setState({ showcustomerPopup2: true });
            this.setState({ showReset36: true });
        }
        if (month == 48) {

 this.setState({ popupmonth: month });
                this.setState({ showcustomerPopup2: true });
            this.setState({ showReset48: true });
        }
        if (month == 60) {


 this.setState({ popupmonth: month });
                this.setState({ showcustomerPopup2: true });

            this.setState({ showReset60: true });
        }
        if (month == 72) {


 this.setState({ popupmonth: month });
                this.setState({ showcustomerPopup2: true });
            this.setState({ showReset72: true });
        }
        if (month == 84) {

             this.setState({ popupmonth: month });
                this.setState({ showcustomerPopup2: true });
            this.setState({ showReset84: true });
        }
        // document
        //     .getElementById("editableInput" + month)
        //     .removeAttribute("disabled");
    };

    resetDataa = (month) => {
        if (month == 12) {
            this.setState({ showReset12: false });
            document.getElementById("editableInput" + month).value =
                this.state.prevestimatedresidualvalue12;
            const updatedArray = [...this.state.estimatedresidualvalue];
            updatedArray[0] = this.state.prevestimatedresidualvalue12;
            this.setState({
                estimatedresidualvalue: updatedArray,
            });
            localStorage.setItem("residual_value", updatedArray[0]);
            // Terms for calculation
            const pifRate2 = this.state.pifrate / 100 / 12;
            const estimatervcArray = updatedArray;
            var loanAmount = this.state.totalamountfinanced.toFixed(2);
            var endBalance = updatedArray[0];
            var monthlyInterestRate = pifRate2;
            var termMonths = 12;
            let monthlyPayment = 0;
            let epsilon = 0.01; // Tolerance for convergence
            let maxIterations = 1000; // Maximum number of iterations
            for (let i = 0; i < maxIterations; i++) {
                let remainingBalance = loanAmount;
                // Calculate remaining balance using the current monthly payment
                for (let j = 0; j < termMonths; j++) {
                    let interest = remainingBalance * monthlyInterestRate;
                    let principal = monthlyPayment - interest;
                    remainingBalance -= principal;
                }
                // Check if the remaining balance is close enough to the end balance
                if (Math.abs(remainingBalance - endBalance) < epsilon) {
                    break; // Stop iteration if close enough
                }
                // Adjust monthly payment based on the difference between calculated remaining balance and end balance
                monthlyPayment += (remainingBalance - endBalance) / termMonths;
            }
            // console.log(pifMonthlyPayments);
            this.setState({
                monthlypayment: [monthlyPayment, ...this.state.monthlypayment.slice(1)],
            });
        }
        if (month == 24) {
            this.setState({ showReset24: false });
            document.getElementById("editableInput" + month).value =
                this.state.prevestimatedresidualvalue24;
            const updatedArray = [...this.state.estimatedresidualvalue];
            // Update the first index with the value from estimatervcArray
            updatedArray[1] = this.state.prevestimatedresidualvalue24;
            this.setState({
                estimatedresidualvalue: updatedArray,
            });
            localStorage.setItem("residual_value", updatedArray[1]);
            const pifRate2 = this.state.pifrate / 100 / 12;
            const estimatervcArray = updatedArray;
            var loanAmount = this.state.totalamountfinanced.toFixed(2);
            var endBalance = updatedArray[1];
            var monthlyInterestRate = pifRate2;
            var termMonths = 24;
            let monthlyPayment = 0;
            let epsilon = 0.01; // Tolerance for convergence
            let maxIterations = 1000; // Maximum number of iterations
            for (let i = 0; i < maxIterations; i++) {
                let remainingBalance = loanAmount;
                // Calculate remaining balance using the current monthly payment
                for (let j = 0; j < termMonths; j++) {
                    let interest = remainingBalance * monthlyInterestRate;
                    let principal = monthlyPayment - interest;
                    remainingBalance -= principal;
                }
                // Check if the remaining balance is close enough to the end balance
                if (Math.abs(remainingBalance - endBalance) < epsilon) {
                    break; // Stop iteration if close enough
                }
                // Adjust monthly payment based on the difference between calculated remaining balance and end balance
                monthlyPayment += (remainingBalance - endBalance) / termMonths;
            }
            this.setState({
                monthlypayment: [
                    this.state.monthlypayment[0], // Keep the first element unchanged
                    monthlyPayment, // Update the second element
                    ...this.state.monthlypayment.slice(2), // Keep the rest of the elements unchanged
                ],
            });
        }
        if (month == 36) {
            this.setState({ showReset36: false });
            document.getElementById("editableInput" + month).value =
                this.state.prevestimatedresidualvalue36;

            const updatedArray = [...this.state.estimatedresidualvalue];

            // Update the first index with the value from estimatervcArray
            updatedArray[2] = this.state.prevestimatedresidualvalue36;
            this.setState({
                estimatedresidualvalue: updatedArray,
            });
            localStorage.setItem("residual_value", updatedArray[2]);
            const pifRate2 = this.state.pifrate / 100 / 12;
            const estimatervcArray = updatedArray;
            var loanAmount = this.state.totalamountfinanced.toFixed(2);
            var endBalance = updatedArray[2];
            var monthlyInterestRate = pifRate2;
            var termMonths = 36;
            let monthlyPayment = 0;
            let epsilon = 0.01; // Tolerance for convergence
            let maxIterations = 1000; // Maximum number of iterations

            for (let i = 0; i < maxIterations; i++) {
                let remainingBalance = loanAmount;

                // Calculate remaining balance using the current monthly payment
                for (let j = 0; j < termMonths; j++) {
                    let interest = remainingBalance * monthlyInterestRate;
                    let principal = monthlyPayment - interest;
                    remainingBalance -= principal;
                }

                // Check if the remaining balance is close enough to the end balance
                if (Math.abs(remainingBalance - endBalance) < epsilon) {
                    break; // Stop iteration if close enough
                }

                // Adjust monthly payment based on the difference between calculated remaining balance and end balance
                monthlyPayment += (remainingBalance - endBalance) / termMonths;
            }

            this.setState({
                monthlypayment: [
                    this.state.monthlypayment[0],
                    this.state.monthlypayment[1], // Keep the first element unchanged
                    monthlyPayment, // Update the second element
                    ...this.state.monthlypayment.slice(3), // Keep the rest of the elements unchanged
                ],
            });
        }
        if (month == 48) {
            this.setState({ showReset48: false });
            document.getElementById("editableInput" + month).value =
                this.state.prevestimatedresidualvalue48;
            const updatedArray = [...this.state.estimatedresidualvalue];
        updatedArray[3] = this.state.prevestimatedresidualvalue48;
            this.setState({
                estimatedresidualvalue: updatedArray,
            });
            localStorage.setItem("residual_value", updatedArray[3]);
            const pifRate2 = this.state.pifrate / 100 / 12;
            const estimatervcArray = updatedArray;
            var loanAmount = this.state.totalamountfinanced.toFixed(2);
            var endBalance = updatedArray[3];
            var monthlyInterestRate = pifRate2;
            var termMonths = 48;
            let monthlyPayment = 0;
            let epsilon = 0.01; // Tolerance for convergence
            let maxIterations = 1000; // Maximum number of iterations

            for (let i = 0; i < maxIterations; i++) {
                let remainingBalance = loanAmount;

                // Calculate remaining balance using the current monthly payment
                for (let j = 0; j < termMonths; j++) {
                    let interest = remainingBalance * monthlyInterestRate;
                    let principal = monthlyPayment - interest;
                    remainingBalance -= principal;
                }

                // Check if the remaining balance is close enough to the end balance
                if (Math.abs(remainingBalance - endBalance) < epsilon) {
                    break; // Stop iteration if close enough
                }

                // Adjust monthly payment based on the difference between calculated remaining balance and end balance
                monthlyPayment += (remainingBalance - endBalance) / termMonths;
            }

            this.setState({
                monthlypayment: [
                    this.state.monthlypayment[0],
                    this.state.monthlypayment[1],
                    this.state.monthlypayment[2], // Keep the first element unchanged
                    monthlyPayment, // Update the second element
                    ...this.state.monthlypayment.slice(4), // Keep the rest of the elements unchanged
                ],
            });
        }
        if (month == 60) {
            this.setState({ showReset60: false });
            document.getElementById("editableInput" + month).value =
                this.state.prevestimatedresidualvalue60;

            const updatedArray = [...this.state.estimatedresidualvalue];
            // Update the first index with the value from estimatervcArray
            updatedArray[4] = this.state.prevestimatedresidualvalue60;
            console.log("test kaka");

            console.log(updatedArray);
            this.setState({
                estimatedresidualvalue: updatedArray,
            });
            localStorage.setItem("residual_value", updatedArray[4]);
            // this.setState({
            //     estimatedresidualvaluemyname: updatedArray,

            // });

            const pifRate2 = this.state.pifrate / 100 / 12;
            console.log("kana");
            console.log(updatedArray);
            const estimatervcArray = updatedArray;
            var loanAmount = this.state.totalamountfinanced.toFixed(2);
            var endBalance = updatedArray[4];
            var monthlyInterestRate = pifRate2;
            var termMonths = 60;
            let monthlyPayment = 0;
            let epsilon = 0.01; // Tolerance for convergence
            let maxIterations = 1000; // Maximum number of iterations

            for (let i = 0; i < maxIterations; i++) {
                let remainingBalance = loanAmount;

                // Calculate remaining balance using the current monthly payment
                for (let j = 0; j < termMonths; j++) {
                    let interest = remainingBalance * monthlyInterestRate;
                    let principal = monthlyPayment - interest;
                    remainingBalance -= principal;
                }

                // Check if the remaining balance is close enough to the end balance
                if (Math.abs(remainingBalance - endBalance) < epsilon) {
                    break; // Stop iteration if close enough
                }

                // Adjust monthly payment based on the difference between calculated remaining balance and end balance
                monthlyPayment += (remainingBalance - endBalance) / termMonths;
            }

            this.setState({
                monthlypayment: [
                    this.state.monthlypayment[0],
                    this.state.monthlypayment[1],
                    this.state.monthlypayment[2],
                    this.state.monthlypayment[3], // Keep the first element unchanged
                    monthlyPayment, // Update the second element
                    ...this.state.monthlypayment.slice(5), // Keep the rest of the elements unchanged
                ],
            });
        }
        if (month == 72) {
            this.setState({ showReset72: false });
            document.getElementById("editableInput" + month).value =
                this.state.prevestimatedresidualvalue72;

            const updatedArray = [...this.state.estimatedresidualvalue];

            // Update the first index with the value from estimatervcArray
            updatedArray[5] = this.state.prevestimatedresidualvalue72;

            console.log("test kaka");

            console.log(updatedArray);
            this.setState({
                estimatedresidualvalue: updatedArray,
            });
            localStorage.setItem("residual_value", updatedArray[5]);
            // this.setState({
            //     estimatedresidualvaluemyname: updatedArray,

            // });

            const pifRate2 = this.state.pifrate / 100 / 12;
            console.log("kana");
            console.log(updatedArray);
            const estimatervcArray = updatedArray;
            var loanAmount = this.state.totalamountfinanced.toFixed(2);
            var endBalance = updatedArray[5];
            var monthlyInterestRate = pifRate2;
            var termMonths = 72;
            let monthlyPayment = 0;
            let epsilon = 0.01; // Tolerance for convergence
            let maxIterations = 1000; // Maximum number of iterations

            for (let i = 0; i < maxIterations; i++) {
                let remainingBalance = loanAmount;

                // Calculate remaining balance using the current monthly payment
                for (let j = 0; j < termMonths; j++) {
                    let interest = remainingBalance * monthlyInterestRate;
                    let principal = monthlyPayment - interest;
                    remainingBalance -= principal;
                }

                // Check if the remaining balance is close enough to the end balance
                if (Math.abs(remainingBalance - endBalance) < epsilon) {
                    break; // Stop iteration if close enough
                }

                // Adjust monthly payment based on the difference between calculated remaining balance and end balance
                monthlyPayment += (remainingBalance - endBalance) / termMonths;
            }

            this.setState({
                monthlypayment: [
                    this.state.monthlypayment[0],
                    this.state.monthlypayment[1],
                    this.state.monthlypayment[2],
                    this.state.monthlypayment[3],
                    this.state.monthlypayment[4], // Keep the first element unchanged
                    monthlyPayment, // Update the second element
                    ...this.state.monthlypayment.slice(6), // Keep the rest of the elements unchanged
                ],
            });
        }
        if (month == 84) {
            this.setState({ showReset84: false });
            document.getElementById("editableInput" + month).value =
                this.state.prevestimatedresidualvalue84;

            const updatedArray = [...this.state.estimatedresidualvalue];

            console.log("mainesocha");

            // Update the first index with the value from estimatervcArray

            console.log(this.state.prevestimatedresidualvalue84);
            console.log("mainesocha2" + this.state.prevestimatedresidualvalue84);

            updatedArray[6] = this.state.prevestimatedresidualvalue84;

            console.log("test kaka");

            console.log(updatedArray);
            this.setState({
                estimatedresidualvalue: updatedArray,
            });

            localStorage.setItem("residual_value", updatedArray[6]);

            // this.setState({
            //     estimatedresidualbynamevalue: updatedArray,

            // });

            const pifRate2 = this.state.pifrate / 100 / 12;
            console.log("kana");
            console.log(updatedArray);
            const estimatervcArray = updatedArray;
            var loanAmount = this.state.totalamountfinanced.toFixed(2);
            var endBalance = updatedArray[6];
            var monthlyInterestRate = pifRate2;
            var termMonths = 84;
            let monthlyPayment = 0;
            let epsilon = 0.01; // Tolerance for convergence
            let maxIterations = 1000; // Maximum number of iterations

            for (let i = 0; i < maxIterations; i++) {
                let remainingBalance = loanAmount;

                // Calculate remaining balance using the current monthly payment
                for (let j = 0; j < termMonths; j++) {
                    let interest = remainingBalance * monthlyInterestRate;
                    let principal = monthlyPayment - interest;
                    remainingBalance -= principal;
                }

                // Check if the remaining balance is close enough to the end balance
                if (Math.abs(remainingBalance - endBalance) < epsilon) {
                    break; // Stop iteration if close enough
                }

                // Adjust monthly payment based on the difference between calculated remaining balance and end balance
                monthlyPayment += (remainingBalance - endBalance) / termMonths;
            }

            console.log(monthlyPayment);

            this.setState({
                monthlypayment: [
                    this.state.monthlypayment[0],
                    this.state.monthlypayment[1],
                    this.state.monthlypayment[2],
                    this.state.monthlypayment[3],
                    this.state.monthlypayment[4],
                    this.state.monthlypayment[5], // Keep the first element unchanged
                    monthlyPayment, // Update the second element
                    ...this.state.monthlypayment.slice(7), // Keep the rest of the elements unchanged
                ],
            });
        }

        document
            .getElementById("editableInput" + month)
            .setAttribute("disabled", "disabled");
    };

    editData = () => {
        this.setState({ estimatedresidualvalue: 0 });
        document.getElementById("editableInput").value = 0;
      //  document.getElementById("editableInput").removeAttribute("disabled");
    };

    updateEstimateresiduavalue = (data) => {
        this.setState({
            estimatedresidualvalue: data,
        });
    };


    handleSuggestionClick = (suggestion) => {
        this.setState(
            {
                quoteid: suggestion.split("--")[0],
            },
            () => {
                this.handlequoteidchange({
                    target: { value: suggestion.split("--")[0] },
                });
            }
        );

        this.setState({
            suggestions: [],
        });

        // this.handlequoteidchange(suggestion.split('--')[0])
    };

    handlePhone = (e) => {
        const { value } = e.target;

        // alert(value);
        const formattedvalue =
            value != undefined
                ? value.replace(/\D/g, "").replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
                : "";

        if (formattedvalue && formattedvalue.length > 12) {
            //alert('Please enter surname.');

            Swal({
                text: "Please enter a 10-digit phone number.",
                icon: "error",
                button: "OK",
            });

            return false;
        }

        this.setState({ phone: [formattedvalue] });
    };

    handlePhone1 = (e) => {
        const { value } = e.target;
        const numericValue = value.replace(/\D/g, "");

        const formattedvalue =
            value != undefined
                ? value.replace(/\D/g, "").replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
                : "";

        if (formattedvalue && formattedvalue.length > 12) {
            Swal({
                text: "Please enter a 10-digit phone number.",
                icon: "error",
                button: "OK",
            });

            return false;
        }

        this.setState({ phone1: [formattedvalue] });
    };

    handlePurchasevalue(e) {
        const { value } = e.target;
        const numbersAndDecimal = value.replace(/[^0-9.]/g, ""); // Keep only digits and dot
        const sections = numbersAndDecimal.split("."); // Split at the dot, if present
        const integerPart = sections[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Add commas for thousand separators
        const decimalPart = sections[1] ? sections[1].substring(0, 2) : ""; // Allow up to 4 decimal places
        const formattedValue =
            sections.length === 2 ? `${integerPart}.${decimalPart}` : integerPart;
        this.setState({ purchasepricevalue: formattedValue });

        this.setState({ value }, () => {
            const adjustedvehicleprice =
                parseFloat(this.state.purchasepricevalue.replace(/,/g, "")) -
                (parseFloat(this.state.tradeinvalue.replace(/,/g, "")) || 0) +
                (parseFloat(this.state.tradeinpayoff.replace(/,/g, "")) || 0);
            const totalamounttobefinanced =
                adjustedvehicleprice -
                (parseFloat(this.state.downpayment.replace(/,/g, "")) || 0) +
                (parseFloat(this.state.insurance.replace(/,/g, "")) || 0) +
                (parseFloat(this.state.otherfees.replace(/,/g, "")) || 0) +
                (parseFloat(this.state.salestax) || 0);

            console.log("totalamounttobefinanced" + totalamounttobefinanced);

            this.setState({ totalamountfinanced: totalamounttobefinanced });
            let estimatervcArray1 = [...this.state.estimatedresidualvaluemyname]; // Create a copy of the array to avoid mutating state directly

            console.log("purchasechange" + this.state.msrppercentagedata);
            var msrppercentage1 = this.state.msrppercentagedata;
            // Create a new array to store the modified values
            let modifiedArray = [];
            // Iterate through the array

            console.log(estimatervcArray1);

            for (let i = 0; i < estimatervcArray1.length; i++) {
                // Check if the array element is greater than the target value
                const targetValue = totalamounttobefinanced;

                console.log("target valeu");
                console.log(targetValue);

                if (parseFloat(estimatervcArray1[i]) > targetValue) {
                    // Calculate the new value based on msrppercentage1

                    const numericValue = parseFloat(
                        this.state.msrpvalue.replace(/\$|,/g, "")
                    ).toFixed(2);
                    const newValue1 = (numericValue * msrppercentage1[i]) / 100;

                    // Push the new value to the modifiedArray
                    modifiedArray.push(newValue1);
                } else {
                    // If the value is not greater than the target value, push the original value
                    modifiedArray.push(estimatervcArray1[i]);
                }
            }
            console.log(
                "Previous estimatedresidualvalue:",
                this.state.estimatedresidualvaluemyname
            );
            console.log("Modified Array:", modifiedArray);

            modifiedArray = modifiedArray.map((value) =>
                parseFloat(value.toFixed(2))
            );

            this.setState({
                prevestimatedresidualvalue12: modifiedArray[0],
            });

            this.setState({
                prevestimatedresidualvalue24: modifiedArray[1],
            });

            this.setState({
                prevestimatedresidualvalue36: modifiedArray[2],
            });
            this.setState({
                prevestimatedresidualvalue48: modifiedArray[3],
            });
            this.setState({
                prevestimatedresidualvalue60: modifiedArray[4],
            });
            this.setState({
                prevestimatedresidualvalue72: modifiedArray[5],
            });
            this.setState({
                prevestimatedresidualvalue84: modifiedArray[6],
            });

            // this.setState({
            //     estimatedresidualvalue: modifiedArray,
            // });
            this.setState(
                {
                    estimatedresidualvalue: modifiedArray,
                },
                () => {
                    console.log(
                        "aftet Modified Array:",
                        this.state.estimatedresidualvalue
                    );

                    localStorage.setItem("residual_value", modifiedArray[6]);

                    // this.setState({
                    //     estimatedresidualvaluemyname:modifiedArray
                    // });
                    const calculateMonthlyPaymentC = (
                        totalLoanAmount,
                        termMonths,
                        monthlyInterestRate
                    ) => {
                        return (
                            (totalLoanAmount * monthlyInterestRate) /
                            (1 - Math.pow(1 + monthlyInterestRate, -termMonths))
                        );
                    };
                    const calculateMonthlyPayment = (
                        totalLoanAmount,
                        termMonths,
                        pifRate,
                        endBalance
                    ) => {
                        // const monthlyInterestRate = pifRate;
                        //     return (totalLoanAmount * monthlyInterestRate) / (1 - Math.pow(1 + monthlyInterestRate, -termMonths));

                        var loanAmount = totalLoanAmount;
                        var endBalance = endBalance;
                        var monthlyInterestRate = pifRate;
                        console.log("dead line3---" + pifRate);
                        console.log(endBalance);
                        var termMonths = termMonths;
                        let monthlyPayment = 0;
                        let epsilon = 0.01; // Tolerance for convergence
                        let maxIterations = 1000; // Maximum number of iterations

                        for (let i = 0; i < maxIterations; i++) {
                            let remainingBalance = loanAmount;

                            // Calculate remaining balance using the current monthly payment
                            for (let j = 0; j < termMonths; j++) {
                                let interest = remainingBalance * monthlyInterestRate;
                                let principal = monthlyPayment - interest;
                                remainingBalance -= principal;
                            }

                            // Check if the remaining balance is close enough to the end balance
                            if (Math.abs(remainingBalance - endBalance) < epsilon) {
                                break; // Stop iteration if close enough
                            }

                            // Adjust monthly payment based on the difference between calculated remaining balance and end balance
                            monthlyPayment += (remainingBalance - endBalance) / termMonths;
                        }
                        console.log("deadline2" + monthlyPayment);
                        return monthlyPayment;
                    };

                    // Terms for calculation
                    const terms = [12, 24, 36, 48, 60, 72, 84];


// alert(this.state.conventionalrate);
// alert(this.state.mycustomcu);

                    const conventionalRate = this.state.mycustomcu / 100 / 12;
                    const pifRate2 = this.state.mycustomfu / 100 / 12;
                    const estimatervcArray = this.state.estimatedresidualvalue;
                    let pifMonthlyPayments = [];
                    let conventionalMonthlyPayments = [];
                    // estimatervcArray.forEach(estimatervcValue => {
                    terms.forEach((term, index) => {
                        // // Calculate monthly payment for PIF rate
                        // var toalamountfinceddata = this.state.totalamountfinanced- this.state.estimatedresidualvalue[index]
                        var toalamountfinceddata = totalamounttobefinanced;
                        const pifMonthlyPayment = calculateMonthlyPayment(
                            toalamountfinceddata,
                            term,
                            pifRate2,
                            this.state.estimatedresidualvalue[index]
                        );
                        pifMonthlyPayments.push(pifMonthlyPayment);
                        // Calculate monthly payment for conventional rate
                        const conventionalMonthlyPayment = calculateMonthlyPaymentC(
                            toalamountfinceddata,
                            term,
                            conventionalRate
                        );
                        conventionalMonthlyPayments.push(conventionalMonthlyPayment);
                    });
                    //});
                    this.setState({
                        monthlypayment: pifMonthlyPayments,
                        monthlypaymentc: conventionalMonthlyPayments,
                    });
                }
            );
        });
    }

    appendcoapplicant = (event) => {
const containers = document.getElementsByClassName('customer'); // No dot needed
if (containers.length > 0) {
 const container = containers[0];
if(event.target.checked)
{
        container.classList.add('scrollable');
}
else
{
     container.classList.remove('scrollable');
}
}
        this.setState({
            coapplicantmember: event.target.checked,
        });
    };
    handleTradeinpayoff(e) {
        // const tradeinpayoff = isNaN(parseFloat(e.target.value))?'':parseFloat(e.target.value);
        if (!this.state.purchasepricevalue) {
            // alert("Please Fill Purchase Price Field");
            Swal({
                text: "Please Fill Purchase Price Field",
                icon: "error",
                button: "OK",
            });
            return false;
        }
        const { value } = e.target;
        const numbersAndDecimal = value.replace(/[^0-9.]/g, ""); // Keep only digits and dot
        const sections = numbersAndDecimal.split("."); // Split at the dot, if present
        const integerPart = sections[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Add commas for thousand separators
        const decimalPart = sections[1] ? sections[1].substring(0, 2) : ""; // Allow up to 4 decimal places
        const formattedValue =
            sections.length === 2 ? `${integerPart}.${decimalPart}` : integerPart;
        // if (/^-?\d*\.?\d{0,4}$/.test(value)) {
        this.setState({ tradeinpayoff: formattedValue });
        // }

        this.setState({ value }, () => {
            // const adjustedvehicleprice = parseFloat(this.state.purchasepricevalue) - (parseFloat(this.state.tradeinvalue) || 0) + (parseFloat(this.state.tradeinpayoff) || 0);
            //    const totalamounttobefinanced = adjustedvehicleprice - (parseFloat(this.state.downpayment) || 0) + (parseFloat(this.state.insurance) || 0) + (parseFloat(this.state.otherfees) || 0) + (parseFloat(this.state.salestax) || 0);
            const adjustedvehicleprice =
                parseFloat(this.state.purchasepricevalue.replace(/,/g, "")) -
                (parseFloat(this.state.tradeinvalue.replace(/,/g, "")) || 0) +
                (parseFloat(this.state.tradeinpayoff.replace(/,/g, "")) || 0);
            const totalamounttobefinanced =
                adjustedvehicleprice -
                (parseFloat(this.state.downpayment.replace(/,/g, "")) || 0) +
                (parseFloat(this.state.insurance.replace(/,/g, "")) || 0) +
                (parseFloat(this.state.otherfees.replace(/,/g, "")) || 0) +
                (parseFloat(this.state.salestax) || 0);
            // alert(totalamounttobefinanced);
            this.setState({ totalamountfinanced: totalamounttobefinanced });
            let estimatervcArray1 = [...this.state.estimatedresidualvaluemyname]; // Create a copy of the array to avoid mutating state directly
            console.log("target", estimatervcArray1);
            var msrppercentage1 = this.state.msrppercentagedata;
            // Create a new array to store the modified values
            let modifiedArray = [];
            // Iterate through the array
            for (let i = 0; i < estimatervcArray1.length; i++) {
                // Check if the array element is greater than the target value
                const targetValue = totalamounttobefinanced;
                if (parseFloat(estimatervcArray1[i]) > targetValue) {
                    // Calculate the new value based on msrppercentage1
                    // const newValue1 =    (this.state.msrpvalue * msrppercentage1[i]) / 100;
                    const numericValue = parseFloat(
                        this.state.msrpvalue.replace(/\$|,/g, "")
                    ).toFixed(2);
                    const newValue1 = (numericValue * msrppercentage1[i]) / 100;
                    // Push the new value to the modifiedArray
                    modifiedArray.push(newValue1);
                } else {
                    // If the value is not greater than the target value, push the original value
                    modifiedArray.push(estimatervcArray1[i]);
                }
            }

            // Set the state with the modified array
            console.log(
                "Previous estimatedresidualvalue:",
                this.state.estimatedresidualvaluemyname
            );
            console.log("Modified Array:", modifiedArray);

            modifiedArray = modifiedArray.map((value) =>
                parseFloat(value.toFixed(2))
            );

            // this.setState({
            //     estimatedresidualvalue: modifiedArray,
            // });

            localStorage.setItem("residual_value", modifiedArray[6]);

            // this.setState({
            //                                                     estimatedresidualvaluemyname:modifiedArray
            //                                                 });

            this.setState({
                prevestimatedresidualvalue12: modifiedArray[0],
            });

            this.setState({
                prevestimatedresidualvalue24: modifiedArray[1],
            });

            this.setState({
                prevestimatedresidualvalue36: modifiedArray[2],
            });
            this.setState({
                prevestimatedresidualvalue48: modifiedArray[3],
            });
            this.setState({
                prevestimatedresidualvalue60: modifiedArray[4],
            });
            this.setState({
                prevestimatedresidualvalue72: modifiedArray[5],
            });
            this.setState({
                prevestimatedresidualvalue84: modifiedArray[6],
            });

            this.setState(
                {
                    estimatedresidualvalue: modifiedArray,
                },
                () => {
                    const calculateMonthlyPaymentC = (
                        totalLoanAmount,
                        termMonths,
                        monthlyInterestRate
                    ) => {
                        return (
                            (totalLoanAmount * monthlyInterestRate) /
                            (1 - Math.pow(1 + monthlyInterestRate, -termMonths))
                        );
                    };
                    const calculateMonthlyPayment = (
                        totalLoanAmount,
                        termMonths,
                        pifRate,
                        endBalance
                    ) => {
                        // const monthlyInterestRate = pifRate;
                        //     return (totalLoanAmount * monthlyInterestRate) / (1 - Math.pow(1 + monthlyInterestRate, -termMonths));

                        var loanAmount = totalLoanAmount;
                        var endBalance = endBalance;
                        var monthlyInterestRate = pifRate;
                        console.log("dead line4---" + pifRate);
                        console.log(endBalance);
                        var termMonths = termMonths;
                        let monthlyPayment = 0;
                        let epsilon = 0.01; // Tolerance for convergence
                        let maxIterations = 1000; // Maximum number of iterations

                        for (let i = 0; i < maxIterations; i++) {
                            let remainingBalance = loanAmount;

                            // Calculate remaining balance using the current monthly payment
                            for (let j = 0; j < termMonths; j++) {
                                let interest = remainingBalance * monthlyInterestRate;
                                let principal = monthlyPayment - interest;
                                remainingBalance -= principal;
                            }

                            // Check if the remaining balance is close enough to the end balance
                            if (Math.abs(remainingBalance - endBalance) < epsilon) {
                                break; // Stop iteration if close enough
                            }

                            // Adjust monthly payment based on the difference between calculated remaining balance and end balance
                            monthlyPayment += (remainingBalance - endBalance) / termMonths;
                        }

                        return monthlyPayment;
                    };

                    // Terms for calculation
                    const terms = [12, 24, 36, 48, 60, 72, 84];
                    const conventionalRate = this.state.mycustomcu / 100 / 12;
                    const pifRate2 = this.state.mycustomfu / 100 / 12;
                    const estimatervcArray = this.state.estimatedresidualvalue;
                    let pifMonthlyPayments = [];
                    let conventionalMonthlyPayments = [];

                    // estimatervcArray.forEach(estimatervcValue => {
                    terms.forEach((term, index) => {
                        // // Calculate monthly payment for PIF rate
                        // var toalamountfinceddata = this.state.totalamountfinanced- this.state.estimatedresidualvalue[index]
                        var toalamountfinceddata = totalamounttobefinanced;

                        const pifMonthlyPayment = calculateMonthlyPayment(
                            toalamountfinceddata,
                            term,
                            pifRate2,
                            toalamountfinceddata,
                            this.state.estimatedresidualvalue[index]
                        );
                        pifMonthlyPayments.push(pifMonthlyPayment);
                        // Calculate monthly payment for conventional rate
                        const conventionalMonthlyPayment = calculateMonthlyPaymentC(
                            toalamountfinceddata,
                            term,
                            conventionalRate
                        );
                        conventionalMonthlyPayments.push(conventionalMonthlyPayment);
                    });
                    //});
                    this.setState({
                        monthlypayment: pifMonthlyPayments,
                        monthlypaymentc: conventionalMonthlyPayments,
                    });
                }
            );
        });
    }

    handleSalestax(e) {
        //    const salestax = isNaN(parseFloat(e.target.value))?'':parseFloat(e.target.value);;

        const { value } = e.target;
        // if (/^-?\d*\.?\d*$/.test(value)) {

        // }

        // const numbersAndDecimal = value.replace(/[^0-9.]/g, ''); // Keep only digits and dot
        //         const sections = numbersAndDecimal.split('.'); // Split at the dot, if present
        //         const integerPart = sections[0].replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Add commas for thousand separators
        //         const decimalPart = sections[1] ? sections[1].substring(0, 4) : ''; // Allow up to 4 decimal places

        //         const formattedValue = sections.length === 2
        //             ? `${integerPart}.${decimalPart}`
        //             : integerPart;
        this.setState({ salestax: value });

        this.setState({ value }, () => {
            const adjustedvehicleprice =
                parseFloat(this.state.purchasepricevalue.replace(/,/g, "")) -
                (parseFloat(this.state.tradeinvalue.replace(/,/g, "")) || 0) +
                (parseFloat(this.state.tradeinpayoff.replace(/,/g, "")) || 0);
            const totalamounttobefinanced =
                adjustedvehicleprice -
                (parseFloat(this.state.downpayment.replace(/,/g, "")) || 0) +
                (parseFloat(this.state.insurance.replace(/,/g, "")) || 0) +
                (parseFloat(this.state.otherfees.replace(/,/g, "")) || 0) +
                (parseFloat(this.state.salestax) || 0);
            // alert(totalamounttobefinanced);
            this.setState({ totalamountfinanced: totalamounttobefinanced });
        });
    }

    handleSalestaxpercentage(e) {
        // const salestaxpercentage = isNaN(parseFloat(e.target.value))?'':parseFloat(e.target.value);;

        const { value } = e.target;
        // if (/^-?\d*\.?\d*$/.test(value)) {
        //            this.setState({ salestaxpercentage: value });
        //        }

        // const numbersAndDecimal = value.replace(/[^0-9.]/g, ''); // Keep only digits and dot
        //         const sections = numbersAndDecimal.split('.'); // Split at the dot, if present
        //         const integerPart = sections[0].replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Add commas for thousand separators
        //         const decimalPart = sections[1] ? sections[1].substring(0, 4) : ''; // Allow up to 4 decimal places

        //         const formattedValue = sections.length === 2
        //             ? `${integerPart}.${decimalPart}`
        //             : integerPart;
        this.setState({ salestaxpercentage: value });

        this.setState({ value }, () => {
            const adjustedvehicleprice =
                parseFloat(this.state.purchasepricevalue.replace(/,/g, "")) -
                (parseFloat(this.state.tradeinvalue.replace(/,/g, "")) || 0) +
                (parseFloat(this.state.tradeinpayoff.replace(/,/g, "")) || 0);
            //const totalamounttobefinanced = adjustedvehicleprice - (parseFloat(this.state.downpayment.replace(/,/g, '')) || 0) + (parseFloat(this.state.insurance.replace(/,/g, '')) || 0) + (parseFloat(this.state.otherfees.replace(/,/g, '')) || 0) + (parseFloat(this.state.salestax.replace(/,/g, '')) || 0);

            //const adjustedvehicleprice = parseFloat(this.state.purchasepricevalue) - (parseFloat(this.state.tradeinvalue) || 0) + (parseFloat(this.state.tradeinpayoff) || 0);
            const totalamounttobeforsalescalc1 =
                adjustedvehicleprice -
                (parseFloat(this.state.downpayment.replace(/,/g, "")) || 0);

            const salestaxpercentageDecimal = value / 100;
            const totalamounttobeforsalescalc =
                totalamounttobeforsalescalc1 * salestaxpercentageDecimal;

            this.setState({ salestax: totalamounttobeforsalescalc });

            const adjustedvehicleprice1 =
                parseFloat(this.state.purchasepricevalue.replace(/,/g, "")) -
                (parseFloat(this.state.tradeinvalue.replace(/,/g, "")) || 0) +
                (parseFloat(this.state.tradeinpayoff.replace(/,/g, "")) || 0);
            const totalamounttobefinanced =
                adjustedvehicleprice1 -
                (parseFloat(this.state.downpayment.replace(/,/g, "")) || 0) +
                (parseFloat(this.state.insurance.replace(/,/g, "")) || 0) +
                (parseFloat(this.state.otherfees.replace(/,/g, "")) || 0) +
                (parseFloat(totalamounttobeforsalescalc) || 0);
            // alert(totalamounttobefinanced);
            this.setState({ totalamountfinanced: totalamounttobefinanced });

            let estimatervcArray1 = [...this.state.estimatedresidualvaluemyname]; // Create a copy of the array to avoid mutating state directly
            console.log("target", estimatervcArray1);
            // var msrppercentage1=[8,7,5,4,2,2,1];
            var msrppercentage1 = this.state.msrppercentagedata;
            // Create a new array to store the modified values
            let modifiedArray = [];
            // Iterate through the array
            for (let i = 0; i < estimatervcArray1.length; i++) {
                // Check if the array element is greater than the target value
                const targetValue = totalamounttobefinanced;

                if (parseFloat(estimatervcArray1[i]) > targetValue) {
                    // Calculate the new value based on msrppercentage1
                    //const newValue1 =    (this.state.msrpvalue * msrppercentage1[i]) / 100;
                    const numericValue = parseFloat(
                        this.state.msrpvalue.replace(/\$|,/g, "")
                    ).toFixed(2);
                    const newValue1 = (numericValue * msrppercentage1[i]) / 100;
                    // Push the new value to the modifiedArray
                    modifiedArray.push(newValue1);
                } else {
                    // If the value is not greater than the target value, push the original value
                    modifiedArray.push(estimatervcArray1[i]);
                }
            }

            // Set the state with the modified array
            console.log(
                "Previous estimatedresidualvalue:",
                this.state.estimatedresidualvaluemyname
            );
            console.log("Modified Array:", modifiedArray);

            // this.setState({
            //     estimatedresidualvalue: modifiedArray,
            // });
            localStorage.setItem("residual_value", modifiedArray[6]);

            // this.setState({
            //                                                     estimatedresidualvaluemyname:modifiedArray
            //                                                 });

            this.setState({
                prevestimatedresidualvalue12: modifiedArray[0],
            });

            this.setState({
                prevestimatedresidualvalue24: modifiedArray[1],
            });

            this.setState({
                prevestimatedresidualvalue36: modifiedArray[2],
            });
            this.setState({
                prevestimatedresidualvalue48: modifiedArray[3],
            });
            this.setState({
                prevestimatedresidualvalue60: modifiedArray[4],
            });
            this.setState({
                prevestimatedresidualvalue72: modifiedArray[5],
            });
            this.setState({
                prevestimatedresidualvalue84: modifiedArray[6],
            });

            this.setState(
                {
                    estimatedresidualvalue: modifiedArray,
                },
                () => {
                    const calculateMonthlyPaymentC = (
                        totalLoanAmount,
                        termMonths,
                        monthlyInterestRate
                    ) => {
                        return (
                            (totalLoanAmount * monthlyInterestRate) /
                            (1 - Math.pow(1 + monthlyInterestRate, -termMonths))
                        );
                    };
                    const calculateMonthlyPayment = (
                        totalLoanAmount,
                        termMonths,
                        pifRate,
                        endBalance
                    ) => {
                        var loanAmount = totalLoanAmount;
                        var endBalance = endBalance;
                        var monthlyInterestRate = pifRate;
                        console.log("dead line5---" + pifRate);
                        console.log(endBalance);
                        var termMonths = termMonths;
                        let monthlyPayment = 0;
                        let epsilon = 0.01; // Tolerance for convergence
                        let maxIterations = 1000; // Maximum number of iterations

                        for (let i = 0; i < maxIterations; i++) {
                            let remainingBalance = loanAmount;

                            // Calculate remaining balance using the current monthly payment
                            for (let j = 0; j < termMonths; j++) {
                                let interest = remainingBalance * monthlyInterestRate;
                                let principal = monthlyPayment - interest;
                                remainingBalance -= principal;
                            }

                            // Check if the remaining balance is close enough to the end balance
                            if (Math.abs(remainingBalance - endBalance) < epsilon) {
                                break; // Stop iteration if close enough
                            }

                            // Adjust monthly payment based on the difference between calculated remaining balance and end balance
                            monthlyPayment += (remainingBalance - endBalance) / termMonths;
                        }
                        console.log("deadline2" + monthlyPayment);
                        return monthlyPayment;
                    };

                    // Terms for calculation
                    const terms = [12, 24, 36, 48, 60, 72, 84];
                    const conventionalRate = this.state.mycustomcu / 100 / 12;
                    const pifRate2 = this.state.mycustomfu / 100 / 12;
                    const estimatervcArray = this.state.estimatedresidualvalue;
                    let pifMonthlyPayments = [];
                    let conventionalMonthlyPayments = [];

                    // estimatervcArray.forEach(estimatervcValue => {
                    terms.forEach((term, index) => {
                        // // Calculate monthly payment for PIF rate
                        //var toalamountfinceddata = this.state.totalamountfinanced- this.state.estimatedresidualvalue[index]

                        var toalamountfinceddata = totalamounttobefinanced;

                        const pifMonthlyPayment = calculateMonthlyPayment(
                            toalamountfinceddata,
                            term,
                            pifRate2,
                            this.state.estimatedresidualvalue[index]
                        );
                        pifMonthlyPayments.push(pifMonthlyPayment);
                        // Calculate monthly payment for conventional rate
                        const conventionalMonthlyPayment = calculateMonthlyPaymentC(
                            toalamountfinceddata,
                            term,
                            conventionalRate
                        );
                        conventionalMonthlyPayments.push(conventionalMonthlyPayment);
                    });
                    //});
                    this.setState({
                        monthlypayment: pifMonthlyPayments,
                        monthlypaymentc: conventionalMonthlyPayments,
                    });
                }
            );
        });
    }

    handleTradeinvalue(e) {
        if (!this.state.purchasepricevalue) {
            Swal({
                text: "Please Fill Purchase Price Field",
                icon: "error",
                button: "OK",
            });
            return false;
        }
        const { value } = e.target;
        const numbersAndDecimal = value.replace(/[^0-9.]/g, ""); // Keep only digits and dot
        const sections = numbersAndDecimal.split("."); // Split at the dot, if present
        const integerPart = sections[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Add commas for thousand separators
        const decimalPart = sections[1] ? sections[1].substring(0, 2) : ""; // Allow up to 4 decimal places
        const formattedValue =
            sections.length === 2 ? `${integerPart}.${decimalPart}` : integerPart;
        this.setState({ tradeinvalue: formattedValue });
        this.setState({ value }, () => {
            const adjustedvehicleprice =
                parseFloat(this.state.purchasepricevalue.replace(/,/g, "")) -
                (parseFloat(this.state.tradeinvalue.replace(/,/g, "")) || 0) +
                (parseFloat(this.state.tradeinpayoff.replace(/,/g, "")) || 0);
            const totalamounttobefinanced =
                adjustedvehicleprice -
                (parseFloat(this.state.downpayment.replace(/,/g, "")) || 0) +
                (parseFloat(this.state.insurance.replace(/,/g, "")) || 0) +
                (parseFloat(this.state.otherfees.replace(/,/g, "")) || 0) +
                (parseFloat(this.state.salestax) || 0);

            // alert(totalamounttobefinanced);
            this.setState({ totalamountfinanced: totalamounttobefinanced });

            let estimatervcArray1 = [...this.state.estimatedresidualvaluemyname]; // Create a copy of the array to avoid mutating state directly
            console.log("target", estimatervcArray1);
            // var msrppercentage1=[8,7,5,4,2,2,1];
            var msrppercentage1 = this.state.msrppercentagedata;
            // Create a new array to store the modified values
            let modifiedArray = [];
            // Iterate through the array
            for (let i = 0; i < estimatervcArray1.length; i++) {
                // Check if the array element is greater than the target value
                const targetValue = totalamounttobefinanced;

                if (parseFloat(estimatervcArray1[i]) > targetValue) {
                    // Calculate the new value based on msrppercentage1
                    //    const newValue1 =    (this.state.msrpvalue * msrppercentage1[i]) / 100;
                    const numericValue = parseFloat(
                        this.state.msrpvalue.replace(/\$|,/g, "")
                    ).toFixed(2);
                    const newValue1 = (numericValue * msrppercentage1[i]) / 100;
                    // Push the new value to the modifiedArray
                    modifiedArray.push(newValue1);
                } else {
                    // If the value is not greater than the target value, push the original value
                    modifiedArray.push(estimatervcArray1[i]);
                }
            }

            // Set the state with the modified array
            console.log(
                "Previous estimatedresidualvalue:",
                this.state.estimatedresidualvaluemyname
            );
            console.log("Modified Array:", modifiedArray);

            modifiedArray = modifiedArray.map((value) =>
                parseFloat(value.toFixed(2))
            );

            // this.setState({
            //     estimatedresidualvalue: modifiedArray,
            // });
            localStorage.setItem("residual_value", modifiedArray[6]);
            // this.setState({
            //                                                     estimatedresidualvaluemyname:modifiedArray
            //                                                 });

            this.setState({
                prevestimatedresidualvalue12: modifiedArray[0],
            });

            this.setState({
                prevestimatedresidualvalue24: modifiedArray[1],
            });

            this.setState({
                prevestimatedresidualvalue36: modifiedArray[2],
            });
            this.setState({
                prevestimatedresidualvalue48: modifiedArray[3],
            });
            this.setState({
                prevestimatedresidualvalue60: modifiedArray[4],
            });
            this.setState({
                prevestimatedresidualvalue72: modifiedArray[5],
            });
            this.setState({
                prevestimatedresidualvalue84: modifiedArray[6],
            });

            this.setState(
                {
                    estimatedresidualvalue: modifiedArray,
                },
                () => {
                    const calculateMonthlyPaymentC = (
                        totalLoanAmount,
                        termMonths,
                        monthlyInterestRate
                    ) => {
                        return (
                            (totalLoanAmount * monthlyInterestRate) /
                            (1 - Math.pow(1 + monthlyInterestRate, -termMonths))
                        );
                    };
                    const calculateMonthlyPayment = (
                        totalLoanAmount,
                        termMonths,
                        pifRate,
                        endBalance
                    ) => {
                        var loanAmount = totalLoanAmount;
                        var endBalance = endBalance;
                        var monthlyInterestRate = pifRate;
                        console.log("dead line6---" + pifRate);
                        console.log(endBalance);
                        var termMonths = termMonths;
                        let monthlyPayment = 0;
                        let epsilon = 0.01; // Tolerance for convergence
                        let maxIterations = 1000; // Maximum number of iterations

                        for (let i = 0; i < maxIterations; i++) {
                            let remainingBalance = loanAmount;

                            // Calculate remaining balance using the current monthly payment
                            for (let j = 0; j < termMonths; j++) {
                                let interest = remainingBalance * monthlyInterestRate;
                                let principal = monthlyPayment - interest;
                                remainingBalance -= principal;
                            }

                            // Check if the remaining balance is close enough to the end balance
                            if (Math.abs(remainingBalance - endBalance) < epsilon) {
                                break; // Stop iteration if close enough
                            }

                            // Adjust monthly payment based on the difference between calculated remaining balance and end balance
                            monthlyPayment += (remainingBalance - endBalance) / termMonths;
                        }
                        console.log("deadline2" + monthlyPayment);
                        return monthlyPayment;
                    };

                    // Terms for calculation
                    const terms = [12, 24, 36, 48, 60, 72, 84];
                    const conventionalRate = this.state.mycustomcu / 100 / 12;
                    const pifRate2 = this.state.mycustomfu / 100 / 12;
                    const estimatervcArray = this.state.estimatedresidualvalue;
                    let pifMonthlyPayments = [];
                    let conventionalMonthlyPayments = [];

                    // estimatervcArray.forEach(estimatervcValue => {
                    terms.forEach((term, index) => {
                        // // Calculate monthly payment for PIF rate
                        //var toalamountfinceddata = this.state.totalamountfinanced- this.state.estimatedresidualvalue[index]

                        var toalamountfinceddata = totalamounttobefinanced;

                        const pifMonthlyPayment = calculateMonthlyPayment(
                            toalamountfinceddata,
                            term,
                            pifRate2,
                            this.state.estimatedresidualvalue[index]
                        );
                        pifMonthlyPayments.push(pifMonthlyPayment);
                        // Calculate monthly payment for conventional rate
                        const conventionalMonthlyPayment = calculateMonthlyPaymentC(
                            toalamountfinceddata,
                            term,
                            conventionalRate
                        );
                        conventionalMonthlyPayments.push(conventionalMonthlyPayment);
                    });
                    //});
                    this.setState({
                        monthlypayment: pifMonthlyPayments,
                        monthlypaymentc: conventionalMonthlyPayments,
                    });
                }
            );
        });
    }

    handleDownpayment(e) {
        if (!this.state.purchasepricevalue) {
            // alert("Please Fill Purchase Price Field");

            Swal({
                text: "Please Fill Purchase Price Field",
                icon: "error",
                button: "OK",
            });
            return false;
        }
        //const downpayment = isNaN(parseFloat(e.target.value))?'':parseFloat(e.target.value);;

        const { value } = e.target;
        // if (/^-?\d*\.?\d*$/.test(value)) {
        //            this.setState({ downpayment: value });
        //        }

        const numbersAndDecimal = value.replace(/[^0-9.]/g, ""); // Keep only digits and dot
        const sections = numbersAndDecimal.split("."); // Split at the dot, if present
        const integerPart = sections[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Add commas for thousand separators
        const decimalPart = sections[1] ? sections[1].substring(0, 2) : ""; // Allow up to 4 decimal places

        const formattedValue =
            sections.length === 2 ? `${integerPart}.${decimalPart}` : integerPart;
        this.setState({ downpayment: formattedValue });

        this.setState({ value }, () => {
            //    const adjustedvehicleprice = parseFloat(this.state.purchasepricevalue) - (parseFloat(this.state.tradeinvalue) || 0) + (parseFloat(this.state.tradeinpayoff) || 0);
            // const totalamounttobefinanced = adjustedvehicleprice - (parseFloat(this.state.downpayment) || 0) + (parseFloat(this.state.insurance) || 0) + (parseFloat(this.state.otherfees) || 0) + (parseFloat(this.state.salestax) || 0);

            const adjustedvehicleprice =
                parseFloat(this.state.purchasepricevalue.replace(/,/g, "")) -
                (parseFloat(this.state.tradeinvalue.replace(/,/g, "")) || 0) +
                (parseFloat(this.state.tradeinpayoff.replace(/,/g, "")) || 0);
            const totalamounttobefinanced =
                adjustedvehicleprice -
                (parseFloat(this.state.downpayment.replace(/,/g, "")) || 0) +
                (parseFloat(this.state.insurance.replace(/,/g, "")) || 0) +
                (parseFloat(this.state.otherfees.replace(/,/g, "")) || 0) +
                (parseFloat(this.state.salestax) || 0);

            // alert(totalamounttobefinanced);
            this.setState({ totalamountfinanced: totalamounttobefinanced });

            let estimatervcArray1 = [...this.state.estimatedresidualvaluemyname]; // Create a copy of the array to avoid mutating state directly
            console.log("target", estimatervcArray1);
            var msrppercentage1 = this.state.msrppercentagedata;
            // Create a new array to store the modified values
            let modifiedArray = [];
            // Iterate through the array
            for (let i = 0; i < estimatervcArray1.length; i++) {
                // Check if the array element is greater than the target value
                const targetValue = totalamounttobefinanced;

                if (parseFloat(estimatervcArray1[i]) > targetValue) {
                    // Calculate the new value based on msrppercentage1
                    //const newValue1 =    (this.state.msrpvalue * msrppercentage1[i]) / 100;
                    const numericValue = parseFloat(
                        this.state.msrpvalue.replace(/\$|,/g, "")
                    ).toFixed(2);
                    const newValue1 = (numericValue * msrppercentage1[i]) / 100;
                    // Push the new value to the modifiedArray
                    modifiedArray.push(newValue1);
                } else {
                    // If the value is not greater than the target value, push the original value
                    modifiedArray.push(estimatervcArray1[i]);
                }
            }

            // Set the state with the modified array
            console.log(
                "Previous estimatedresidualvalue:",
                this.state.estimatedresidualvaluemyname
            );
            console.log("Modified Array:", modifiedArray);

            modifiedArray = modifiedArray.map((value) =>
                parseFloat(value.toFixed(2))
            );

            // this.setState({
            //     estimatedresidualvalue: modifiedArray,
            // });
            localStorage.setItem("residual_value", modifiedArray[6]);
            // this.setState({
            //                                                     estimatedresidualvaluemyname:modifiedArray
            //                                                 });

            this.setState({
                prevestimatedresidualvalue12: modifiedArray[0],
            });

            this.setState({
                prevestimatedresidualvalue24: modifiedArray[1],
            });

            this.setState({
                prevestimatedresidualvalue36: modifiedArray[2],
            });
            this.setState({
                prevestimatedresidualvalue48: modifiedArray[3],
            });
            this.setState({
                prevestimatedresidualvalue60: modifiedArray[4],
            });
            this.setState({
                prevestimatedresidualvalue72: modifiedArray[5],
            });
            this.setState({
                prevestimatedresidualvalue84: modifiedArray[6],
            });

            this.setState(
                {
                    estimatedresidualvalue: modifiedArray,
                },
                () => {
                    const calculateMonthlyPaymentC = (
                        totalLoanAmount,
                        termMonths,
                        monthlyInterestRate
                    ) => {
                        return (
                            (totalLoanAmount * monthlyInterestRate) /
                            (1 - Math.pow(1 + monthlyInterestRate, -termMonths))
                        );
                    };
                    const calculateMonthlyPayment = (
                        totalLoanAmount,
                        termMonths,
                        pifRate,
                        endBalance
                    ) => {
                        var loanAmount = totalLoanAmount;
                        var endBalance = endBalance;
                        var monthlyInterestRate = pifRate;
                        console.log("dead line7---" + pifRate);
                        console.log(endBalance);
                        var termMonths = termMonths;
                        let monthlyPayment = 0;
                        let epsilon = 0.01; // Tolerance for convergence
                        let maxIterations = 1000; // Maximum number of iterations

                        for (let i = 0; i < maxIterations; i++) {
                            let remainingBalance = loanAmount;

                            // Calculate remaining balance using the current monthly payment
                            for (let j = 0; j < termMonths; j++) {
                                let interest = remainingBalance * monthlyInterestRate;
                                let principal = monthlyPayment - interest;
                                remainingBalance -= principal;
                            }

                            // Check if the remaining balance is close enough to the end balance
                            if (Math.abs(remainingBalance - endBalance) < epsilon) {
                                break; // Stop iteration if close enough
                            }

                            // Adjust monthly payment based on the difference between calculated remaining balance and end balance
                            monthlyPayment += (remainingBalance - endBalance) / termMonths;
                        }

                        return monthlyPayment;
                    };

                    // Terms for calculation
                    const terms = [12, 24, 36, 48, 60, 72, 84];
                    const conventionalRate = this.state.mycustomcu / 100 / 12;
                    const pifRate2 = this.state.mycustomfu / 100 / 12;
                    const estimatervcArray = this.state.estimatedresidualvalue;
                    let pifMonthlyPayments = [];
                    let conventionalMonthlyPayments = [];

                    // estimatervcArray.forEach(estimatervcValue => {
                    terms.forEach((term, index) => {
                        // // Calculate monthly payment for PIF rate
                        // var toalamountfinceddata = this.state.totalamountfinanced- this.state.estimatedresidualvalue[index]
                        var toalamountfinceddata = totalamounttobefinanced;

                        const pifMonthlyPayment = calculateMonthlyPayment(
                            toalamountfinceddata,
                            term,
                            pifRate2,
                            this.state.estimatedresidualvalue[index]
                        );
                        pifMonthlyPayments.push(pifMonthlyPayment);
                        // Calculate monthly payment for conventional rate
                        const conventionalMonthlyPayment = calculateMonthlyPaymentC(
                            toalamountfinceddata,
                            term,
                            conventionalRate
                        );
                        conventionalMonthlyPayments.push(conventionalMonthlyPayment);
                    });
                    //});
                    console.log("natiedown");
                    console.log(pifMonthlyPayments);
                    console.log(totalamounttobefinanced);

                    this.setState({
                        monthlypayment: pifMonthlyPayments,
                        monthlypaymentc: conventionalMonthlyPayments,
                    });
                }
            );
        });
    }

    handleInsurance(e) {
        //const insurance = isNaN(parseFloat(e.target.value))?'':parseFloat(e.target.value);;
        if (!this.state.purchasepricevalue) {
            //alert("Please Fill Purchase Price Field");

            Swal({
                text: "Please Fill Purchase Price Field",
                icon: "error",
                button: "OK",
            });
            return false;
        }

        const { value } = e.target;
        // if (/^-?\d*\.?\d*$/.test(value)) {
        //            this.setState({ insurance: value });
        //        }

        const numbersAndDecimal = value.replace(/[^0-9.]/g, ""); // Keep only digits and dot
        const sections = numbersAndDecimal.split("."); // Split at the dot, if present
        const integerPart = sections[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Add commas for thousand separators
        const decimalPart = sections[1] ? sections[1].substring(0, 2) : ""; // Allow up to 4 decimal places

        const formattedValue =
            sections.length === 2 ? `${integerPart}.${decimalPart}` : integerPart;
        this.setState({ insurance: formattedValue });

        this.setState({ value }, () => {
            //    const adjustedvehicleprice = parseFloat(this.state.purchasepricevalue) - (parseFloat(this.state.tradeinvalue) || 0) + (parseFloat(this.state.tradeinpayoff) || 0);
            // const totalamounttobefinanced = adjustedvehicleprice - (parseFloat(this.state.downpayment) || 0) + (parseFloat(this.state.insurance) || 0) + (parseFloat(this.state.otherfees) || 0) + (parseFloat(this.state.salestax) || 0);

            const adjustedvehicleprice =
                parseFloat(this.state.purchasepricevalue.replace(/,/g, "")) -
                (parseFloat(this.state.tradeinvalue.replace(/,/g, "")) || 0) +
                (parseFloat(this.state.tradeinpayoff.replace(/,/g, "")) || 0);
            const totalamounttobefinanced =
                adjustedvehicleprice -
                (parseFloat(this.state.downpayment.replace(/,/g, "")) || 0) +
                (parseFloat(this.state.insurance.replace(/,/g, "")) || 0) +
                (parseFloat(this.state.otherfees.replace(/,/g, "")) || 0) +
                (parseFloat(this.state.salestax) || 0);

            // alert(totalamounttobefinanced);
            this.setState({ totalamountfinanced: totalamounttobefinanced });

            let estimatervcArray1 = [...this.state.estimatedresidualvaluemyname]; // Create a copy of the array to avoid mutating state directly
            console.log("target", estimatervcArray1);
            // var msrppercentage1=[8,7,5,4,2,2,1];
            var msrppercentage1 = this.state.msrppercentagedata;
            // Create a new array to store the modified values
            let modifiedArray = [];
            // Iterate through the array
            for (let i = 0; i < estimatervcArray1.length; i++) {
                // Check if the array element is greater than the target value
                const targetValue = totalamounttobefinanced;

                if (parseFloat(estimatervcArray1[i]) > targetValue) {
                    // Calculate the new value based on msrppercentage1
                    // const newValue1 =    (this.state.msrpvalue * msrppercentage1[i]) / 100;
                    const numericValue = parseFloat(
                        this.state.msrpvalue.replace(/\$|,/g, "")
                    ).toFixed(2);
                    const newValue1 = (numericValue * msrppercentage1[i]) / 100;
                    // Push the new value to the modifiedArray
                    modifiedArray.push(newValue1);
                } else {
                    // If the value is not greater than the target value, push the original value
                    modifiedArray.push(estimatervcArray1[i]);
                }
            }

            // Set the state with the modified array
            console.log(
                "Previous estimatedresidualvalue:",
                this.state.estimatedresidualvaluemyname
            );
            console.log("Modified Array:", modifiedArray);

            modifiedArray = modifiedArray.map((value) =>
                parseFloat(value.toFixed(2))
            );

            // this.setState({
            //     estimatedresidualvalue: modifiedArray,
            // });
            localStorage.setItem("residual_value", modifiedArray[6]);
            // this.setState({
            //                                                     estimatedresidualvaluemyname:modifiedArray
            //                                                 });

            this.setState({
                prevestimatedresidualvalue12: modifiedArray[0],
            });

            this.setState({
                prevestimatedresidualvalue24: modifiedArray[1],
            });

            this.setState({
                prevestimatedresidualvalue36: modifiedArray[2],
            });
            this.setState({
                prevestimatedresidualvalue48: modifiedArray[3],
            });
            this.setState({
                prevestimatedresidualvalue60: modifiedArray[4],
            });
            this.setState({
                prevestimatedresidualvalue72: modifiedArray[5],
            });
            this.setState({
                prevestimatedresidualvalue84: modifiedArray[6],
            });

            this.setState(
                {
                    estimatedresidualvalue: modifiedArray,
                },
                () => {
                    const calculateMonthlyPaymentC = (
                        totalLoanAmount,
                        termMonths,
                        monthlyInterestRate
                    ) => {
                        return (
                            (totalLoanAmount * monthlyInterestRate) /
                            (1 - Math.pow(1 + monthlyInterestRate, -termMonths))
                        );
                    };
                    const calculateMonthlyPayment = (
                        totalLoanAmount,
                        termMonths,
                        pifRate,
                        endBalance
                    ) => {
                        var loanAmount = totalLoanAmount;
                        var endBalance = endBalance;
                        var monthlyInterestRate = pifRate;
                        console.log("dead line8---" + pifRate);
                        console.log(endBalance);
                        var termMonths = termMonths;
                        let monthlyPayment = 0;
                        let epsilon = 0.01; // Tolerance for convergence
                        let maxIterations = 1000; // Maximum number of iterations

                        for (let i = 0; i < maxIterations; i++) {
                            let remainingBalance = loanAmount;

                            // Calculate remaining balance using the current monthly payment
                            for (let j = 0; j < termMonths; j++) {
                                let interest = remainingBalance * monthlyInterestRate;
                                let principal = monthlyPayment - interest;
                                remainingBalance -= principal;
                            }

                            // Check if the remaining balance is close enough to the end balance
                            if (Math.abs(remainingBalance - endBalance) < epsilon) {
                                break; // Stop iteration if close enough
                            }

                            // Adjust monthly payment based on the difference between calculated remaining balance and end balance
                            monthlyPayment += (remainingBalance - endBalance) / termMonths;
                        }
                        console.log("deadline2" + monthlyPayment);
                        return monthlyPayment;
                    };

                    // Terms for calculation
                    const terms = [12, 24, 36, 48, 60, 72, 84];
                    const conventionalRate = this.state.mycustomcu / 100 / 12;
                    const pifRate2 = this.state.mycustomfu / 100 / 12;
                    const estimatervcArray = this.state.estimatedresidualvalue;
                    let pifMonthlyPayments = [];
                    let conventionalMonthlyPayments = [];

                    // estimatervcArray.forEach(estimatervcValue => {
                    terms.forEach((term, index) => {
                        // // Calculate monthly payment for PIF rate
                        //var toalamountfinceddata = this.state.totalamountfinanced- this.state.estimatedresidualvalue[index]

                        var toalamountfinceddata = totalamounttobefinanced;

                        const pifMonthlyPayment = calculateMonthlyPayment(
                            toalamountfinceddata,
                            term,
                            pifRate2,
                            this.state.estimatedresidualvalue[index]
                        );
                        pifMonthlyPayments.push(pifMonthlyPayment);
                        // Calculate monthly payment for conventional rate
                        const conventionalMonthlyPayment = calculateMonthlyPaymentC(
                            toalamountfinceddata,
                            term,
                            conventionalRate
                        );
                        conventionalMonthlyPayments.push(conventionalMonthlyPayment);
                    });
                    //});
                    this.setState({
                        monthlypayment: pifMonthlyPayments,
                        monthlypaymentc: conventionalMonthlyPayments,
                    });
                }
            );
        });
    }

    handleAllotherfees(e) {
        // const otherfees = isNaN(parseFloat(e.target.value))?'':parseFloat(e.target.value);;

        if (!this.state.purchasepricevalue) {
            //alert("Please Fill Purchase Price Field");

            Swal({
                text: "Please Fill Purchase Price Field",
                icon: "error",
                button: "OK",
            });
            return false;
        }

        const { value } = e.target;

        const numbersAndDecimal = value.replace(/[^0-9.]/g, ""); // Keep only digits and dot
        const sections = numbersAndDecimal.split("."); // Split at the dot, if present
        const integerPart = sections[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Add commas for thousand separators
        const decimalPart = sections[1] ? sections[1].substring(0, 2) : ""; // Allow up to 4 decimal places

        const formattedValue =
            sections.length === 2 ? `${integerPart}.${decimalPart}` : integerPart;
        this.setState({ otherfees: formattedValue });

        this.setState({ value }, () => {
            // const adjustedvehicleprice = parseFloat(this.state.purchasepricevalue) - (parseFloat(this.state.tradeinvalue) || 0) + parseFloat(this.state.tradeinpayoff || 0);
            //    const totalamounttobefinanced = adjustedvehicleprice - (parseFloat(this.state.downpayment) || 0) + (parseFloat(this.state.insurance) || 0) + (parseFloat(this.state.otherfees) || 0) + (parseFloat(this.state.salestax) || 0);

            const adjustedvehicleprice =
                parseFloat(this.state.purchasepricevalue.replace(/,/g, "")) -
                (parseFloat(this.state.tradeinvalue.replace(/,/g, "")) || 0) +
                (parseFloat(this.state.tradeinpayoff.replace(/,/g, "")) || 0);
            const totalamounttobefinanced =
                adjustedvehicleprice -
                (parseFloat(this.state.downpayment.replace(/,/g, "")) || 0) +
                (parseFloat(this.state.insurance.replace(/,/g, "")) || 0) +
                (parseFloat(this.state.otherfees.replace(/,/g, "")) || 0) +
                (parseFloat(this.state.salestax) || 0);

            // alert(totalamounttobefinanced);
            this.setState({ totalamountfinanced: totalamounttobefinanced });

            let estimatervcArray1 = [...this.state.estimatedresidualvaluemyname]; // Create a copy of the array to avoid mutating state directly
            console.log("target", estimatervcArray1);
            // var msrppercentage1=[8,7,5,4,2,2,1];
            var msrppercentage1 = this.state.msrppercentagedata;
            // Create a new array to store the modified values
            let modifiedArray = [];
            // Iterate through the array
            for (let i = 0; i < estimatervcArray1.length; i++) {
                // Check if the array element is greater than the target value
                const targetValue = totalamounttobefinanced;

                if (parseFloat(estimatervcArray1[i]) > targetValue) {
                    // Calculate the new value based on msrppercentage1
                    // const newValue1 =    (this.state.msrpvalue * msrppercentage1[i]) / 100;
                    const numericValue = parseFloat(
                        this.state.msrpvalue.replace(/\$|,/g, "")
                    ).toFixed(2);
                    const newValue1 = (numericValue * msrppercentage1[i]) / 100;
                    // Push the new value to the modifiedArray
                    modifiedArray.push(newValue1);
                } else {
                    // If the value is not greater than the target value, push the original value
                    modifiedArray.push(estimatervcArray1[i]);
                }
            }

            // Set the state with the modified array
            console.log(
                "Previous estimatedresidualvalue:",
                this.state.estimatedresidualvaluemyname
            );
            console.log("Modified Array:", modifiedArray);
            modifiedArray = modifiedArray.map((value) =>
                parseFloat(value.toFixed(2))
            );

            // this.setState({
            //     estimatedresidualvalue: modifiedArray,
            // });
            localStorage.setItem("residual_value", modifiedArray[6]);
            // this.setState({
            //                                                     estimatedresidualvaluemyname:modifiedArray
            //                                                 });

            this.setState({
                prevestimatedresidualvalue12: modifiedArray[0],
            });

            this.setState({
                prevestimatedresidualvalue24: modifiedArray[1],
            });

            this.setState({
                prevestimatedresidualvalue36: modifiedArray[2],
            });
            this.setState({
                prevestimatedresidualvalue48: modifiedArray[3],
            });
            this.setState({
                prevestimatedresidualvalue60: modifiedArray[4],
            });
            this.setState({
                prevestimatedresidualvalue72: modifiedArray[5],
            });
            this.setState({
                prevestimatedresidualvalue84: modifiedArray[6],
            });

            this.setState(
                {
                    estimatedresidualvalue: modifiedArray,
                },
                () => {
                    const calculateMonthlyPaymentC = (
                        totalLoanAmount,
                        termMonths,
                        monthlyInterestRate
                    ) => {
                        return (
                            (totalLoanAmount * monthlyInterestRate) /
                            (1 - Math.pow(1 + monthlyInterestRate, -termMonths))
                        );
                    };
                    const calculateMonthlyPayment = (
                        totalLoanAmount,
                        termMonths,
                        pifRate,
                        endBalance
                    ) => {
                        var loanAmount = totalLoanAmount;
                        var endBalance = endBalance;
                        var monthlyInterestRate = pifRate;

                        console.log("dead line9---" + pifRate);
                        console.log(endBalance);
                        var termMonths = termMonths;
                        let monthlyPayment = 0;
                        let epsilon = 0.01; // Tolerance for convergence
                        let maxIterations = 1000; // Maximum number of iterations

                        for (let i = 0; i < maxIterations; i++) {
                            let remainingBalance = loanAmount;

                            // Calculate remaining balance using the current monthly payment
                            for (let j = 0; j < termMonths; j++) {
                                let interest = remainingBalance * monthlyInterestRate;
                                let principal = monthlyPayment - interest;
                                remainingBalance -= principal;
                            }

                            // Check if the remaining balance is close enough to the end balance
                            if (Math.abs(remainingBalance - endBalance) < epsilon) {
                                break; // Stop iteration if close enough
                            }

                            // Adjust monthly payment based on the difference between calculated remaining balance and end balance
                            monthlyPayment += (remainingBalance - endBalance) / termMonths;
                        }
                        console.log("deadline2" + monthlyPayment);
                        return monthlyPayment;
                    };

                    // Terms for calculation
                    const terms = [12, 24, 36, 48, 60, 72, 84];
                    const conventionalRate = this.state.mycustomcu / 100 / 12;
                    const pifRate2 = this.state.mycustomfu / 100 / 12;
                    const estimatervcArray = this.state.estimatedresidualvalue;
                    let pifMonthlyPayments = [];
                    let conventionalMonthlyPayments = [];

                    // estimatervcArray.forEach(estimatervcValue => {
                    terms.forEach((term, index) => {
                        // // Calculate monthly payment for PIF rate
                        //var toalamountfinceddata = this.state.totalamountfinanced- this.state.estimatedresidualvalue[index]

                        var toalamountfinceddata = totalamounttobefinanced;

                        const pifMonthlyPayment = calculateMonthlyPayment(
                            toalamountfinceddata,
                            term,
                            pifRate2,
                            this.state.estimatedresidualvalue[index]
                        );
                        pifMonthlyPayments.push(pifMonthlyPayment);
                        // Calculate monthly payment for conventional rate
                        const conventionalMonthlyPayment = calculateMonthlyPaymentC(
                            this.state.totalamountfinanced,
                            term,
                            conventionalRate
                        );
                        conventionalMonthlyPayments.push(conventionalMonthlyPayment);
                    });
                    //});
                    this.setState({
                        monthlypayment: pifMonthlyPayments,
                        monthlypaymentc: conventionalMonthlyPayments,
                    });
                }
            );
        });
    }



handlerv =(e) =>
{

  let value = e.target.value.replace(/,/g, '').replace(/[^\d.-]/g, '');
    // Split the value into whole part and decimal part
    let parts = value.split('.');

    // Add commas to the whole part
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // Join the whole and decimal parts together
    e.target.value = parts.join('.');

    // Limit the decimal part to two places
    if (parts[1] && parts[1].length > 2) {
      e.target.value = parts[0] + '.' + parts[1].slice(0, 2);
    }


  this.setState({
                        estimateresidualvaluepopup: e.target.value
                       
                    });

}

    updatevalue = (month) => 
    {
// alert(month);



      var newValue= this.state.estimateresidualvaluepopup;

                  var newValue1 =parseFloat(newValue.replace(",", ""))
console.log(typeof  newValue1);
console.log(newValue1);

let previousValue = this.state.prevestimatedresidualvalue84;

if(month==12){

         previousValue = this.state.prevestimatedresidualvalue12;

}

if(month==24){

         previousValue = this.state.prevestimatedresidualvalue24;

}

if(month==36){

         previousValue = this.state.prevestimatedresidualvalue36;

}

if(month==48){

         previousValue = this.state.prevestimatedresidualvalue48;

}

if(month==60){

         previousValue = this.state.prevestimatedresidualvalue60;

}

if(month==72){

         previousValue = this.state.prevestimatedresidualvalue72;

}

if(month==84){

         previousValue = this.state.prevestimatedresidualvalue84;


}


// alert("hello");
// alert(typeof previousValue);
// alert(previousValue);
// alert(typeof newValue1);
// alert(newValue1);
        if (newValue1 > previousValue) {
            // alert("You can't select a value greater than the previous value.");

            Swal({
                text: "You can't select a value greater than the previous value.",
                icon: "error",
                button: "OK",
            });

            return;
        }

console.log(this.state.estimatedresidualvaluemyname);
        const updatedArray = [...this.state.estimatedresidualvaluemyname];

        console.log(updatedArray);
        console.log("rajiv");

        // Update the first index with the value from estimatervcArray


if(month==12)
{
        updatedArray[0] =    newValue1;
        for (let i = 1; i < updatedArray.length; i++) {
            updatedArray[i] = this.state.estimatedresidualvaluemyname[i];
        }
        this.setState({
            estimatedresidualvalue: updatedArray,
        });

        localStorage.setItem("residual_value", updatedArray[0]);
        

        this.setState({
            estimatedresidualvaluemyname: updatedArray,
        });


       
        const adjustedvehicleprice =
            parseFloat(this.state.purchasepricevalue.replace(/,/g, "")) -
            (parseFloat(this.state.tradeinvalue.replace(/,/g, "")) || 0) +
            (parseFloat(this.state.tradeinpayoff.replace(/,/g, "")) || 0);
        const totalamounttobefinanced =
            adjustedvehicleprice -
            (parseFloat(this.state.downpayment.replace(/,/g, "")) || 0) +
            (parseFloat(this.state.insurance.replace(/,/g, "")) || 0) +
            (parseFloat(this.state.otherfees.replace(/,/g, "")) || 0) +
            (parseFloat(this.state.salestax) || 0);

        this.setState({ totalamountfinanced: totalamounttobefinanced });

        const calculateMonthlyPayment = (
            totalLoanAmount,
            termMonths,
            pifRate,
            endBalance
        ) => {
            var loanAmount = totalLoanAmount;
            var endBalance = endBalance;
            var monthlyInterestRate = pifRate;
            var termMonths = termMonths;
            let monthlyPayment = 0;
            let epsilon = 0.01; // Tolerance for convergence
            let maxIterations = 1000; // Maximum number of iterations

            for (let i = 0; i < maxIterations; i++) {
                let remainingBalance = loanAmount;

                // Calculate remaining balance using the current monthly payment
                for (let j = 0; j < termMonths; j++) {
                    let interest = remainingBalance * monthlyInterestRate;
                    let principal = monthlyPayment - interest;
                    remainingBalance -= principal;
                }

                // Check if the remaining balance is close enough to the end balance
                if (Math.abs(remainingBalance - endBalance) < epsilon) {
                    break; // Stop iteration if close enough
                }

                // Adjust monthly payment based on the difference between calculated remaining balance and end balance
                monthlyPayment += (remainingBalance - endBalance) / termMonths;
            }

            return monthlyPayment;
        };

        // Terms for calculation
        const terms = [12, 24, 36, 48, 60, 72, 84];
        const conventionalRate = this.state.conventionalrate / 100 / 12;
        const pifRate2 = this.state.pifrate / 100 / 12;
        const estimatervcArray = updatedArray;
        let pifMonthlyPayments = [];
        let conventionalMonthlyPayments = [];

        // estimatervcArray.forEach(estimatervcValue => {
        terms.forEach((term, index) => {
            // // Calculate monthly payment for PIF rate
            console.log(this.state.totalamountfinanced);
            console.log(estimatervcArray[index]);
            console.log("raju");
            // var toalamountfinceddata = this.state.totalamountfinanced
            var toalamountfinceddata = totalamounttobefinanced;

            const pifMonthlyPayment = calculateMonthlyPayment(
                toalamountfinceddata,
                term,
                pifRate2,
                estimatervcArray[index]
            );
            pifMonthlyPayments.push(pifMonthlyPayment);
        });
        //});
        this.setState({
            monthlypayment: pifMonthlyPayments,
        });

this.setState({ estimateresidualvaluepopup: '' });
this.setState({ showcustomerPopup2: false });
}


if(month==24)
{
     //   updatedArray[1] =    newValue1;

    const updatedArray1 = [...this.state.estimatedresidualvaluemyname];
        console.log("razai");
        console.log(updatedArray1);

        updatedArray1[1] = newValue1;

        console.log("test kaka");

        console.log(updatedArray1);
        this.setState({
            estimatedresidualvalue: updatedArray1,
        });
        localStorage.setItem("residual_value", updatedArray1[1]);
        this.setState({
            estimatedresidualvaluemyname: updatedArray1,
        });

        // const adjustedvehicleprice = parseFloat(this.state.purchasepricevalue) - (parseFloat(this.state.tradeinvalue) || 0) + (parseFloat(this.state.tradeinpayoff) || 0);
        //                const totalamounttobefinanced = adjustedvehicleprice - (parseFloat(this.state.downpayment) || 0) + (parseFloat(this.state.insurance) || 0) + (parseFloat(this.state.otherfees) || 0) + (parseFloat(this.state.salestax) || 0);

        const adjustedvehicleprice =
            parseFloat(this.state.purchasepricevalue.replace(/,/g, "")) -
            (parseFloat(this.state.tradeinvalue.replace(/,/g, "")) || 0) +
            (parseFloat(this.state.tradeinpayoff.replace(/,/g, "")) || 0);
        const totalamounttobefinanced =
            adjustedvehicleprice -
            (parseFloat(this.state.downpayment.replace(/,/g, "")) || 0) +
            (parseFloat(this.state.insurance.replace(/,/g, "")) || 0) +
            (parseFloat(this.state.otherfees.replace(/,/g, "")) || 0) +
            (parseFloat(this.state.salestax) || 0);

        // alert(totalamounttobefinanced);
        this.setState({ totalamountfinanced: totalamounttobefinanced });

        const calculateMonthlyPayment = (
            totalLoanAmount,
            termMonths,
            pifRate,
            endBalance
        ) => {
            var loanAmount = totalLoanAmount;
            var endBalance = endBalance;
            var monthlyInterestRate = pifRate;
            var termMonths = termMonths;
            let monthlyPayment = 0;
            let epsilon = 0.01; // Tolerance for convergence
            let maxIterations = 1000; // Maximum number of iterations

            for (let i = 0; i < maxIterations; i++) {
                let remainingBalance = loanAmount;

                // Calculate remaining balance using the current monthly payment
                for (let j = 0; j < termMonths; j++) {
                    let interest = remainingBalance * monthlyInterestRate;
                    let principal = monthlyPayment - interest;
                    remainingBalance -= principal;
                }

                // Check if the remaining balance is close enough to the end balance
                if (Math.abs(remainingBalance - endBalance) < epsilon) {
                    break; // Stop iteration if close enough
                }

                // Adjust monthly payment based on the difference between calculated remaining balance and end balance
                monthlyPayment += (remainingBalance - endBalance) / termMonths;
            }

            return monthlyPayment;
        };

        // Terms for calculation
        const terms = [12, 24, 36, 48, 60, 72, 84];
        const conventionalRate = this.state.conventionalrate / 100 / 12;
        const pifRate2 = this.state.pifrate / 100 / 12;
        const estimatervcArray = updatedArray1;
        let pifMonthlyPayments = [];
        let conventionalMonthlyPayments = [];

        // estimatervcArray.forEach(estimatervcValue => {
        terms.forEach((term, index) => {
            // // Calculate monthly payment for PIF rate
            console.log(this.state.totalamountfinanced);
            console.log(estimatervcArray[index]);
            console.log("raju");
            //var toalamountfinceddata = this.state.totalamountfinanced - estimatervcArray[index]
            var toalamountfinceddata = totalamounttobefinanced;

            const pifMonthlyPayment = calculateMonthlyPayment(
                toalamountfinceddata,
                term,
                pifRate2,
                estimatervcArray[index]
            );
            pifMonthlyPayments.push(pifMonthlyPayment);
        });
        //});
        this.setState({
            monthlypayment: pifMonthlyPayments,
        });
  
this.setState({ estimateresidualvaluepopup: '' });
this.setState({ showcustomerPopup2: false });
}

if(month==36)
{

        const updatedArray1 = [...this.state.estimatedresidualvaluemyname];
        console.log("razai");
        console.log(updatedArray1);

        updatedArray1[2] = newValue1;

        console.log("test kaka");

        console.log(updatedArray1);
        this.setState({
            estimatedresidualvalue: updatedArray1,
        });
        localStorage.setItem("residual_value", updatedArray1[2]);
        this.setState({
            estimatedresidualvaluemyname: updatedArray1,
        });

        // const adjustedvehicleprice = parseFloat(this.state.purchasepricevalue) - (parseFloat(this.state.tradeinvalue) || 0) + (parseFloat(this.state.tradeinpayoff) || 0);
        //                const totalamounttobefinanced = adjustedvehicleprice - (parseFloat(this.state.downpayment) || 0) + (parseFloat(this.state.insurance) || 0) + (parseFloat(this.state.otherfees) || 0) + (parseFloat(this.state.salestax) || 0);

        const adjustedvehicleprice =
            parseFloat(this.state.purchasepricevalue.replace(/,/g, "")) -
            (parseFloat(this.state.tradeinvalue.replace(/,/g, "")) || 0) +
            (parseFloat(this.state.tradeinpayoff.replace(/,/g, "")) || 0);
        const totalamounttobefinanced =
            adjustedvehicleprice -
            (parseFloat(this.state.downpayment.replace(/,/g, "")) || 0) +
            (parseFloat(this.state.insurance.replace(/,/g, "")) || 0) +
            (parseFloat(this.state.otherfees.replace(/,/g, "")) || 0) +
            (parseFloat(this.state.salestax) || 0);

        // alert(totalamounttobefinanced);
        this.setState({ totalamountfinanced: totalamounttobefinanced });

        const calculateMonthlyPayment = (
            totalLoanAmount,
            termMonths,
            pifRate,
            endBalance
        ) => {
            var loanAmount = totalLoanAmount;
            var endBalance = endBalance;
            var monthlyInterestRate = pifRate;
            var termMonths = termMonths;
            let monthlyPayment = 0;
            let epsilon = 0.01; // Tolerance for convergence
            let maxIterations = 1000; // Maximum number of iterations

            for (let i = 0; i < maxIterations; i++) {
                let remainingBalance = loanAmount;

                // Calculate remaining balance using the current monthly payment
                for (let j = 0; j < termMonths; j++) {
                    let interest = remainingBalance * monthlyInterestRate;
                    let principal = monthlyPayment - interest;
                    remainingBalance -= principal;
                }

                // Check if the remaining balance is close enough to the end balance
                if (Math.abs(remainingBalance - endBalance) < epsilon) {
                    break; // Stop iteration if close enough
                }

                // Adjust monthly payment based on the difference between calculated remaining balance and end balance
                monthlyPayment += (remainingBalance - endBalance) / termMonths;
            }

            return monthlyPayment;
        };

        // Terms for calculation
        const terms = [12, 24, 36, 48, 60, 72, 84];
        const conventionalRate = this.state.conventionalrate / 100 / 12;
        const pifRate2 = this.state.pifrate / 100 / 12;
        const estimatervcArray = updatedArray1;
        let pifMonthlyPayments = [];
        let conventionalMonthlyPayments = [];

        // estimatervcArray.forEach(estimatervcValue => {
        terms.forEach((term, index) => {
            // // Calculate monthly payment for PIF rate
            console.log(this.state.totalamountfinanced);
            console.log(estimatervcArray[index]);
            console.log("raju");
            // var toalamountfinceddata = this.state.totalamountfinanced - estimatervcArray[index]
            var toalamountfinceddata = totalamounttobefinanced;

            const pifMonthlyPayment = calculateMonthlyPayment(
                toalamountfinceddata,
                term,
                pifRate2,
                estimatervcArray[index]
            );
            pifMonthlyPayments.push(pifMonthlyPayment);
        });
        //});
        this.setState({
            monthlypayment: pifMonthlyPayments,
        });
this.setState({ estimateresidualvaluepopup: '' });
this.setState({ showcustomerPopup2: false });
}

if(month==48)
{
        


        const updatedArray1 = [...this.state.estimatedresidualvaluemyname];
        console.log("razai");
        console.log(updatedArray1);

        updatedArray1[3] = newValue1;

        console.log("test kaka");

        console.log(updatedArray1);
        this.setState({
            estimatedresidualvalue: updatedArray1,
        });
        localStorage.setItem("residual_value", updatedArray1[3]);
        this.setState({
            estimatedresidualvaluemyname: updatedArray1,
        });

        // const adjustedvehicleprice = parseFloat(this.state.purchasepricevalue) - (parseFloat(this.state.tradeinvalue) || 0) + (parseFloat(this.state.tradeinpayoff) || 0);
        //                const totalamounttobefinanced = adjustedvehicleprice - (parseFloat(this.state.downpayment) || 0) + (parseFloat(this.state.insurance) || 0) + (parseFloat(this.state.otherfees) || 0) + (parseFloat(this.state.salestax) || 0);

        const adjustedvehicleprice =
            parseFloat(this.state.purchasepricevalue.replace(/,/g, "")) -
            (parseFloat(this.state.tradeinvalue.replace(/,/g, "")) || 0) +
            (parseFloat(this.state.tradeinpayoff.replace(/,/g, "")) || 0);
        const totalamounttobefinanced =
            adjustedvehicleprice -
            (parseFloat(this.state.downpayment.replace(/,/g, "")) || 0) +
            (parseFloat(this.state.insurance.replace(/,/g, "")) || 0) +
            (parseFloat(this.state.otherfees.replace(/,/g, "")) || 0) +
            (parseFloat(this.state.salestax) || 0);

        // alert(totalamounttobefinanced);
        this.setState({ totalamountfinanced: totalamounttobefinanced });

        const calculateMonthlyPayment = (
            totalLoanAmount,
            termMonths,
            pifRate,
            endBalance
        ) => {
            var loanAmount = totalLoanAmount;
            var endBalance = endBalance;
            var monthlyInterestRate = pifRate;
            var termMonths = termMonths;
            let monthlyPayment = 0;
            let epsilon = 0.01; // Tolerance for convergence
            let maxIterations = 1000; // Maximum number of iterations

            for (let i = 0; i < maxIterations; i++) {
                let remainingBalance = loanAmount;

                // Calculate remaining balance using the current monthly payment
                for (let j = 0; j < termMonths; j++) {
                    let interest = remainingBalance * monthlyInterestRate;
                    let principal = monthlyPayment - interest;
                    remainingBalance -= principal;
                }

                // Check if the remaining balance is close enough to the end balance
                if (Math.abs(remainingBalance - endBalance) < epsilon) {
                    break; // Stop iteration if close enough
                }

                // Adjust monthly payment based on the difference between calculated remaining balance and end balance
                monthlyPayment += (remainingBalance - endBalance) / termMonths;
            }

            return monthlyPayment;
        };

        // Terms for calculation
        const terms = [12, 24, 36, 48, 60, 72, 84];
        const conventionalRate = this.state.conventionalrate / 100 / 12;
        const pifRate2 = this.state.pifrate / 100 / 12;
        const estimatervcArray = updatedArray1;
        let pifMonthlyPayments = [];
        let conventionalMonthlyPayments = [];

        // estimatervcArray.forEach(estimatervcValue => {
        terms.forEach((term, index) => {
            // // Calculate monthly payment for PIF rate
            console.log(this.state.totalamountfinanced);
            console.log(estimatervcArray[index]);
            console.log("raju");
            // var toalamountfinceddata = this.state.totalamountfinanced - estimatervcArray[index]
            var toalamountfinceddata = totalamounttobefinanced;

            const pifMonthlyPayment = calculateMonthlyPayment(
                toalamountfinceddata,
                term,
                pifRate2,
                estimatervcArray[index]
            );
            pifMonthlyPayments.push(pifMonthlyPayment);
        });
        //});
        this.setState({
            monthlypayment: pifMonthlyPayments,
        });

this.setState({ estimateresidualvaluepopup: '' });
this.setState({ showcustomerPopup2: false });
}

if(month==60)
{


        const updatedArray1 = [...this.state.estimatedresidualvaluemyname];
        console.log("razai");
        console.log(updatedArray1);

        updatedArray1[4] = newValue1;

        console.log("test kaka");

        console.log(updatedArray1);
        this.setState({
            estimatedresidualvalue: updatedArray1,
        });
        localStorage.setItem("residual_value", updatedArray1[4]);
        this.setState({
            estimatedresidualvaluemyname: updatedArray1,
        });

        // const adjustedvehicleprice = parseFloat(this.state.purchasepricevalue) - (parseFloat(this.state.tradeinvalue) || 0) + (parseFloat(this.state.tradeinpayoff) || 0);
        //                const totalamounttobefinanced = adjustedvehicleprice - (parseFloat(this.state.downpayment) || 0) + (parseFloat(this.state.insurance) || 0) + (parseFloat(this.state.otherfees) || 0) + (parseFloat(this.state.salestax) || 0);

        const adjustedvehicleprice =
            parseFloat(this.state.purchasepricevalue.replace(/,/g, "")) -
            (parseFloat(this.state.tradeinvalue.replace(/,/g, "")) || 0) +
            (parseFloat(this.state.tradeinpayoff.replace(/,/g, "")) || 0);
        const totalamounttobefinanced =
            adjustedvehicleprice -
            (parseFloat(this.state.downpayment.replace(/,/g, "")) || 0) +
            (parseFloat(this.state.insurance.replace(/,/g, "")) || 0) +
            (parseFloat(this.state.otherfees.replace(/,/g, "")) || 0) +
            (parseFloat(this.state.salestax) || 0);

        // alert(totalamounttobefinanced);
        this.setState({ totalamountfinanced: totalamounttobefinanced });

        const calculateMonthlyPayment = (
            totalLoanAmount,
            termMonths,
            pifRate,
            endBalance
        ) => {
            var loanAmount = totalLoanAmount;
            var endBalance = endBalance;
            var monthlyInterestRate = pifRate;
            var termMonths = termMonths;
            let monthlyPayment = 0;
            let epsilon = 0.01; // Tolerance for convergence
            let maxIterations = 1000; // Maximum number of iterations

            for (let i = 0; i < maxIterations; i++) {
                let remainingBalance = loanAmount;

                // Calculate remaining balance using the current monthly payment
                for (let j = 0; j < termMonths; j++) {
                    let interest = remainingBalance * monthlyInterestRate;
                    let principal = monthlyPayment - interest;
                    remainingBalance -= principal;
                }

                // Check if the remaining balance is close enough to the end balance
                if (Math.abs(remainingBalance - endBalance) < epsilon) {
                    break; // Stop iteration if close enough
                }

                // Adjust monthly payment based on the difference between calculated remaining balance and end balance
                monthlyPayment += (remainingBalance - endBalance) / termMonths;
            }

            return monthlyPayment;
        };

        // Terms for calculation
        const terms = [12, 24, 36, 48, 60, 72, 84];
        const conventionalRate = this.state.conventionalrate / 100 / 12;
        const pifRate2 = this.state.pifrate / 100 / 12;
        const estimatervcArray = updatedArray1;
        let pifMonthlyPayments = [];
        let conventionalMonthlyPayments = [];

        // estimatervcArray.forEach(estimatervcValue => {
        terms.forEach((term, index) => {
            // // Calculate monthly payment for PIF rate
            console.log(this.state.totalamountfinanced);
            console.log(estimatervcArray[index]);
            console.log("raju");
            // var toalamountfinceddata = this.state.totalamountfinanced - estimatervcArray[index]
            var toalamountfinceddata = totalamounttobefinanced;

            const pifMonthlyPayment = calculateMonthlyPayment(
                toalamountfinceddata,
                term,
                pifRate2,
                estimatervcArray[index]
            );
            pifMonthlyPayments.push(pifMonthlyPayment);
        });
        //});
        this.setState({
            monthlypayment: pifMonthlyPayments,
        });
this.setState({ estimateresidualvaluepopup: '' });
this.setState({ showcustomerPopup2: false });
}

if(month==72)
{
      

        const updatedArray1 = [...this.state.estimatedresidualvaluemyname];
        console.log("razai");
        console.log(updatedArray1);

        updatedArray1[5] = newValue1;

        console.log("test kaka");

        console.log(updatedArray1);
        this.setState({
            estimatedresidualvalue: updatedArray1,
        });
        localStorage.setItem("residual_value", updatedArray1[5]);
        this.setState({
            estimatedresidualvaluemyname: updatedArray1,
        });

        // const adjustedvehicleprice = parseFloat(this.state.purchasepricevalue) - (parseFloat(this.state.tradeinvalue) || 0) + (parseFloat(this.state.tradeinpayoff) || 0);
        //                const totalamounttobefinanced = adjustedvehicleprice - (parseFloat(this.state.downpayment) || 0) + (parseFloat(this.state.insurance) || 0) + (parseFloat(this.state.otherfees) || 0) + (parseFloat(this.state.salestax) || 0);

        const adjustedvehicleprice =
            parseFloat(this.state.purchasepricevalue.replace(/,/g, "")) -
            (parseFloat(this.state.tradeinvalue.replace(/,/g, "")) || 0) +
            (parseFloat(this.state.tradeinpayoff.replace(/,/g, "")) || 0);
        const totalamounttobefinanced =
            adjustedvehicleprice -
            (parseFloat(this.state.downpayment.replace(/,/g, "")) || 0) +
            (parseFloat(this.state.insurance.replace(/,/g, "")) || 0) +
            (parseFloat(this.state.otherfees.replace(/,/g, "")) || 0) +
            (parseFloat(this.state.salestax) || 0);

        // alert(totalamounttobefinanced);
        this.setState({ totalamountfinanced: totalamounttobefinanced });

        const calculateMonthlyPayment = (
            totalLoanAmount,
            termMonths,
            pifRate,
            endBalance
        ) => {
            var loanAmount = totalLoanAmount;
            var endBalance = endBalance;
            var monthlyInterestRate = pifRate;
            var termMonths = termMonths;
            let monthlyPayment = 0;
            let epsilon = 0.01; // Tolerance for convergence
            let maxIterations = 1000; // Maximum number of iterations

            for (let i = 0; i < maxIterations; i++) {
                let remainingBalance = loanAmount;

                // Calculate remaining balance using the current monthly payment
                for (let j = 0; j < termMonths; j++) {
                    let interest = remainingBalance * monthlyInterestRate;
                    let principal = monthlyPayment - interest;
                    remainingBalance -= principal;
                }

                // Check if the remaining balance is close enough to the end balance
                if (Math.abs(remainingBalance - endBalance) < epsilon) {
                    break; // Stop iteration if close enough
                }

                // Adjust monthly payment based on the difference between calculated remaining balance and end balance
                monthlyPayment += (remainingBalance - endBalance) / termMonths;
            }

            return monthlyPayment;
        };

        // Terms for calculation
        const terms = [12, 24, 36, 48, 60, 72, 84];
        const conventionalRate = this.state.conventionalrate / 100 / 12;
        const pifRate2 = this.state.pifrate / 100 / 12;
        const estimatervcArray = updatedArray1;
        let pifMonthlyPayments = [];
        let conventionalMonthlyPayments = [];

        // estimatervcArray.forEach(estimatervcValue => {
        terms.forEach((term, index) => {
            // // Calculate monthly payment for PIF rate
            console.log(this.state.totalamountfinanced);
            console.log(estimatervcArray[index]);
            console.log("raju");
            // var toalamountfinceddata = this.state.totalamountfinanced - estimatervcArray[index]
            var toalamountfinceddata = totalamounttobefinanced;

            const pifMonthlyPayment = calculateMonthlyPayment(
                toalamountfinceddata,
                term,
                pifRate2,
                estimatervcArray[index]
            );
            pifMonthlyPayments.push(pifMonthlyPayment);
        });
        //});
        this.setState({
            monthlypayment: pifMonthlyPayments,
        });
this.setState({ estimateresidualvaluepopup: '' });
this.setState({ showcustomerPopup2: false });
      }

if(month==84)
{
   

        const updatedArray1 = [...this.state.estimatedresidualvaluemyname];
        console.log("razai");
        console.log(updatedArray1);

        updatedArray1[6] = newValue1;

        console.log("test kaka");

        console.log(updatedArray1);
        this.setState({
            estimatedresidualvalue: updatedArray1,
        });
        localStorage.setItem("residual_value", updatedArray1[6]);
        this.setState({
            estimatedresidualvaluemyname: updatedArray1,
        });

        // const adjustedvehicleprice = parseFloat(this.state.purchasepricevalue) - (parseFloat(this.state.tradeinvalue) || 0) + (parseFloat(this.state.tradeinpayoff) || 0);
        //                const totalamounttobefinanced = adjustedvehicleprice - (parseFloat(this.state.downpayment) || 0) + (parseFloat(this.state.insurance) || 0) + (parseFloat(this.state.otherfees) || 0) + (parseFloat(this.state.salestax) || 0);

        const adjustedvehicleprice =
            parseFloat(this.state.purchasepricevalue.replace(/,/g, "")) -
            (parseFloat(this.state.tradeinvalue.replace(/,/g, "")) || 0) +
            (parseFloat(this.state.tradeinpayoff.replace(/,/g, "")) || 0);
        const totalamounttobefinanced =
            adjustedvehicleprice -
            (parseFloat(this.state.downpayment.replace(/,/g, "")) || 0) +
            (parseFloat(this.state.insurance.replace(/,/g, "")) || 0) +
            (parseFloat(this.state.otherfees.replace(/,/g, "")) || 0) +
            (parseFloat(this.state.salestax) || 0);

        // alert(totalamounttobefinanced);
        this.setState({ totalamountfinanced: totalamounttobefinanced });

        const calculateMonthlyPayment = (
            totalLoanAmount,
            termMonths,
            pifRate,
            endBalance
        ) => {
            var loanAmount = totalLoanAmount;
            var endBalance = endBalance;
            var monthlyInterestRate = pifRate;
            var termMonths = termMonths;
            let monthlyPayment = 0;
            let epsilon = 0.01; // Tolerance for convergence
            let maxIterations = 1000; // Maximum number of iterations

            for (let i = 0; i < maxIterations; i++) {
                let remainingBalance = loanAmount;

                // Calculate remaining balance using the current monthly payment
                for (let j = 0; j < termMonths; j++) {
                    let interest = remainingBalance * monthlyInterestRate;
                    let principal = monthlyPayment - interest;
                    remainingBalance -= principal;
                }

                // Check if the remaining balance is close enough to the end balance
                if (Math.abs(remainingBalance - endBalance) < epsilon) {
                    break; // Stop iteration if close enough
                }

                // Adjust monthly payment based on the difference between calculated remaining balance and end balance
                monthlyPayment += (remainingBalance - endBalance) / termMonths;
            }

            return monthlyPayment;
        };

        // Terms for calculation
        const terms = [12, 24, 36, 48, 60, 72, 84];
        const conventionalRate = this.state.conventionalrate / 100 / 12;
        const pifRate2 = this.state.pifrate / 100 / 12;
        const estimatervcArray = updatedArray1;
        let pifMonthlyPayments = [];
        let conventionalMonthlyPayments = [];

        // estimatervcArray.forEach(estimatervcValue => {
        terms.forEach((term, index) => {
            // // Calculate monthly payment for PIF rate
            console.log(this.state.totalamountfinanced);
            console.log(estimatervcArray[index]);
            console.log("raju");
            // var toalamountfinceddata = this.state.totalamountfinanced - estimatervcArray[index]
            var toalamountfinceddata = totalamounttobefinanced;

            const pifMonthlyPayment = calculateMonthlyPayment(
                toalamountfinceddata,
                term,
                pifRate2,
                estimatervcArray[index]
            );
            pifMonthlyPayments.push(pifMonthlyPayment);
        });
        //});
        this.setState({
            monthlypayment: pifMonthlyPayments,
        });
this.setState({ estimateresidualvaluepopup: '' });
this.setState({ showcustomerPopup2: false });
}


    };

    // handlerv24 = (e) => {
    //     //const newValue = parseFloat(e.target.value);


    //  const numbersAndDecimal = e.target.value.replace(/[^0-9.]/g, ""); // Keep only digits and dot
    //     const sections = numbersAndDecimal.split("."); // Split at the dot, if present
    //     const integerPart = sections[0]; // Add commas for thousand separators
    //     const decimalPart = sections[1] ? sections[1].substring(0, 2) : ""; // Allow up to 4 decimal places
    //     const newValue =
    //         sections.length === 2 ? `${integerPart}.${decimalPart}` : integerPart;



    //     const previousValue = this.state.prevestimatedresidualvalue24;

    //     if (newValue > previousValue) {
    //         //alert("You can't select a value greater than the previous value.");
    //         Swal({
    //             text: "You can't select a value greater than the previous value.",
    //             icon: "error",
    //             button: "OK",
    //         });

    //         return;
    //     }

    //     const updatedArray1 = [...this.state.estimatedresidualvaluemyname];
    //     console.log("razai");
    //     console.log(updatedArray1);

    //     updatedArray1[1] = Number(newValue);

    //     console.log("test kaka");

    //     console.log(updatedArray1);
    //     this.setState({
    //         estimatedresidualvalue: updatedArray1,
    //     });
    //     localStorage.setItem("residual_value", updatedArray1[1]);
    //     this.setState({
    //         estimatedresidualvaluemyname: updatedArray1,
    //     });

    //     // const adjustedvehicleprice = parseFloat(this.state.purchasepricevalue) - (parseFloat(this.state.tradeinvalue) || 0) + (parseFloat(this.state.tradeinpayoff) || 0);
    //     //                const totalamounttobefinanced = adjustedvehicleprice - (parseFloat(this.state.downpayment) || 0) + (parseFloat(this.state.insurance) || 0) + (parseFloat(this.state.otherfees) || 0) + (parseFloat(this.state.salestax) || 0);

    //     const adjustedvehicleprice =
    //         parseFloat(this.state.purchasepricevalue.replace(/,/g, "")) -
    //         (parseFloat(this.state.tradeinvalue.replace(/,/g, "")) || 0) +
    //         (parseFloat(this.state.tradeinpayoff.replace(/,/g, "")) || 0);
    //     const totalamounttobefinanced =
    //         adjustedvehicleprice -
    //         (parseFloat(this.state.downpayment.replace(/,/g, "")) || 0) +
    //         (parseFloat(this.state.insurance.replace(/,/g, "")) || 0) +
    //         (parseFloat(this.state.otherfees.replace(/,/g, "")) || 0) +
    //         (parseFloat(this.state.salestax) || 0);

    //     // alert(totalamounttobefinanced);
    //     this.setState({ totalamountfinanced: totalamounttobefinanced });

    //     const calculateMonthlyPayment = (
    //         totalLoanAmount,
    //         termMonths,
    //         pifRate,
    //         endBalance
    //     ) => {
    //         var loanAmount = totalLoanAmount;
    //         var endBalance = endBalance;
    //         var monthlyInterestRate = pifRate;
    //         var termMonths = termMonths;
    //         let monthlyPayment = 0;
    //         let epsilon = 0.01; // Tolerance for convergence
    //         let maxIterations = 1000; // Maximum number of iterations

    //         for (let i = 0; i < maxIterations; i++) {
    //             let remainingBalance = loanAmount;

    //             // Calculate remaining balance using the current monthly payment
    //             for (let j = 0; j < termMonths; j++) {
    //                 let interest = remainingBalance * monthlyInterestRate;
    //                 let principal = monthlyPayment - interest;
    //                 remainingBalance -= principal;
    //             }

    //             // Check if the remaining balance is close enough to the end balance
    //             if (Math.abs(remainingBalance - endBalance) < epsilon) {
    //                 break; // Stop iteration if close enough
    //             }

    //             // Adjust monthly payment based on the difference between calculated remaining balance and end balance
    //             monthlyPayment += (remainingBalance - endBalance) / termMonths;
    //         }

    //         return monthlyPayment;
    //     };

    //     // Terms for calculation
    //     const terms = [12, 24, 36, 48, 60, 72, 84];
    //     const conventionalRate = this.state.conventionalrate / 100 / 12;
    //     const pifRate2 = this.state.pifrate / 100 / 12;
    //     const estimatervcArray = updatedArray1;
    //     let pifMonthlyPayments = [];
    //     let conventionalMonthlyPayments = [];

    //     // estimatervcArray.forEach(estimatervcValue => {
    //     terms.forEach((term, index) => {
    //         // // Calculate monthly payment for PIF rate
    //         console.log(this.state.totalamountfinanced);
    //         console.log(estimatervcArray[index]);
    //         console.log("raju");
    //         //var toalamountfinceddata = this.state.totalamountfinanced - estimatervcArray[index]
    //         var toalamountfinceddata = totalamounttobefinanced;

    //         const pifMonthlyPayment = calculateMonthlyPayment(
    //             toalamountfinceddata,
    //             term,
    //             pifRate2,
    //             estimatervcArray[index]
    //         );
    //         pifMonthlyPayments.push(pifMonthlyPayment);
    //     });
    //     //});
    //     this.setState({
    //         monthlypayment: pifMonthlyPayments,
    //     });
    // };

    handlePrevButtonClick = () => {
        const { currentTab } = this.state;
        // Logic to handle clicking the "previous" button
        if (currentTab > 0) {
            this.setState({ currentTab: currentTab - 1 });
        }
    };

    handleNextButtonClick = () => {
        var vindata = this.state.vinno.trim();

        if (vindata == "") {
            // alert("Please Fill Vin No.Field");

            Swal({
                text: "Please Fill Vin No.Field.",
                icon: "error",
                button: "OK",
            });
            return false;
        }

        // alert(vindata.length);

        if (vindata.length < 17 || vindata.length > 17) {
            // alert("Please Fill Vin No.Field");

            Swal({
                text: "Vin no. should be 17 digit number",
                icon: "error",
                button: "OK",
            });
            return false;
        }

        // alert("ejbjb");
        const { currentTab, totalTabs } = this.state;
        // Logic to handle clicking the "next" button
        // alert(currentTab);
        // alert(totalTabs);
        if (currentTab < totalTabs - 1) {
            this.setState({ currentTab: currentTab + 1 });

            this.setState({ vpnbygetquote: false });

            //alert(this.state.currentTab);
        }
    };

//     handlerv36 = (e) => {
//         //const newValue = parseFloat(e.target.value);
        
// const numbersAndDecimal = e.target.value.replace(/[^0-9.]/g, ""); // Keep only digits and dot
//         const sections = numbersAndDecimal.split("."); // Split at the dot, if present
//         const integerPart = sections[0]; // Add commas for thousand separators
//         const decimalPart = sections[1] ? sections[1].substring(0, 2) : ""; // Allow up to 4 decimal places
//         const newValue =
//             sections.length === 2 ? `${integerPart}.${decimalPart}` : integerPart;




//         const previousValue = this.state.prevestimatedresidualvalue36;

//         if (newValue > previousValue) {
//             // alert("You can't select a value greater than the previous value.");

//             Swal({
//                 text: "You can't select a value greater than the previous value",
//                 icon: "error",
//                 button: "OK",
//             });

//             return;
//         }

//         const updatedArray1 = [...this.state.estimatedresidualvaluemyname];
//         console.log("razai");
//         console.log(updatedArray1);

//         updatedArray1[2] = Number(newValue);

//         console.log("test kaka");

//         console.log(updatedArray1);
//         this.setState({
//             estimatedresidualvalue: updatedArray1,
//         });
//         localStorage.setItem("residual_value", updatedArray1[2]);
//         this.setState({
//             estimatedresidualvaluemyname: updatedArray1,
//         });

//         // const adjustedvehicleprice = parseFloat(this.state.purchasepricevalue) - (parseFloat(this.state.tradeinvalue) || 0) + (parseFloat(this.state.tradeinpayoff) || 0);
//         //                const totalamounttobefinanced = adjustedvehicleprice - (parseFloat(this.state.downpayment) || 0) + (parseFloat(this.state.insurance) || 0) + (parseFloat(this.state.otherfees) || 0) + (parseFloat(this.state.salestax) || 0);

//         const adjustedvehicleprice =
//             parseFloat(this.state.purchasepricevalue.replace(/,/g, "")) -
//             (parseFloat(this.state.tradeinvalue.replace(/,/g, "")) || 0) +
//             (parseFloat(this.state.tradeinpayoff.replace(/,/g, "")) || 0);
//         const totalamounttobefinanced =
//             adjustedvehicleprice -
//             (parseFloat(this.state.downpayment.replace(/,/g, "")) || 0) +
//             (parseFloat(this.state.insurance.replace(/,/g, "")) || 0) +
//             (parseFloat(this.state.otherfees.replace(/,/g, "")) || 0) +
//             (parseFloat(this.state.salestax) || 0);

//         // alert(totalamounttobefinanced);
//         this.setState({ totalamountfinanced: totalamounttobefinanced });

//         const calculateMonthlyPayment = (
//             totalLoanAmount,
//             termMonths,
//             pifRate,
//             endBalance
//         ) => {
//             var loanAmount = totalLoanAmount;
//             var endBalance = endBalance;
//             var monthlyInterestRate = pifRate;
//             var termMonths = termMonths;
//             let monthlyPayment = 0;
//             let epsilon = 0.01; // Tolerance for convergence
//             let maxIterations = 1000; // Maximum number of iterations

//             for (let i = 0; i < maxIterations; i++) {
//                 let remainingBalance = loanAmount;

//                 // Calculate remaining balance using the current monthly payment
//                 for (let j = 0; j < termMonths; j++) {
//                     let interest = remainingBalance * monthlyInterestRate;
//                     let principal = monthlyPayment - interest;
//                     remainingBalance -= principal;
//                 }

//                 // Check if the remaining balance is close enough to the end balance
//                 if (Math.abs(remainingBalance - endBalance) < epsilon) {
//                     break; // Stop iteration if close enough
//                 }

//                 // Adjust monthly payment based on the difference between calculated remaining balance and end balance
//                 monthlyPayment += (remainingBalance - endBalance) / termMonths;
//             }

//             return monthlyPayment;
//         };

//         // Terms for calculation
//         const terms = [12, 24, 36, 48, 60, 72, 84];
//         const conventionalRate = this.state.conventionalrate / 100 / 12;
//         const pifRate2 = this.state.pifrate / 100 / 12;
//         const estimatervcArray = updatedArray1;
//         let pifMonthlyPayments = [];
//         let conventionalMonthlyPayments = [];

//         // estimatervcArray.forEach(estimatervcValue => {
//         terms.forEach((term, index) => {
//             // // Calculate monthly payment for PIF rate
//             console.log(this.state.totalamountfinanced);
//             console.log(estimatervcArray[index]);
//             console.log("raju");
//             // var toalamountfinceddata = this.state.totalamountfinanced - estimatervcArray[index]
//             var toalamountfinceddata = totalamounttobefinanced;

//             const pifMonthlyPayment = calculateMonthlyPayment(
//                 toalamountfinceddata,
//                 term,
//                 pifRate2,
//                 estimatervcArray[index]
//             );
//             pifMonthlyPayments.push(pifMonthlyPayment);
//         });
//         //});
//         this.setState({
//             monthlypayment: pifMonthlyPayments,
//         });
//     };

//     handlerv48 = (e) => {
//         //const newValue = parseFloat(e.target.value);
     
// const numbersAndDecimal = e.target.value.replace(/[^0-9.]/g, ""); // Keep only digits and dot
//         const sections = numbersAndDecimal.split("."); // Split at the dot, if present
//         const integerPart = sections[0]; // Add commas for thousand separators
//         const decimalPart = sections[1] ? sections[1].substring(0, 2) : ""; // Allow up to 4 decimal places
//         const newValue =
//             sections.length === 2 ? `${integerPart}.${decimalPart}` : integerPart;





//         const previousValue = this.state.prevestimatedresidualvalue48;

//         if (newValue > previousValue) {
//             // alert("You can't select a value greater than the previous value.");

//             Swal({
//                 text: "You can't select a value greater than the previous value.",
//                 icon: "error",
//                 button: "OK",
//             });

//             return;
//         }

//         const updatedArray1 = [...this.state.estimatedresidualvaluemyname];
//         console.log("razai");
//         console.log(updatedArray1);

//         updatedArray1[3] = Number(newValue);

//         console.log("test kaka");

//         console.log(updatedArray1);
//         this.setState({
//             estimatedresidualvalue: updatedArray1,
//         });
//         localStorage.setItem("residual_value", updatedArray1[3]);

//         this.setState({
//             estimatedresidualvaluemyname: updatedArray1,
//         });

//         // const adjustedvehicleprice = parseFloat(this.state.purchasepricevalue) - (parseFloat(this.state.tradeinvalue) || 0) + (parseFloat(this.state.tradeinpayoff) || 0);
//         //                const totalamounttobefinanced = adjustedvehicleprice - (parseFloat(this.state.downpayment) || 0) + (parseFloat(this.state.insurance) || 0) + (parseFloat(this.state.otherfees) || 0) + (parseFloat(this.state.salestax) || 0);

//         const adjustedvehicleprice =
//             parseFloat(this.state.purchasepricevalue.replace(/,/g, "")) -
//             (parseFloat(this.state.tradeinvalue.replace(/,/g, "")) || 0) +
//             (parseFloat(this.state.tradeinpayoff.replace(/,/g, "")) || 0);
//         const totalamounttobefinanced =
//             adjustedvehicleprice -
//             (parseFloat(this.state.downpayment.replace(/,/g, "")) || 0) +
//             (parseFloat(this.state.insurance.replace(/,/g, "")) || 0) +
//             (parseFloat(this.state.otherfees.replace(/,/g, "")) || 0) +
//             (parseFloat(this.state.salestax) || 0);

//         // alert(totalamounttobefinanced);
//         this.setState({ totalamountfinanced: totalamounttobefinanced });

//         const calculateMonthlyPayment = (
//             totalLoanAmount,
//             termMonths,
//             pifRate,
//             endBalance
//         ) => {
//             var loanAmount = totalLoanAmount;
//             var endBalance = endBalance;
//             var monthlyInterestRate = pifRate;
//             var termMonths = termMonths;
//             let monthlyPayment = 0;
//             let epsilon = 0.01; // Tolerance for convergence
//             let maxIterations = 1000; // Maximum number of iterations

//             for (let i = 0; i < maxIterations; i++) {
//                 let remainingBalance = loanAmount;

//                 // Calculate remaining balance using the current monthly payment
//                 for (let j = 0; j < termMonths; j++) {
//                     let interest = remainingBalance * monthlyInterestRate;
//                     let principal = monthlyPayment - interest;
//                     remainingBalance -= principal;
//                 }

//                 // Check if the remaining balance is close enough to the end balance
//                 if (Math.abs(remainingBalance - endBalance) < epsilon) {
//                     break; // Stop iteration if close enough
//                 }

//                 // Adjust monthly payment based on the difference between calculated remaining balance and end balance
//                 monthlyPayment += (remainingBalance - endBalance) / termMonths;
//             }

//             return monthlyPayment;
//         };

//         // Terms for calculation
//         const terms = [12, 24, 36, 48, 60, 72, 84];
//         const conventionalRate = this.state.conventionalrate / 100 / 12;
//         const pifRate2 = this.state.pifrate / 100 / 12;
//         const estimatervcArray = updatedArray1;
//         let pifMonthlyPayments = [];
//         let conventionalMonthlyPayments = [];

//         // estimatervcArray.forEach(estimatervcValue => {
//         terms.forEach((term, index) => {
//             // // Calculate monthly payment for PIF rate
//             console.log(this.state.totalamountfinanced);
//             console.log(estimatervcArray[index]);
//             console.log("raju");
//             //var toalamountfinceddata = this.state.totalamountfinanced - estimatervcArray[index]
//             var toalamountfinceddata = totalamounttobefinanced;

//             const pifMonthlyPayment = calculateMonthlyPayment(
//                 toalamountfinceddata,
//                 term,
//                 pifRate2,
//                 estimatervcArray[index]
//             );
//             pifMonthlyPayments.push(pifMonthlyPayment);
//         });
//         //});
//         this.setState({
//             monthlypayment: pifMonthlyPayments,
//         });
//     };

//     handlerv60 = (e) => {
//         //const newValue = parseFloat(e.target.value);
        
// const numbersAndDecimal = e.target.value.replace(/[^0-9.]/g, ""); // Keep only digits and dot
//         const sections = numbersAndDecimal.split("."); // Split at the dot, if present
//         const integerPart = sections[0]; // Add commas for thousand separators
//         const decimalPart = sections[1] ? sections[1].substring(0, 2) : ""; // Allow up to 4 decimal places
//         const newValue =
//             sections.length === 2 ? `${integerPart}.${decimalPart}` : integerPart;



//         const previousValue = this.state.prevestimatedresidualvalue60;

//         if (newValue > previousValue) {
//             // alert("You can't select a value greater than the previous value.");

//             Swal({
//                 text: "You can't select a value greater than the previous value.",
//                 icon: "error",
//                 button: "OK",
//             });
//             return;
//         }

//         const updatedArray1 = [...this.state.estimatedresidualvaluemyname];
//         console.log("razai");
//         console.log(updatedArray1);

//         updatedArray1[4] = Number(newValue);

//         console.log("test kaka");

//         console.log(updatedArray1);
//         this.setState({
//             estimatedresidualvalue: updatedArray1,
//         });
//         localStorage.setItem("residual_value", updatedArray1[4]);
//         this.setState({
//             estimatedresidualvaluemyname: updatedArray1,
//         });
//         // const adjustedvehicleprice = parseFloat(this.state.purchasepricevalue) - (parseFloat(this.state.tradeinvalue) || 0) + (parseFloat(this.state.tradeinpayoff) || 0);
//         //                const totalamounttobefinanced = adjustedvehicleprice - (parseFloat(this.state.downpayment) || 0) + (parseFloat(this.state.insurance) || 0) + (parseFloat(this.state.otherfees) || 0) + (parseFloat(this.state.salestax) || 0);

//         const adjustedvehicleprice =
//             parseFloat(this.state.purchasepricevalue.replace(/,/g, "")) -
//             (parseFloat(this.state.tradeinvalue.replace(/,/g, "")) || 0) +
//             (parseFloat(this.state.tradeinpayoff.replace(/,/g, "")) || 0);
//         const totalamounttobefinanced =
//             adjustedvehicleprice -
//             (parseFloat(this.state.downpayment.replace(/,/g, "")) || 0) +
//             (parseFloat(this.state.insurance.replace(/,/g, "")) || 0) +
//             (parseFloat(this.state.otherfees.replace(/,/g, "")) || 0) +
//             (parseFloat(this.state.salestax) || 0);

//         // alert(totalamounttobefinanced);
//         this.setState({ totalamountfinanced: totalamounttobefinanced });

//         const calculateMonthlyPayment = (
//             totalLoanAmount,
//             termMonths,
//             pifRate,
//             endBalance
//         ) => {
//             var loanAmount = totalLoanAmount;
//             var endBalance = endBalance;
//             var monthlyInterestRate = pifRate;
//             var termMonths = termMonths;
//             let monthlyPayment = 0;
//             let epsilon = 0.01; // Tolerance for convergence
//             let maxIterations = 1000; // Maximum number of iterations

//             for (let i = 0; i < maxIterations; i++) {
//                 let remainingBalance = loanAmount;

//                 // Calculate remaining balance using the current monthly payment
//                 for (let j = 0; j < termMonths; j++) {
//                     let interest = remainingBalance * monthlyInterestRate;
//                     let principal = monthlyPayment - interest;
//                     remainingBalance -= principal;
//                 }

//                 // Check if the remaining balance is close enough to the end balance
//                 if (Math.abs(remainingBalance - endBalance) < epsilon) {
//                     break; // Stop iteration if close enough
//                 }

//                 // Adjust monthly payment based on the difference between calculated remaining balance and end balance
//                 monthlyPayment += (remainingBalance - endBalance) / termMonths;
//             }

//             return monthlyPayment;
//         };

//         // Terms for calculation
//         const terms = [12, 24, 36, 48, 60, 72, 84];
//         const conventionalRate = this.state.conventionalrate / 100 / 12;
//         const pifRate2 = this.state.pifrate / 100 / 12;
//         const estimatervcArray = updatedArray1;
//         let pifMonthlyPayments = [];
//         let conventionalMonthlyPayments = [];

//         // estimatervcArray.forEach(estimatervcValue => {
//         terms.forEach((term, index) => {
//             // // Calculate monthly payment for PIF rate
//             console.log(this.state.totalamountfinanced);
//             console.log(estimatervcArray[index]);
//             console.log("raju");
//             // var toalamountfinceddata = this.state.totalamountfinanced - estimatervcArray[index]
//             var toalamountfinceddata = totalamounttobefinanced;

//             const pifMonthlyPayment = calculateMonthlyPayment(
//                 toalamountfinceddata,
//                 term,
//                 pifRate2,
//                 estimatervcArray[index]
//             );
//             pifMonthlyPayments.push(pifMonthlyPayment);
//         });
//         //});
//         this.setState({
//             monthlypayment: pifMonthlyPayments,
//         });
//     };

//     handlerv72 = (e) => {
//         //const newValue = parseFloat(e.target.value);
        

// const numbersAndDecimal = e.target.value.replace(/[^0-9.]/g, ""); // Keep only digits and dot
//         const sections = numbersAndDecimal.split("."); // Split at the dot, if present
//         const integerPart = sections[0]; // Add commas for thousand separators
//         const decimalPart = sections[1] ? sections[1].substring(0, 2) : ""; // Allow up to 4 decimal places
//         const newValue =
//             sections.length === 2 ? `${integerPart}.${decimalPart}` : integerPart;



//         const previousValue = this.state.prevestimatedresidualvalue72;

//         if (newValue > previousValue) {
//             //alert("You can't select a value greater than the previous value.");

//             Swal({
//                 text: "You can't select a value greater than the previous value.",
//                 icon: "error",
//                 button: "OK",
//             });
//             return;
//         }

//         const updatedArray1 = [...this.state.estimatedresidualvaluemyname];
//         console.log("razai");
//         console.log(updatedArray1);

//         updatedArray1[5] = Number(newValue);

//         console.log("test kaka");

//         console.log(updatedArray1);
//         this.setState({
//             estimatedresidualvalue: updatedArray1,
//         });

//         localStorage.setItem("residual_value", updatedArray1[5]);

//         this.setState({
//             estimatedresidualvaluemyname: updatedArray1,
//         });
//         // const adjustedvehicleprice = parseFloat(this.state.purchasepricevalue) - (parseFloat(this.state.tradeinvalue) || 0) + (parseFloat(this.state.tradeinpayoff) || 0);
//         //                const totalamounttobefinanced = adjustedvehicleprice - (parseFloat(this.state.downpayment) || 0) + (parseFloat(this.state.insurance) || 0) + (parseFloat(this.state.otherfees) || 0) + (parseFloat(this.state.salestax) || 0);

//         const adjustedvehicleprice =
//             parseFloat(this.state.purchasepricevalue.replace(/,/g, "")) -
//             (parseFloat(this.state.tradeinvalue.replace(/,/g, "")) || 0) +
//             (parseFloat(this.state.tradeinpayoff.replace(/,/g, "")) || 0);
//         const totalamounttobefinanced =
//             adjustedvehicleprice -
//             (parseFloat(this.state.downpayment.replace(/,/g, "")) || 0) +
//             (parseFloat(this.state.insurance.replace(/,/g, "")) || 0) +
//             (parseFloat(this.state.otherfees.replace(/,/g, "")) || 0) +
//             (parseFloat(this.state.salestax) || 0);

//         // alert(totalamounttobefinanced);
//         this.setState({ totalamountfinanced: totalamounttobefinanced });

//         const calculateMonthlyPayment = (
//             totalLoanAmount,
//             termMonths,
//             pifRate,
//             endBalance
//         ) => {
//             var loanAmount = totalLoanAmount;
//             var endBalance = endBalance;
//             var monthlyInterestRate = pifRate;
//             var termMonths = termMonths;
//             let monthlyPayment = 0;
//             let epsilon = 0.01; // Tolerance for convergence
//             let maxIterations = 1000; // Maximum number of iterations

//             for (let i = 0; i < maxIterations; i++) {
//                 let remainingBalance = loanAmount;

//                 // Calculate remaining balance using the current monthly payment
//                 for (let j = 0; j < termMonths; j++) {
//                     let interest = remainingBalance * monthlyInterestRate;
//                     let principal = monthlyPayment - interest;
//                     remainingBalance -= principal;
//                 }

//                 // Check if the remaining balance is close enough to the end balance
//                 if (Math.abs(remainingBalance - endBalance) < epsilon) {
//                     break; // Stop iteration if close enough
//                 }

//                 // Adjust monthly payment based on the difference between calculated remaining balance and end balance
//                 monthlyPayment += (remainingBalance - endBalance) / termMonths;
//             }

//             return monthlyPayment;
//         };

//         // Terms for calculation
//         const terms = [12, 24, 36, 48, 60, 72, 84];
//         const conventionalRate = this.state.conventionalrate / 100 / 12;
//         const pifRate2 = this.state.pifrate / 100 / 12;
//         const estimatervcArray = updatedArray1;
//         let pifMonthlyPayments = [];
//         let conventionalMonthlyPayments = [];

//         // estimatervcArray.forEach(estimatervcValue => {
//         terms.forEach((term, index) => {
//             // // Calculate monthly payment for PIF rate
//             console.log(this.state.totalamountfinanced);
//             console.log(estimatervcArray[index]);
//             console.log("raju");
//             //var toalamountfinceddata = this.state.totalamountfinanced - estimatervcArray[index]
//             var toalamountfinceddata = totalamounttobefinanced;

//             const pifMonthlyPayment = calculateMonthlyPayment(
//                 toalamountfinceddata,
//                 term,
//                 pifRate2,
//                 estimatervcArray[index]
//             );
//             pifMonthlyPayments.push(pifMonthlyPayment);
//         });
//         //});
//         this.setState({
//             monthlypayment: pifMonthlyPayments,
//         });
//     };

//     handlerv84 = (e) => 
//     {
//      // const newValue = parseFloat(e.target.value);
     

// const numbersAndDecimal = e.target.value.replace(/[^0-9.]/g, ""); // Keep only digits and dot
//         const sections = numbersAndDecimal.split("."); // Split at the dot, if present
//         const integerPart = sections[0]; // Add commas for thousand separators
//         const decimalPart = sections[1] ? sections[1].substring(0, 2) : ""; // Allow up to 4 decimal places
//         const newValue =
//             sections.length === 2 ? `${integerPart}.${decimalPart}` : integerPart;



//         // const numbersAndDecimal = value.replace(/[^0-9.]/g, ''); // Keep only digits and dot
//         //         const sections = numbersAndDecimal.split('.'); // Split at the dot, if present
//         //         const integerPart = sections[0]; // Add commas for thousand separators
//         //         const decimalPart = sections[1] ? sections[1].substring(0, 4) : ''; // Allow up to 4 decimal places

//         //         const formattedValue = sections.length === 2
//         //             ? `${integerPart}.${decimalPart}`
//         //             : integerPart;

//         //    this.setState({
//         //         estimatedresidualvalue: [formattedValue, ...this.state.estimatedresidualvalue.slice(1)]
//         //     });
//         const previousValue = this.state.prevestimatedresidualvalue84;
//         if (newValue > previousValue) {
//             // alert("You can't select a value greater than the previous value.");
//             Swal({
//                 text: "You can't select a value greater than the previous value.",
//                 icon: "error",
//                 button: "OK",
//             });
//             return;
//         }

//         const updatedArray1 = [...this.state.estimatedresidualvalue];
//         console.log("razai");
//         console.log(updatedArray1); 

//         updatedArray1[6] = Number(newValue);

//         console.log("test kaka");

//         console.log(updatedArray1);
//         this.setState({
//             estimatedresidualvalue: updatedArray1,
//         });

//         localStorage.setItem("residual_value", updatedArray1[6]);
//         // this.setState({
//         //     estimatedresidualvaluemyname: updatedArray1,

//         // });

//         // const adjustedvehicleprice = parseFloat(this.state.purchasepricevalue) - (parseFloat(this.state.tradeinvalue) || 0) + (parseFloat(this.state.tradeinpayoff) || 0);
//         //                const totalamounttobefinanced = adjustedvehicleprice - (parseFloat(this.state.downpayment) || 0) + (parseFloat(this.state.insurance) || 0) + (parseFloat(this.state.otherfees) || 0) + (parseFloat(this.state.salestax) || 0);
//         //                // alert(totalamounttobefinanced);
//         const adjustedvehicleprice =
//             parseFloat(this.state.purchasepricevalue.replace(/,/g, "")) -
//             (parseFloat(this.state.tradeinvalue.replace(/,/g, "")) || 0) +
//             (parseFloat(this.state.tradeinpayoff.replace(/,/g, "")) || 0);
//         const totalamounttobefinanced =
//             adjustedvehicleprice -
//             (parseFloat(this.state.downpayment.replace(/,/g, "")) || 0) +
//             (parseFloat(this.state.insurance.replace(/,/g, "")) || 0) +
//             (parseFloat(this.state.otherfees.replace(/,/g, "")) || 0) +
//             (parseFloat(this.state.salestax) || 0);

//         this.setState({ totalamountfinanced: totalamounttobefinanced });

//         const calculateMonthlyPayment = (
//             totalLoanAmount,
//             termMonths,
//             pifRate,
//             endBalance
//         ) => {
//             var loanAmount = totalLoanAmount;
//             var endBalance = endBalance;
//             var monthlyInterestRate = pifRate;
//             var termMonths = termMonths;
//             let monthlyPayment = 0;
//             let epsilon = 0.01; // Tolerance for convergence
//             let maxIterations = 1000; // Maximum number of iterations

//             for (let i = 0; i < maxIterations; i++) {
//                 let remainingBalance = loanAmount;

//                 // Calculate remaining balance using the current monthly payment
//                 for (let j = 0; j < termMonths; j++) {
//                     let interest = remainingBalance * monthlyInterestRate;
//                     let principal = monthlyPayment - interest;
//                     remainingBalance -= principal;
//                 }

//                 // Check if the remaining balance is close enough to the end balance
//                 if (Math.abs(remainingBalance - endBalance) < epsilon) {
//                     break; // Stop iteration if close enough
//                 }

//                 // Adjust monthly payment based on the difference between calculated remaining balance and end balance
//                 monthlyPayment += (remainingBalance - endBalance) / termMonths;
//             }

//             return monthlyPayment;
//         };

//         // Terms for calculation
//         const terms = [12, 24, 36, 48, 60, 72, 84];
//         const conventionalRate = this.state.conventionalrate / 100 / 12;
//         const pifRate2 = this.state.pifrate / 100 / 12;
//         const estimatervcArray = updatedArray1;
//         let pifMonthlyPayments = [];
//         let conventionalMonthlyPayments = [];

//         // estimatervcArray.forEach(estimatervcValue => {
//         terms.forEach((term, index) => {
//             // // Calculate monthly payment for PIF rate
//             console.log(this.state.totalamountfinanced);
//             console.log(estimatervcArray[index]);
//             console.log("raju");
//             //var toalamountfinceddata = this.state.totalamountfinanced - estimatervcArray[index]
//             var toalamountfinceddata = totalamounttobefinanced;

//             const pifMonthlyPayment = calculateMonthlyPayment(
//                 toalamountfinceddata,
//                 term,
//                 pifRate2,
//                 estimatervcArray[index]
//             );
//             pifMonthlyPayments.push(pifMonthlyPayment);
//         });
//         //});
//         this.setState({
//             monthlypayment: pifMonthlyPayments,
//         });
//     };

    // error messages
    errorMessages = () => {
        // you can add alert or console.log or any thing you want
        // alert("Please fill in the required fields");
    };

    handleNextClick = () => {
        this.setState({ quoteStatus: true });
        this.setState({ printStatus: false });
        this.setState({ showPopup: false });
        this.setState({ showPopup1: false });
const customerId = localStorage.getItem('customer_id');
const currentTimestamp = Date.now();
const currentDate = new Date(currentTimestamp);
const formattedTime = currentDate.getHours().toString().padStart(2, '0') +
                      currentDate.getMinutes().toString().padStart(2, '0') +
                      currentDate.getSeconds().toString().padStart(2, '0');
const uniqueFormId = customerId + currentTimestamp + formattedTime;


this.setState({ uniqueformid: uniqueFormId });


        this.setState({ showcustomerPopup: true });
    };

    handlePrintClick = () => {
        this.setState({ printStatus: true });
        this.setState({ quoteStatus: false });
        this.setState({ showPopup: false });
        this.setState({ showPopup1: false });
        
const customerId = localStorage.getItem('customer_id');
const currentTimestamp = Date.now();
const currentDate = new Date(currentTimestamp);
const formattedTime = currentDate.getHours().toString().padStart(2, '0') +
                      currentDate.getMinutes().toString().padStart(2, '0') +
                      currentDate.getSeconds().toString().padStart(2, '0');
const uniqueFormId = customerId + currentTimestamp + formattedTime;


this.setState({ uniqueformid: uniqueFormId });
        this.setState({ showcustomerPopup: true });



    };

    handleNextClick1 = (data) => {


// console.log(this.state.name);
// alert(typeof this.state.name);
// alert(this.state.name);
//         alert(this.state.name.length);
        //this.setState({ showPopup: false});
        if (this.state.name.length == 0 || this.state.name.every(item => item === '')) {
            Swal({
                text: "Please enter name.",
                icon: "error",
                button: "OK",
            });
            return false;
        }
        if (this.state.email.length == 0 || this.state.email.every(item => item === '')) {
            Swal({
                text: "Please enter email.",
                icon: "error",
                button: "OK",
            });
            return false;
        }

        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if (reg.test(this.state.email) === false) {
            Swal({
                text: "Please enter correct email.",
                icon: "error",
                button: "OK",
            });
            return false;
        }

        if (this.state.phone.length == 0 ||  this.state.phone.every(item => item === '')) 

        {
            Swal({
                text: "Please enter phone.",
                icon: "error",
                button: "OK",
            });
            return false;
        }
        const phoneNumberLength = this.state.phone[0].length; // Get the length of the phone number string
        console.log("Length of phone number:", phoneNumberLength);
        console.log("this.state.phone.length");
        if (parseInt(phoneNumberLength) !== 12) {
            Swal({
                text: "Please enter a 10-digit phone number.",
                icon: "error",
                button: "OK",
            });
            return false;
        }

        if (this.state.address.length == 0 || this.state.address.every(item => item === '')) {
            Swal({
                text: "Please enter address.",
                icon: "error",
                button: "OK",
            });
            return false;
        }

        if (this.state.city.length == 0 || this.state.city.every(item => item === '')) {
            Swal({
                text: "Please enter city.",
                icon: "error",
                button: "OK",
            });
            return false;
        }
        if (this.state.state.length == 0 || this.state.state.every(item => item === '')) {
            Swal({
                text: "Please enter state.",
                icon: "error",
                button: "OK",
            });
            return false;
        }
        if (this.state.zip.length == 0 || this.state.zip.every(item => item === '')) {
            Swal({
                text: "Please enter zip.",
                icon: "error",
                button: "OK",
            });
            return false;
        }

        // alert(this.state.coapplicantmember);
        // alert(this.state.name1.length == 0);
        // alert(this.state.name1.every(item => item === ''));
        if (this.state.coapplicantmember == true && (this.state.name1.length == 0  ||  this.state.name1.every(item => item === ''))) 
        {
            Swal({
                text: "Please enter co applicant name.",
                icon: "error",
                button: "OK",
            });
            return false;
        }

        if (this.state.coapplicantmember == true && (this.state.email1.length == 0 ||  this.state.email1.every(item => item === ''))) {
            Swal({
                text: "Please enter co applicant email.",
                icon: "error",
                button: "OK",
            });
            return false;
        }
     let reg1 = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if (this.state.coapplicantmember == true &&    reg1.test(this.state.email1) === false) {
             Swal({
                text: "Please enter correct co applicant email.",
                icon: "error",
                button: "OK",
            });
            return false;
        }
        if (this.state.coapplicantmember == true && (this.state.phone1.length == 0 ||  this.state.phone1.every(item => item === ''))) {
            Swal({
                text: "Please enter co applicant phone.",
                icon: "error",
                button: "OK",
            });
            return false;
        }
        const phone1NumberLength =    this.state.phone1[0] && this.state.phone1[0].length; // Get the length of the phone number string
        if (this.state.coapplicantmember == true && parseInt(phone1NumberLength) !== 12) {
            Swal({
                text: "Please enter a 10-digit phone number.",
                icon: "error",
                button: "OK",
            });
            return false;
        }

        if (
            this.state.coapplicantmember == true &&
           

(this.state.address1.length == 0 ||  this.state.address1.every(item => item === ''))

        ) {
            Swal({
                text: "Please enter co applicant address.",
                icon: "error",
                button: "OK",
            });
            return false;
        }

        if (this.state.coapplicantmember == true && (this.state.city1.length == 0 ||  this.state.city1.every(item => item === ''))) {
            Swal({
                text: "Please enter co applicant city.",
                icon: "error",
                button: "OK",
            });
            return false;
        }

        if (this.state.coapplicantmember == true && (this.state.state1.length == 0 ||  this.state.state1.every(item => item === ''))) {
            Swal({
                text: "Please enter co applicant state.",
                icon: "error",
                button: "OK",
            });
            return false;
        }
        if (this.state.coapplicantmember == true && (this.state.zip1.length == 0 ||  this.state.zip1.every(item => item === ''))) {
            Swal({
                text: "Please enter co applicant zip.",
                icon: "error",
                button: "OK",
            });
            return false;
        }
        const profitAmount1 = parseFloat(localStorage.getItem("profitAmount"));
        const cprofitAmount1 = parseFloat(localStorage.getItem("cprofitAmount"));
        // Access the state value and parse it to a float
        // const profitAmountPercentage1 = parseFloat(this.state.profitAmountPercentage);
        const profitAmountPercentage1 = profitAmount1 - cprofitAmount1;
        console.log("calculation for ineterest pif" + profitAmount1);
        console.log("calculation for ineterest co" + cprofitAmount1);
        console.log("profitAmountPercentage1");
        console.log(profitAmountPercentage1);
        localStorage.setItem("tprofitAmount", profitAmountPercentage1);
        const clientData1 = new URLSearchParams({
            cu_name:
                localStorage.getItem('CustomerType') === 'None' ? "Century Federal" :
                                                 localStorage.getItem('CustomerType') === 'Dealer' ? localStorage.getItem('selectedBank') : 
                                                 localStorage.getItem('CustomerType') === 'Credit Union' ? localStorage.getItem('CompanyName') :
                                                 "Century Federal",
           
                cu_id:
            localStorage.getItem('CustomerType') === 'Dealer' ?
                (this.state.selectedBankId != undefined && this.state.selectedBankId != ""
                    ? this.state.selectedBankId:localStorage.getItem('UserID'))
                    :localStorage.getItem('CustomerType') === 'Credit Union' ? localStorage.getItem('DealerId')
                    :localStorage.getItem('CustomerType') === 'None'? 4
                    : localStorage.getItem('DealerId'),
            dealer_id: localStorage.getItem("customer_id"),
            auth_token: localStorage.getItem("auth_token"),
            customer_id: "",
            profit_amount: profitAmountPercentage1,
            profit_amount_percentage: this.state.profitAmountPercentage,
            car_name: this.state.carname,
            model: this.state.model,
            year: this.state.year,
            msrp_value: this.state.msrpvalue,
            msrp_percentage: "",
            residual_value: localStorage.getItem("residual_value"),
            purchasepricevalue: this.state.purchasepricevalue,
            trade_in_value: this.state.tradeinvalue,
            trade_in_payoff: this.state.tradeinpayoff,
            downpayments: this.state.downpayment,
            insurance: this.state.insurance,
            salestax: this.state.salestax,
            terms: this.state.selectedTerms,
            total_financed_amount: this.state.totalamountfinanced
                ? parseFloat(this.state.totalamountfinanced).toFixed(2)
                : "",



            conventional_rate: this.state.mycustomcu,
            pif_rate: this.state.mycustomfu,
            notes: this.state.notes,
            all_other_fees: this.state.otherfees,
            quote_status: this.state.dealerStatus
                ? 2
                : this.state.quoteStatus == true
                ? 1
                : 0,
            vin_number: localStorage.getItem("vinno"),
            rvi_segment: localStorage.getItem("rvisegment"),
            per_month_pif_amount:
                this.state.monthlypayment[this.state.selectedIndex] !== undefined &&
                this.state.monthlypayment[this.state.selectedIndex] > 0
                    ? `${this.state.monthlypayment[this.state.selectedIndex].toFixed(2)}`
                    : "",
            per_month_conventioal_amount:
                this.state.monthlypaymentc[this.state.selectedIndex] !== undefined &&
                this.state.monthlypaymentc[this.state.selectedIndex] !== 0
                    ? `${this.state.monthlypaymentc[this.state.selectedIndex].toFixed(2)}`
                    : "",
        });

        if (this.state.name[0]) {
            clientData1.append("name[0]", this.state.name[0]);
        }
        if (this.state.email[0]) {
            clientData1.append("email[0]", this.state.email[0]);
        }
        // if (this.state.phone[0]) {
        //         clientData1.append('phone[0]', this.state.phone[0] );

        // }

        if (this.state.phone[0]) {
            clientData1.append(
                "phone[0]",
                this.state.phone[0] != undefined
                    ? this.state.phone[0]
                            .replace(/\D/g, "")
                            .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
                    : ""
            );
        }

        if (this.state.address[0]) {
            clientData1.append("address[0]", this.state.address[0]);
        }

        if (this.state.address2[0]) {
            clientData1.append("address2[0]", this.state.address2[0]);
        }

        if (this.state.city[0]) {
            clientData1.append("city[0]", this.state.city[0]);
        }

        if (this.state.state[0]) {
            clientData1.append("state[0]", this.state.state[0]);
        }

        if (this.state.zip[0]) {
            clientData1.append("zip[0]", this.state.zip[0]);
        }

        if (this.state.name1[0]) {
            clientData1.append("name[1]", this.state.name1[0]);
        }
        if (this.state.email1[0]) {
            clientData1.append("email[1]", this.state.email1[0]);
        }
        if (this.state.phone1[0]) {
            // clientData1.append('phone[1]', this.state.phone1[0]);

            clientData1.append(
                "phone[1]",
                this.state.phone1[0] != undefined
                    ? this.state.phone1[0]
                            .replace(/\D/g, "")
                            .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
                    : ""
            );
        }
        if (this.state.address1[0]) {
            clientData1.append("address[1]", this.state.address1[0]);
        }

        if (this.state.city1[0]) {
            clientData1.append("city[1]", this.state.city1[0]);
        }
        if (this.state.state1[0]) {
            clientData1.append("state[1]", this.state.state1[0]);
        }
        if (this.state.zip1[0]) {
            clientData1.append("zip[1]", this.state.zip1[0]);
        }
        if (this.state.address2[0]) {
            clientData1.append("address2[1]", this.state.address2[0]);
        }
 this.setState({ loading: true });
    this.setState({ showcustomerPopup: false });

      if (this.state.uniqueformid) {
            clientData1.append("uniqueformid", this.state.uniqueformid);
        }
        axios
            .post(
                `${process.env.REACT_APP_BASE_URL}/authenticationAPI/InsertAcceptQuote`,
                clientData1
            )
            .then((res) => {
                                 //                 setTimeout(() => {
                                 //                 this.setState({ showcustomerPopup: false });
                                 // }, 5000);
                 // setTimeout(() => {
                        this.setState({ loading: false });
                    // }, 3000);

                if (res.data.Status) {
                    const currentDate = new Date();
                    const year = currentDate.getFullYear();
                    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Adding 1 because months are zero-based
                    const day = String(currentDate.getDate()).padStart(2, "0");
                    const formattedDate = `${month}-${day}-${year}`;
                    const futureDate = new Date(currentDate);
                    futureDate.setMonth(
                        currentDate.getMonth() + parseInt(this.state.selectedTerms)
                    ); // Add 84 months (7 years) to the current date
                    // Get the year, month, and day of the future date
                    const futureYear = futureDate.getFullYear();
                    const futureMonth = String(futureDate.getMonth() + 1).padStart(
                        2,
                        "0"
                    );
                    const futureDay = String(futureDate.getDate()).padStart(2, "0");
                    const formattedFutureDate = `${futureMonth}-${futureDay}-${futureYear}`;
                    const tafd =
                        this.state.totalamountfinanced !== undefined &&
                        this.state.totalamountfinanced > 0
                            ? (() => {
                                    const numbersAndDecimaltf = this.state.totalamountfinanced
                                        .toString()
                                        .replace(/[^0-9.]/g, ""); // Keep only digits and dot
                                    const sectionstf = numbersAndDecimaltf.split("."); // Split at the dot, if present
                                    const integerParttf = sectionstf[0].replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                    ); // Add commas for thousand separators
                                    const decimalParttf = sectionstf[1]
                                        ? sectionstf[1].substring(0, 2)
                                        : ""; // Allow up to 4 decimal places
                                    const formattedValuetf =
                                        sectionstf.length === 2
                                            ? `${integerParttf}.${decimalParttf}`
                                            : integerParttf;
                                    return `$${formattedValuetf}`;
                                })()
                            : "";
                    const n1 =
                        this.state.name1[0] == undefined ? "" : this.state.name1[0];
                    const ad1 =
                        this.state.address1[0] == undefined ? "" : this.state.address1[0];
                    const ad2 =
                        this.state.address21[0] == undefined ? "" : this.state.address21[0];
                    const cti =
                        this.state.city1[0] == undefined ? "" : this.state.city1[0];
                    const st1 =
                        this.state.state1[0] == undefined ? "" : this.state.state1[0];
                    const zi1 = this.state.zip1[0] == undefined ? "" : this.state.zip1[0];

                    const ad =
                        this.state.address[0] == undefined ? "" : this.state.address[0];
                    const ad12 =
                        this.state.address2[0] == undefined ? "" : this.state.address2[0];

                    const monthpifamo =
                        this.state.monthlypayment[this.state.selectedIndex] !== undefined &&
                        this.state.monthlypayment[this.state.selectedIndex] > 0
                            ? (() => {
                                    const numbersAndDecimal = this.state.monthlypayment[
                                        this.state.selectedIndex
                                    ]
                                        .toString()
                                        .replace(/[^0-9.]/g, ""); // Keep only digits and dot
                                    const sections = numbersAndDecimal.split("."); // Split at the dot, if present
                                    const integerPart = sections[0].replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                    ); // Add commas for thousand separators
                                    const decimalPart = sections[1]
                                        ? sections[1].substring(0, 2)
                                        : ""; // Allow up to 4 decimal places
                                    const formattedValue =
                                        sections.length === 2
                                            ? `${integerPart}.${decimalPart}`
                                            : integerPart;
                                    return `$${formattedValue}`;
                                })()
                            : "";

                    const tafdr =
                        localStorage.getItem("residual_value") !== undefined &&
                        localStorage.getItem("residual_value") > 0
                            ? (() => {
                                    const numbersAndDecimaltfr = localStorage
                                        .getItem("residual_value")
                                        .toString()
                                        .replace(/[^0-9.]/g, ""); // Keep only digits and dot
                                    const sectionstfr = numbersAndDecimaltfr.split("."); // Split at the dot, if present
                                    const integerParttfr = sectionstfr[0].replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                    ); // Add commas for thousand separators
                                    const decimalParttfr = sectionstfr[1]
                                        ? sectionstfr[1].substring(0, 2)
                                        : ""; // Allow up to 4 decimal places
                                    const formattedValuetfr =
                                        sectionstfr.length === 2
                                            ? `${integerParttfr}.${decimalParttfr}`
                                            : integerParttfr;
                                    return `$${formattedValuetfr}`;
                                })()
                            : "";






                    this.addAgreementInfo2(
                        this.state.name[0],
                        ad,
                        ad12,
                        this.state.city[0],
                        this.state.state[0],
                        this.state.zip[0],
                        n1,
                        ad1,
                        ad2,
                        cti,
                        st1,
                        zi1,
                        
localStorage.getItem('CustomerType') === 'None' ? this.state.cunamedefaultsuperadmin :
                                                 localStorage.getItem('CustomerType') === 'Dealer' ? localStorage.getItem('selectedBank') : 
                                                 localStorage.getItem('CustomerType') === 'Credit Union' ? localStorage.getItem('CompanyName') :
                                                 this.state.cunamedefaultsuperadmin,

                        localStorage.getItem('CustomerType') === 'None' ? this.state.cuaddressdefaultsuperadmin :
                                                 localStorage.getItem('CustomerType') === 'Dealer' ? localStorage.getItem('BankAddress') : 
                                                 localStorage.getItem('CustomerType') === 'Credit Union' ? localStorage.getItem('BankAddress') :
                                                 this.state.cuaddressdefaultsuperadmin,

          localStorage.getItem('CustomerType') === 'None' ? this.state.cuaddress2defaultsuperadmin :
                                                 localStorage.getItem('CustomerType') === 'Dealer' ? localStorage.getItem('BankAddress2') : 
                                                 localStorage.getItem('CustomerType') === 'Credit Union' ? localStorage.getItem('BankAddress2') :
                                                 this.state.cuaddress2defaultsuperadmin,


                                                           localStorage.getItem('CustomerType') === 'None' ? this.state.cucitydefaultsuperadmin :
                                                 localStorage.getItem('CustomerType') === 'Dealer' ? localStorage.getItem('BankCity') : 
                                                 localStorage.getItem('CustomerType') === 'Credit Union' ? localStorage.getItem('BankCity') :
                                                 this.state.cucitydefaultsuperadmin,

                                                           localStorage.getItem('CustomerType') === 'None' ? this.state.custatedefaultsuperadmin :
                                                 localStorage.getItem('CustomerType') === 'Dealer' ? localStorage.getItem('BankState') : 
                                                 localStorage.getItem('CustomerType') === 'Credit Union' ? localStorage.getItem('BankState') :
                                                 this.state.custatedefaultsuperadmin,

                                                           localStorage.getItem('CustomerType') === 'None' ? this.state.cuzipdefaultsuperadmin :
                                                 localStorage.getItem('CustomerType') === 'Dealer' ? localStorage.getItem('BankZip') : 
                                                 localStorage.getItem('CustomerType') === 'Credit Union' ? localStorage.getItem('BankZip') :
                                                 this.state.cuzipdefaultsuperadmin,


                        res.data.QuoteID,
                        this.state.year,
                        this.state.carname,
                        this.state.model,
                        localStorage.getItem("rvisegment"),
                        localStorage.getItem("vinno"),
                        this.state.selectedTerms,
                        formattedDate,
                        tafd,
                        formattedFutureDate,
                        tafdr,
                        "",
                        "",
                        this.state.mycustomfu,
                        monthpifamo,
                        data,
                        this.state.email[0]
                    );

                    this.setState({ purchasepricevalue: "" });
                    this.setState({ tradeinvalue: "" });
                    this.setState({ tradeinpayoff: "" });
                    this.setState({ downpayment: "" });
                    this.setState({ salestax: "" });
                    this.setState({ insurance: "" });
                    this.setState({ otherfees: "" });
                    this.setState({ totalamountfinanced: 0 });
                    this.setState({ pifrate: 6.5 });
                    this.setState({ conventionalrate: 6 });
                    this.setState({ notes: "" });
                    this.setState({ salestaxpercentage: "" });

                    this.setState({ coapplicantmember: false });
                    this.setState({ name: [] });
                    this.setState({ email: [] });
                    this.setState({ phone: [] });
                    this.setState({ address: [] });
                    this.setState({ name1: [] });
                    this.setState({ email1: [] });
                    this.setState({ phone1: [] });
                    this.setState({ address1: [] });

                    this.setState({ address2: [] });
                    this.setState({ city: [] });
                    this.setState({ state: [] });
                    this.setState({ zip: [] });

                    this.setState({ address21: [] });
                    this.setState({ city1: [] });
                    this.setState({ state1: [] });
                    this.setState({ zip1: [] });

                    localStorage.removeItem("profitAmount");
                    localStorage.removeItem("vinno");
                    localStorage.removeItem("rvisegment");
                    localStorage.removeItem("residual_value");

                    localStorage.removeItem("signedattach");
                    this.setState({ showcustomerPopup: false });

                    this.setState({ showAcceptMessage: true });

                    //Revert back to the original state after 5 seconds
                    setTimeout(() => {
                        this.setState({ showAcceptMessage: false });
                    }, 5000);

if(data == "normal")
{
 
                    Swal({
                        text:
                            res.data.Message + " Here is your quote id:-" + res.data.QuoteID,
                        icon: "success"
                    }).then(() => {
                        window.location.reload();
                    });
                     

}

                }



                // alert(res.data.Message);
                else
                    Swal({
                        text: res.data.Message,
                        icon: "error",
                        button: "OK",
                    }).then(() => {
                        window.location.reload();
                    });
            })
            .catch((error) => {
                console.error("Error posting data:", error);
                Swal({
                    text: `Error: ${error.message}`,
                    icon: "error",
                    button: "OK",
                }).then(() => {
                    window.location.reload();
                });
            });
    };

    handleNextClick2 = () => {


   if (this.state.requestcouponCounter > 0) {
            Swal({
                text: "Please wait, processing your request.",
                icon: "info",
                button: "OK",
            });
            return;
        }

        if (this.state.name.length == 0 || this.state.name.every(item => item === '')) {
            Swal({
                text: "Please enter name.",
                icon: "error",
                button: "OK",
            });
            return false;
        }
        if (this.state.email.length == 0 || this.state.email.every(item => item === '')) {
            // alert('Please enter email.');
            Swal({
                text: "Please enter email.",
                icon: "error",
                button: "OK",
            });
            return false;
        }
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if (reg.test(this.state.email) === false) {
            // alert('Please enter correct email.');
            Swal({
                text: "Please enter correct email.",
                icon: "error",
                button: "OK",
            });
            return false;
        }
        if (this.state.phone.length == 0 || this.state.phone.every(item => item === '')) {
            //alert('Please enter surname.');
            Swal({
                text: "Please enter phone.",
                icon: "error",
                button: "OK",
            });
            return false;
        }
        console.log(this.state.phone);
        const phoneNumberLength = this.state.phone[0].length; // Get the length of the phone number string
        console.log("Length of phone number:", phoneNumberLength);
        console.log("this.state.phone.length");
        console.log(this.state.phone.length);
        if (parseInt(phoneNumberLength) > 12) 
        {
            //alert('Please enter surname.');
            Swal({
                text: "Please enter a 10-digit phone number.",
                icon: "error",
                button: "OK",
            });
            return false;
        }
        if (this.state.address.length == 0 || this.state.address.every(item => item === '')) {
            //alert('Please enter surname.');
            Swal({
                text: "Please enter address.",
                icon: "error",
                button: "OK",
            });
            return false;
        }
        if (this.state.city.length == 0 || this.state.city.every(item => item === '')) {
            //alert('Please enter surname.');
            Swal({
                text: "Please enter city.",
                icon: "error",
                button: "OK",
            });
            return false;
        }

        if (this.state.state.length == 0 || this.state.state.every(item => item === '')) {
            //alert('Please enter surname.');
            Swal({
                text: "Please enter state.",
                icon: "error",
                button: "OK",
            });
            return false;
        }
        if (this.state.zip.length == 0 || this.state.zip.every(item => item === '')) {
            //alert('Please enter surname.');
            Swal({
                text: "Please enter zip.",
                icon: "error",
                button: "OK",
            });
            return false;
        }



console.log("this.state.branch.length");
console.log(this.state.branch.length);
console.log(this.state.branch);


        if (this.state.branch.length == 0  ||  Array.isArray(this.state.branch) && this.state.branch.every(item => item === '')) 
        {
            //alert('Please enter surname.');
            Swal({
                text: "Please enter branch.",
                icon: "error",
                button: "OK",
            });
            return false;
        }




        if (this.state.cu_referral.length == 0 || this.state.cu_referral.every(item => item === '')) {
            //alert('Please enter surname.');
            Swal({
                text: "Please enter cu referral.",
                icon: "error",
                button: "OK",
            });
            return false;
        }





// alert(this.state.requestcouponCounter);

        const profitAmount1 = parseFloat(localStorage.getItem("profitAmount"));
        const cprofitAmount1 = parseFloat(localStorage.getItem("cprofitAmount"));
        // Access the state value and parse it to a float
        // const profitAmountPercentage1 = parseFloat(this.state.profitAmountPercentage);
        const profitAmountPercentage1 = profitAmount1 - cprofitAmount1;
        console.log("profitAmountPercentage1");
        console.log(profitAmountPercentage1);
        const clientData1 = new URLSearchParams({
        cu_name:
                localStorage.getItem('CustomerType') === 'None' ? "Century Federal" :
                                                 localStorage.getItem('CustomerType') === 'Dealer' ? localStorage.getItem('selectedBank') : 
                                                 localStorage.getItem('CustomerType') === 'Credit Union' ? localStorage.getItem('CompanyName') :
                                                 "Century Federal",
            cu_id:
            localStorage.getItem('CustomerType') === 'Dealer' ?
                (this.state.selectedBankId != undefined && this.state.selectedBankId != ""
                    ? this.state.selectedBankId:localStorage.getItem('UserID'))
                    :localStorage.getItem('CustomerType') === 'Credit Union' ? localStorage.getItem('DealerId')
                    :localStorage.getItem('CustomerType') === 'None'? 4
                    : localStorage.getItem('DealerId')
                    ,

            auth_token: localStorage.getItem("auth_token"),
            dealer_id: localStorage.getItem("customer_id"),
            customer_id: "",
            profit_amount: profitAmountPercentage1,
            profit_amount_percentage: this.state.profitAmountPercentage,
            car_name: this.state.carname,
            model: this.state.model,
            year: this.state.year,
            msrp_value: this.state.msrpvalue,
            msrp_percentage: "",
            residual_value: localStorage.getItem("residual_value"),
            purchasepricevalue: this.state.purchasepricevalue,
            trade_in_value: this.state.tradeinvalue,
            trade_in_payoff: this.state.tradeinpayoff,
            downpayments: this.state.downpayment,
            insurance: this.state.insurance,
            salestax: this.state.salestax,
            terms: this.state.selectedTerms,
            total_financed_amount: this.state.totalamountfinanced
                ? parseFloat(this.state.totalamountfinanced).toFixed(2)
                : "",
            conventional_rate: this.state.mycustomcu,
            pif_rate: this.state.mycustomfu,
            notes: this.state.notes,
            all_other_fees: this.state.otherfees,
            quote_status: 0,
            vin_number: localStorage.getItem("vinno"),
            rvi_segment: localStorage.getItem("rvisegment"),
            per_month_pif_amount:
                this.state.monthlypayment[this.state.selectedIndex] !== undefined &&
                this.state.monthlypayment[this.state.selectedIndex] > 0
                    ? `${this.state.monthlypayment[this.state.selectedIndex].toFixed(2)}`
                    : "",
            per_month_conventioal_amount:
                this.state.monthlypaymentc[this.state.selectedIndex] !== undefined &&
                this.state.monthlypaymentc[this.state.selectedIndex] !== 0
                    ? `${this.state.monthlypaymentc[this.state.selectedIndex].toFixed(2)}`
                    : "",
        });
        if (this.state.name[0]) {
            clientData1.append("name[0]", this.state.name[0]);
        }
        if (this.state.email[0]) {
            clientData1.append("email[0]", this.state.email[0]);
        }
        if (this.state.phone[0]) {
            clientData1.append(
                "phone[0]",
                this.state.phone[0] != undefined
                    ? this.state.phone[0]
                            .replace(/\D/g, "")
                            .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
                    : ""
            );
        }
        if (this.state.address[0]) {
            clientData1.append("address[0]", this.state.address[0]);
        }
        if (this.state.address2[0]) {
            clientData1.append("address2[0]", this.state.address2[0]);
        }
        if (this.state.city[0]) {
            clientData1.append("city[0]", this.state.city[0]);
        }
        if (this.state.state[0]) {
            clientData1.append("state[0]", this.state.state[0]);
        }
        if (this.state.zip[0]) {
            clientData1.append("zip[0]", this.state.zip[0]);
        }
        if (this.state.name1[0]) {
            clientData1.append("name[1]", this.state.name1[0]);
        }
        if (this.state.email1[0]) {
            clientData1.append("email[1]", this.state.email1[0]);
        }
        if (this.state.phone1[0]) {
            //clientData1.append('phone[1]', this.state.phone1[0]);
            clientData1.append(
                "phone[1]",
                this.state.phone1[0] != undefined
                    ? this.state.phone1[0]
                            .replace(/\D/g, "")
                            .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
                    : ""
            );
        }
        if (this.state.address1[0]) {
            clientData1.append("address[1]", this.state.address1[0]);
        }
        if (this.state.address21[0]) {
            clientData1.append("address2[1]", this.state.address21[0]);
        }
        if (this.state.city1[0]) {
            clientData1.append("city[1]", this.state.city1[0]);
        }
        if (this.state.state1[0]) {
            clientData1.append("state[1]", this.state.state1[0]);
        }
        if (this.state.zip1[0]) {
            clientData1.append("zip[1]", this.state.zip1[0]);
        }


        if (this.state.uniqueformid) {
            clientData1.append("uniqueformid", this.state.uniqueformid);
        }
        var qutid;


        axios
            .post(
                `${process.env.REACT_APP_BASE_URL}/authenticationAPI/InsertAcceptQuote`,
                clientData1
            )
            .then((res) => {
                if (res.data.Status) 
                {
                    qutid = res.data.QuoteID;
                    console.log("Quote ID from first request:", qutid);
                    const couponData1 = {
                        user_member_id: parseInt(localStorage.getItem("customer_id")),
                        auth_token: parseInt(localStorage.getItem("auth_token")),
                        branch: this.state.branch,
                        cu_referral: this.state.cu_referral,
                        receive_status: parseInt(this.state.amazonStatus),
                        QuoteID: qutid,
                        pre_approved_status: parseInt(this.state.preApprovedStatus),
                    };

                    console.log("qutid");
                    console.log(qutid);
              
                    axios
                        .post(
                            `${process.env.REACT_APP_BASE_URL}/authenticationAPI/InsertCoupon`,

                            couponData1
                        )
                        .then((res) => {
                            if (res.data.status) 
                            {

                                            this.setState({showncouponbtn: false});

                                console.log("qutid");
                                console.log(res.data.quoteid);
                                console.log("qutidajay");
                                // console.log(res.data.receive_status);
                                var dt = new Date();
                                var currentformatdate =
                                    dt.getMonth() +
                                    1 +
                                    "-" +
                                    dt.getDate() +
                                    "-" +
                                    dt.getFullYear();

                                var pone =
                                    this.state.phone[0] != undefined
                                        ? this.state.phone[0]
                                                .replace(/\D/g, "")
                                                .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
                                        : "";
                                this.addAgreementInfo(
                                    "",
                                    "",
                                    "",
                                    "",
                                    "",
                                    "",
                                    "",
                                    "",
                                    parseInt(this.state.amazonStatus),
                                    parseInt(this.state.amazonCouponStatus),
                                    res.data.quoteid,
                                    this.state.name[0],
                                    this.state.email[0],
                                    pone,
                                    this.state.cu_referral,
                                    this.state.branch,
                                    parseInt(this.state.preApprovedStatus)
                                );
                            }
                        });
                }

            })


    };

    handleCancelClick = () => {
        this.setState({ quoteStatus: false });

        this.setState({ showPopup: false });
        this.setState({ showPopup1: false });

        this.setState({ showCancelMessage: true });

        // Revert back to the original state after 5 seconds
        setTimeout(() => {
            this.setState({ showCancelMessage: false });
            window.location.reload();
        }, 2000);
    };

    fetchData = (carName) => {
        var carName1 =
            carName && carName.includes("/") ? carName.replace(/\//g, "") : carName;

console.log("second check");
console.log(carName1);

        //var carName='2024 Acura MDX Type S wAdvance Package';

        let msrpPercentages = [];
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `${process.env.REACT_APP_BASE_URL}/authenticationAPI/GetVehiclePercentage/${carName1}`
                )
                .then((secondRes) => {
                    if (secondRes.data.length === 0) {
                        //console.log("Empty data response");
                        Swal({
                            text: "Data is not available for this vin",
                            icon: "error",
                            button: "OK",
                        }).then(() => {
                            window.location.reload();
                        });
                    } else {
                        console.log(secondRes.data);
                        console.log("new ajay testing here");

                        msrpPercentages.push(secondRes.data[0]["12MonthsMsrpPercentage"]);
                        msrpPercentages.push(secondRes.data[0]["24MonthsMsrpPercentage"]);
                        msrpPercentages.push(secondRes.data[0]["36MonthsMsrpPercentage"]);
                        msrpPercentages.push(secondRes.data[0]["48MonthsMsrpPercentage"]);
                        msrpPercentages.push(secondRes.data[0]["60MonthsMsrpPercentage"]);
                        msrpPercentages.push(secondRes.data[0]["72MonthsMsrpPercentage"]);
                        msrpPercentages.push(secondRes.data[0]["84MonthsMsrpPercentage"]);

                        // console.log(
                        //     "msrpPercentages inside Axios callback:",
                        //     msrpPercentages
                        // );
                        console.log("rvisegment");
                        localStorage.setItem("rvisegment", secondRes.data[0]["RviSegment"]);
                        console.log("carname" + secondRes.data[0]["carname"]);
                        console.log("year" + secondRes.data[0]["year"]);
                        console.log("model" + secondRes.data[0]["make"]);

                        // Resolve the promise with the updated msrpPercentages array
                        resolve(msrpPercentages);
                    }
                })
                .catch((error) => {
                    // console.error("Error in second request:", error);
                    // Reject the promise with the error
                    reject(error);

                    Swal({
                        text: error,
                        icon: "error",
                        button: "OK",
                    }).then(() => {
                        window.location.reload();
                    });
                });
        });
    };

    handleOverrideCheckboxChange = () => {
        // alert("djdj");
        const observer = new MutationObserver((mutationsList, observer) => {
            const accordionPanel = document.querySelector(
                ".accordion-collapse.collapse"
            );
            if (accordionPanel) {
                // Execute your code here when the class is found
                accordionPanel.classList.remove("collapse");
                observer.disconnect(); // Disconnect the observer after executing the code
            }
        });

        // Start observing changes to the target node
        observer.observe(document.body, { childList: true, subtree: true });

        // alert(this.state.isvinChecked);
        this.setState({ isvinChecked: !this.state.isvinChecked });

        // alert(this.state.isvinChecked);

        if (this.state.isvinChecked == true) {
            this.setState({ vinno: "" });
        }

        if (this.state.isvinChecked == false) {
            //this.setState({ vinno: '1HGCY1F34RA034366 '});

            this.setState({ vinno: "1HGCY1F34RA034366" }, () => {
                // alert("hello");

                this.setState({ loading: true });

                this.setState({ msrpvalue: "" });
                this.setState({ model: "" });
                this.setState({ carname: "" });
                this.setState({ year: "" });
                this.setState({ pdf_url: "" });
                this.setState({ purchasepricevalue: "" });
                this.setState({ tradeinvalue: "" });
                this.setState({ tradeinpayoff: "" });
                this.setState({ downpayment: "" });
                this.setState({ insurance: "" });
                this.setState({ otherfees: "" });
                this.setState({ salestax: "" });

                axios
                    .get(process.env.REACT_APP_EXTERNAL_URL + `/1HGCY1F34RA034366`)
                    .then((res) => {
                        localStorage.setItem("vinno", "1HGCY1F34RA034366");
                        this.setState({ loading: false });

                        // alert(res.data.status);
                        if (res.data.status == false) {
                            Swal({
                                text: "Data is not available for this vin",
                                icon: "error",
                                button: "OK",
                            }).then(() => {
                                window.location.reload();
                            });
                            return false;
                        }

                        console.log(res);
                        const responseData = res.data;

                        // Extract the vehicle name from the response data
                        const vehicleName = responseData.vehicle_name;



                        const msrpValue = responseData.price;
                        this.setState({ msrpvalue: msrpValue });
                        const carArray = vehicleName.split(" ");

    
    // Check if there are enough parts for make and model

                        const yeardata = parseInt(carArray[0]);
                        // Get the current year
                        const currentYear = new Date().getFullYear();

                        if (currentYear - yeardata > 8) {
                            console.log("Vehicle is 8 years old or older.");

                            Swal({
                                text: "Pay it forward loans only support cars that are 8 years old or newer. Please submit new Vehicle details.",
                                icon: "error",
                                button: "OK",
                            }).then(() => {
                                window.location.reload();
                            });

                            return false;
                        }

console.log("third check");
console.log(carArray[2]);
console.log(carArray[1]+" ");
console.log(carArray[2]);
console.log(carArray[0]);


    if (carArray.length ==3) 
{
       
                        this.setState({ model: carArray[2] });

                        this.setState({ carname: carArray[1] });
                        this.setState({ year: carArray[0] });



    }

else
{
  this.setState({ model: carArray[3] });
                        this.setState({ carname: carArray[1] + " " + carArray[2] });
                        this.setState({ year: carArray[0] });

}

                      




                        this.setState({
                            pdf_url:
                                process.env.REACT_APP_EXTERNAL_URL + responseData.img_url,
                        });

                        const numericValue = parseFloat(
                            msrpValue.replace(/\$|,/g, "")
                        ).toFixed(2);

                        var carName = vehicleName;

                        //let msrpPercentages = [];

                        // Call the fetchData function and handle the promise
                        var msrp = "";
                        this.fetchData(carName).then((data) => {
                            // Access the updated msrpPercentages array here

                            var msrp = numericValue;
                            //var msrppercentage=msrpPercentages;
                            var msrppercentage = data.map((value) => value / 2);

                            var msrppercentage1 = msrppercentage.map((value) => value / 4);
                            //var msrppercentage1=msrpPercentages.map(value => value / 2);
                            console.log("msrppercentage");
                            console.log(msrppercentage);
                            console.log("msrppercentage1");

                            console.log("msrppercentage1lala");
                            console.log(msrppercentage1);

                            this.setState({
                                msrppercentagedata: msrppercentage1,
                            });

                            var estimatedresidualvalue1 = [];
                            // Calculate estimated residual value for each percentage
                            msrppercentage.forEach((percentage) => {
                                var estimaterv = (msrp * percentage) / 100;
                                estimatedresidualvalue1.push(estimaterv);
                            });

                            estimatedresidualvalue1 = estimatedresidualvalue1.map((value) =>
                                parseFloat(value.toFixed(2))
                            );

                            this.setState({
                                estimatedresidualvalue: estimatedresidualvalue1,
                            });
                            console.log(estimatedresidualvalue1);
                            localStorage.setItem(
                                "residual_value",
                                estimatedresidualvalue1[6]
                            );

                            this.setState({
                                estimatedresidualvaluemyname: estimatedresidualvalue1,
                            });

                            for (let i = 0; i < estimatedresidualvalue1.length; i++) {
                                // Construct state key dynamically
                                const stateKey = `prevestimatedresidualvalue${(i + 1) * 12}`;

                                // Set state with the corresponding value
                                this.setState({
                                    [stateKey]: estimatedresidualvalue1[i],
                                });
                            }

                            const calculateMonthlyPaymentC = (
                                totalLoanAmount,
                                termMonths,
                                monthlyInterestRate
                            ) => {
                                return (
                                    (totalLoanAmount * monthlyInterestRate) /
                                    (1 - Math.pow(1 + monthlyInterestRate, -termMonths))
                                );
                            };
                            const calculateMonthlyPayment = (
                                totalLoanAmount,
                                termMonths,
                                pifRate,
                                endBalance
                            ) => {
                                var loanAmount = totalLoanAmount;
                                var endBalance = endBalance;
                                var monthlyInterestRate = pifRate;
                                var termMonths = termMonths;
                                let monthlyPayment = 0;
                                let epsilon = 0.01; // Tolerance for convergence
                                let maxIterations = 1000; // Maximum number of iterations

                                for (let i = 0; i < maxIterations; i++) {
                                    let remainingBalance = loanAmount;

                                    // Calculate remaining balance using the current monthly payment
                                    for (let j = 0; j < termMonths; j++) {
                                        let interest = remainingBalance * monthlyInterestRate;
                                        let principal = monthlyPayment - interest;
                                        remainingBalance -= principal;
                                    }

                                    // Check if the remaining balance is close enough to the end balance
                                    if (Math.abs(remainingBalance - endBalance) < epsilon) {
                                        break; // Stop iteration if close enough
                                    }

                                    // Adjust monthly payment based on the difference between calculated remaining balance and end balance
                                    monthlyPayment +=
                                        (remainingBalance - endBalance) / termMonths;
                                }
                                return monthlyPayment;
                            };

                            // Terms for calculation
                            const terms = [12, 24, 36, 48, 60, 72, 84];
                            const conventionalRate = this.state.conventionalrate / 100 / 12;
                            const pifRate2 = this.state.pifrate / 100 / 12;
                            const estimatervcArray = this.state.estimatedresidualvalue;
                            let pifMonthlyPayments = [];
                            let conventionalMonthlyPayments = [];
                            terms.forEach((term, index) => {
                                var toalamountfinceddata =
                                    this.state.totalamountfinanced.toFixed(2);
                                const pifMonthlyPayment = calculateMonthlyPayment(
                                    toalamountfinceddata,
                                    term,
                                    pifRate2,
                                    this.state.estimatedresidualvalue[index]
                                );
                                pifMonthlyPayments.push(pifMonthlyPayment);
                                // Calculate monthly payment for conventional rate
                                const conventionalMonthlyPayment = calculateMonthlyPaymentC(
                                    this.state.totalamountfinanced,
                                    term,
                                    conventionalRate
                                );
                                conventionalMonthlyPayments.push(conventionalMonthlyPayment);
                            });
                            this.setState({
                                monthlypayment: pifMonthlyPayments,
                                monthlypaymentc: conventionalMonthlyPayments,
                            });
                        });
                        //alert("catch not");
                    })
                    .catch((err) => {
                         alert("catch data");
                        console.log("catch data");
                        console.log(err);
                        this.setState({ loading: false });
                        // Handle error here
                        // alert("static1 data");
                        //            alert(this.state.vinno.trim());
                        if (this.state.vinno.trim() == "1HGCP2E71BA072012") {
                            this.responseCalcualtion(
                                "$25,075.00",
                                "2011 Honda Accord Sdn EX",
                                "/test.png"
                            );
                            console.error("Error:", err);
                        } else {
                            Swal({
                                text: "Sorry server is busy, Please try with override option.",
                                icon: "error",
                                button: "OK",
                            }).then(() => {
                                window.location.reload();
                            });
                            return false;
                        }

                        // alert("test");
                    });
            });
        }
    };

    // handleOverrideCheckboxChange = () => {
    //     // Alert the current state value before updating
    //     // alert(this.state.isvinChecked);

    //     // Toggle the isChecked state
    //     this.setState({ isvinChecked: !this.state.isvinChecked }, () => {
    //         // Callback function is called after state is updated
    //         // alert(this.state.isvinChecked);

    //         // Check if isvinChecked is true and update vinno accordingly
    //         if (this.state.isvinChecked) {
    //             this.setState({ vinno: '1HGCY1F34RA034366' });
    //         }
    //         else{
    //             this.setState({ vinno: '' });
    //         }
    //     });
    // }

    closePopup = () => {
        this.setState({ showPopup: false });

        this.setState({ name: [] });
        this.setState({ email: [] });
        this.setState({ address: [] });
        this.setState({ phone: [] });

        this.setState({ zip: [] });
        this.setState({ state: [] });
        this.setState({ city: [] });
        this.setState({ address2: [] });

        this.setState({ zip1: [] });
        this.setState({ state1: [] });
        this.setState({ city1: [] });
        this.setState({ address21: [] });

        this.setState({ name1: [] });
        this.setState({ email1: [] });
        this.setState({ address1: [] });
        this.setState({ phone1: [] });
        this.setState({ branch: "" });
        this.setState({ cu_referral: "" });
        this.setState({ quoteid: "" });
        this.setState({ suggestions: [] });
    };

    closePopup1 = () => {
        this.setState({ showPopup1: false });
        this.setState({ name: [] });
        this.setState({ email: [] });
        this.setState({ address: [] });
        this.setState({ phone: [] });
        this.setState({ zip: [] });
        this.setState({ state: [] });
        this.setState({ city: [] });
        this.setState({ address2: [] });

        this.setState({ zip1: [] });
        this.setState({ state1: [] });
        this.setState({ city1: [] });
        this.setState({ address21: [] });

        this.setState({ name1: [] });
        this.setState({ email1: [] });
        this.setState({ address1: [] });
        this.setState({ phone1: [] });
        this.setState({ branch: "" });
        this.setState({ cu_referral: "" });

        this.setState({ quoteid: "" });
        this.setState({ suggestions: [] });
    };

    toggleTable = () => {
        this.setState({ isExpanded: !this.state.isExpanded });
    };


  closecustomerPopup2 = () => {
        this.setState({ showcustomerPopup2: false });

    }


    closecustomerPopup = () => {
        this.setState({ showcustomerPopup: false });

        this.setState({ name: [] });
        this.setState({ email: [] });
        this.setState({ address: [] });
        this.setState({ zip: [] });
        this.setState({ state: [] });
        this.setState({ city: [] });
        this.setState({ address2: [] });

        this.setState({ zip1: [] });
        this.setState({ state1: [] });
        this.setState({ city1: [] });
        this.setState({ address21: [] });

        this.setState({ phone: [] });
        this.setState({ name1: [] });
        this.setState({ email1: [] });
        this.setState({ address1: [] });
        this.setState({ phone1: [] });
        this.setState({ branch: "" });
        this.setState({ cu_referral: "" });

        this.setState({ quoteid: "" });
        this.setState({ suggestions: [] });
    };

    handleTabChange = (nextTab) => {
        // alert("dhbd");
        const { currentTab, vinno } = this.state;

        if (nextTab > currentTab) {
            // Moving forward
            if (currentTab === 0 && !vinno) {
                // Check if Vin Info field is empty
                // Vin Info field is empty, prevent moving to the next tab
                alert("Please fill in the Vin Info field before proceeding.");
                return;
            }
        }
        // Proceed to the next tab
        this.setState({ currentTab: nextTab });
    };

    handleVinno = (vinnumber, buildvehiclesection) => {
        if (buildvehiclesection == true) {
            this.setState({ isvinChecked: false });
            if (this.state.isvinChecked == false) {
                this.setState({ vinno: "" });
            }
        }

        const observer = new MutationObserver((mutationsList, observer) => {
            const accordionPanel = document.querySelector(
                ".accordion-collapse.collapse"
            );
            if (accordionPanel) {
                // Execute your code here when the class is found
                accordionPanel.classList.remove("collapse");
                observer.disconnect(); // Disconnect the observer after executing the code
            }
        });

        // Start observing changes to the target node
        observer.observe(document.body, { childList: true, subtree: true });

        console.log("vinnumberbyfunctioncall");
        // console.log(e.target.value);

        var vindata = vinnumber;

        console.log(vindata);

        this.setState({ vinno: vindata }, () => {
            if (vindata.length == 17) {
                this.setState({ loading: true });
                this.setState({ msrpvalue: "" });
                this.setState({ model: "" });
                this.setState({ carname: "" });
                this.setState({ year: "" });
                this.setState({ pdf_url: "" });
                this.setState({ purchasepricevalue: "" });
                this.setState({ tradeinvalue: "" });
                this.setState({ tradeinpayoff: "" });
                this.setState({ downpayment: "" });
                this.setState({ insurance: "" });
                this.setState({ otherfees: "" });
                this.setState({ salestax: "" });

                axios
                    .get(process.env.REACT_APP_EXTERNAL_URL + `/` + vindata)
                    .then((res) => {
                        localStorage.setItem("vinno", vindata);
                        this.setState({ loading: false });

                        // alert(res.data.status)
                        if (res.data.status == false) {
                            Swal({
                                text: "Data is not available for this vin",
                                icon: "error",
                                button: "OK",
                            }).then(() => {
                                window.location.reload();
                            });
                            return false;
                        }

                        console.log(res);
                        const responseData = res.data;

                        // Extract the vehicle name from the response data
                        const msrpValue = responseData.price;
                        this.setState({ msrpvalue: msrpValue });
                        const vehicleName = responseData.vehicle_name;

                        const carArray = vehicleName.split(" ");

                        const yeardata = parseInt(carArray[0]);
                        // Get the current year
                        const currentYear = new Date().getFullYear();

                        if (currentYear - yeardata > 8) {
                            console.log("Vehicle is 8 years old or older.");
                            Swal({
                                text: "Pay it forward loans only support cars that are 8 years old or newer. Please submit new Vehicle details.",
                                icon: "error",
                                button: "OK",
                            }).then(() => {
                                window.location.reload();
                            });

                            return false;
                        }

                        // this.setState({ model: carArray[2] });
                        // this.setState({ carname: carArray[1] + " " + carArray[2] });
                        // this.setState({ year: carArray[0] });


    if (carArray.length ==3) 
{
       
                        this.setState({ model: carArray[2] });

                        this.setState({ carname: carArray[1] });
                        this.setState({ year: carArray[0] });



    }

else
{
  this.setState({ model: carArray[3] });
                        this.setState({ carname: carArray[1] + " " + carArray[2] });
                        this.setState({ year: carArray[0] });

}




                        this.setState({
                            pdf_url:
                                process.env.REACT_APP_EXTERNAL_URL + responseData.img_url,
                        });

                        const numericValue = parseFloat(
                            msrpValue.replace(/\$|,/g, "")
                        ).toFixed(2);

                        var carName = vehicleName;

                        //let msrpPercentages = [];

                        // Call the fetchData function and handle the promise
                        var msrp = "";
                        this.fetchData(carName).then((data) => {
                            // Access the updated msrpPercentages array here

                            var msrp = numericValue;
                            var msrppercentage = data.map((value) => value / 2);
                            // var msrppercentage1=[8,7,5,4,2,2,1];
                            var msrppercentage1 = msrppercentage.map((value) => value / 4);

                            console.log("msrppercentage");
                            console.log(msrppercentage);

                            console.log(msrppercentage1);
                            console.log("msrppercentage2");

                            this.setState({
                                msrppercentagedata: msrppercentage1,
                            });

                            var estimatedresidualvalue1 = [];
                            // Calculate estimated residual value for each percentage
                            msrppercentage.forEach((percentage) => {
                                var estimaterv = (msrp * percentage) / 100;
                                estimatedresidualvalue1.push(estimaterv);
                            });

                            estimatedresidualvalue1 = estimatedresidualvalue1.map((value) =>
                                parseFloat(value.toFixed(2))
                            );

                            this.setState({
                                estimatedresidualvalue: estimatedresidualvalue1,
                            });
                            console.log(estimatedresidualvalue1);
                            localStorage.setItem(
                                "residual_value",
                                estimatedresidualvalue1[6]
                            );

                            this.setState({
                                estimatedresidualvaluemyname: estimatedresidualvalue1,
                            });

                            for (let i = 0; i < estimatedresidualvalue1.length; i++) {
                                // Construct state key dynamically
                                const stateKey = `prevestimatedresidualvalue${(i + 1) * 12}`;

                                // Set state with the corresponding value
                                this.setState({
                                    [stateKey]: estimatedresidualvalue1[i],
                                });
                            }

                            const calculateMonthlyPaymentC = (
                                totalLoanAmount,
                                termMonths,
                                monthlyInterestRate
                            ) => {
                                return (
                                    (totalLoanAmount * monthlyInterestRate) /
                                    (1 - Math.pow(1 + monthlyInterestRate, -termMonths))
                                );
                            };
                            const calculateMonthlyPayment = (
                                totalLoanAmount,
                                termMonths,
                                pifRate,
                                endBalance
                            ) => {
                                var loanAmount = totalLoanAmount;
                                var endBalance = endBalance;
                                var monthlyInterestRate = pifRate;
                                var termMonths = termMonths;
                                let monthlyPayment = 0;
                                let epsilon = 0.01; // Tolerance for convergence
                                let maxIterations = 1000; // Maximum number of iterations

                                for (let i = 0; i < maxIterations; i++) {
                                    let remainingBalance = loanAmount;

                                    // Calculate remaining balance using the current monthly payment
                                    for (let j = 0; j < termMonths; j++) {
                                        let interest = remainingBalance * monthlyInterestRate;
                                        let principal = monthlyPayment - interest;
                                        remainingBalance -= principal;
                                    }

                                    // Check if the remaining balance is close enough to the end balance
                                    if (Math.abs(remainingBalance - endBalance) < epsilon) {
                                        break; // Stop iteration if close enough
                                    }

                                    // Adjust monthly payment based on the difference between calculated remaining balance and end balance
                                    monthlyPayment +=
                                        (remainingBalance - endBalance) / termMonths;
                                }

                                return monthlyPayment;
                            };

                            // Terms for calculation
                            const terms = [12, 24, 36, 48, 60, 72, 84];
                            const conventionalRate = this.state.conventionalrate / 100 / 12;
                            const pifRate2 = this.state.pifrate / 100 / 12;

                            //const estimatervcArray = this.state.estimatedresidualvalue;

                            const estimatervcArray = this.state.estimatedresidualvalue;

                            let pifMonthlyPayments = [];
                            let conventionalMonthlyPayments = [];

                            // estimatervcArray.forEach(estimatervcValue => {
                            terms.forEach((term, index) => {
                                // // Calculate monthly payment for PIF rate
                                //var toalamountfinceddata = this.state.totalamountfinanced.toFixed(2)- this.state.estimatedresidualvalue[index]
                                var toalamountfinceddata =
                                    this.state.totalamountfinanced.toFixed(2);

                                const pifMonthlyPayment = calculateMonthlyPayment(
                                    toalamountfinceddata,
                                    term,
                                    pifRate2,
                                    this.state.estimatedresidualvalue[index]
                                );
                                pifMonthlyPayments.push(pifMonthlyPayment);
                                // Calculate monthly payment for conventional rate
                                const conventionalMonthlyPayment = calculateMonthlyPaymentC(
                                    toalamountfinceddata,
                                    term,
                                    conventionalRate
                                );
                                conventionalMonthlyPayments.push(conventionalMonthlyPayment);
                            });
                            //});
                            this.setState({
                                monthlypayment: pifMonthlyPayments,
                                monthlypaymentc: conventionalMonthlyPayments,
                            });
                        });

                        // alert("static33 data");
                    })
                    .catch((err) => {
                        this.setState({ loading: false });
                        // Handle error here
                        // alert("static34 data");

                        if (this.state.vinno.trim() == "1HGCP2E71BA072012") {
                            this.responseCalcualtion(
                                "$25,075.00",
                                "2011 Honda Accord Sdn EX",
                                "/test.png"
                            );
                            console.error("Error:", err);
                        } else {
                            Swal({
                                text: "Sorry server is busy, Please try with override function.",
                                icon: "error",
                                button: "OK",
                            }).then(() => {
                                window.location.reload();
                            });
                            return false;
                        }
                    });

                if (buildvehiclesection == true) {
                    const { currentTab, totalTabs } = this.state;
                    // Logic to handle clicking the "next" button
                    // alert(currentTab);
                    // alert(totalTabs);
                    if (currentTab < totalTabs - 1) {
                        this.setState({ currentTab: currentTab + 1 });

                        //alert(this.state.currentTab);
                    }
                }
            }
        });
    };

    // Filter makes based on the selected year
    handleYearChange = (selectedYear) => {
        // setYear(selectedYear);

        this.setState({ loading: false });

        const { excelData } = this.state;

        const makes = excelData
            .filter((item) => item.Year == selectedYear)
            .map((item) => item.Make);

        // console.log("2024");
        // console.log(makes);
        // console.log(excelData);
        // console.log(selectedYear);

        this.setState({
            yearData: selectedYear,
            makeOptions: [...new Set(makes)],
            selectedMake: "",
        });
    };

    // Handle make selection
    handleMakeChange = (selectedMake) => {
        this.setState({ selectedMake });

        const { excelData, yearData } = this.state;
        const models = excelData
            .filter((item) => item.Make == selectedMake && item.Year == yearData)

            .map((item) => item.Model);

        this.setState({
            selectedMake: selectedMake,
            modelOptions: [...new Set(models)],
            selectedModel: "",
        });
    };

    handleModelChange = (selectedModel) => {
        this.setState({ selectedModel: selectedModel });
    };

    fetchDatabyparameter = async () => {
        this.setState({ loading: true });

        try {
            const response = await axios.post(
                process.env.REACT_APP_EXTERNAL_URL2,
                {
                    // makeCode: this.state.selectedMake,
                    // modelCode: this.state.selectedModel,
                    // endYear: this.state.yearData,
                    makeCode: this.state.selectedMake,
                    modelCode: this.state.selectedModel,
                    makeYear: this.state.yearData,
                    // startYear: "1982",
                    // singleResult: true
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            this.setState({ loading: false });

            return response;
        } catch (error) {
            console.log("error data");
            console.log(error);
            return error;
        } finally {
            this.setState({ loading: false });
        }
    };

    handleGetVin = async () => {
        this.setState({ getselectr: true });
        console.log("vin data");
        console.log(this.state.yearData);

        if (this.state.yearData == "") {
            Swal({
                text: "Please select Model Year",
                icon: "error",
                button: "OK",
            });
            return false;
        }

        if (this.state.selectedMake == "") {
            Swal({
                text: "Please select Make",
                icon: "error",
                button: "OK",
            });
            return false;
        }

        if (this.state.selectedMake == "") {
            Swal({
                text: "Please select Model",
                icon: "error",
                button: "OK",
            });
            return false;
        }

        console.log(this.state.selectedMake);
        console.log(this.state.selectedModel);

        const vindatabypara = await this.fetchDatabyparameter();
        console.log("s,mdbcbcbddfvdvdd vdvvh");

        console.log("dkjdvdvdvvgddvvdvdvdvdvvdvdvdvvdvvdvdv");
        console.log(vindatabypara);
        if (vindatabypara && vindatabypara) {
            console.log("hello");

            if (vindatabypara.status == 500) {
                Swal({
                    text: "Vin Number Does Not Exist Please Try Another",
                    icon: "error",
                    button: "OK",
                }).then(() => {
                    setTimeout(() => {
                        window.location.reload();
                    }, 5000); // Reload after 5000 milliseconds (5 seconds)
                });
                return false;
            }

            if (vindatabypara.status === 200) {
                console.log("vinfromapi---" + vindatabypara.data.vinNumber);

                console.log(typeof vindatabypara.data.vinNumber);

                if (
                    vindatabypara.data.vinNumber === undefined ||
                    vindatabypara.data.vinNumber === ""
                ) {
                    Swal({
                        text: "Vin Number Does Not Exist Please Try Another",
                        icon: "error",
                        button: "OK",
                    }).then(() => {
                        setTimeout(() => {
                            window.location.reload();
                        }, 5000); // Reload after 5000 milliseconds (5 seconds)
                    });

                    return false;
                }

                this.setState({ vinno: vindatabypara.data.vinNumber.toString() });

                // this.setState({ vinno:'2C4RC1BG4JR155057'});
                this.setState({ getselectr: false });
            } else {
                Swal({
                    text: "Vin Number Does not exist! Please try with another vin",
                    icon: "error",
                    button: "OK",
                });
                return false;

                this.setState({ getselectr: false });
            }
        }

        this.setState({ vpnbygetquote: true });

        const { excelData, yearData, selectedMake, selectedModel } = this.state;
        // const vins = excelData.filter(item =>
        //     item.Make == selectedMake &&
        //     item.Year == yearData &&
        //     item.Model == selectedModel
        // ).map(item => item.VIN);
        console.log("vin");
        // console.log(vins[0]);
        // var vinno= vins[0];

        // console.log('vinfromexcel---'+vinno);

        //this.setState({ vinno: vinno});

        var vinno = vindatabypara.data.vinNumber.toString();
        //var vinno='2C4RC1BG4JR155057';

        this.handleVinno(vinno, true);
    };

    formvalidation = (e) => {
        e.preventDefault();

        if (this.state.name == "") {
            alert("Please enter first name.");
            return false;
        }
        if (this.state.surname == "") {
            alert("Please enter surname.");
            return false;
        }

        if (this.state.email == "") {
            alert("Please enter email.");
            return false;
        }

        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if (reg.test(this.state.email) === false) {
            alert("Please enter correct email.");
            return false;
        }

        if (this.state.country == "") {
            alert("Please select country.");
            return false;
        }

        if (this.state.city == "") {
            alert("Please select city.");
            return false;
        }

        if (this.state.password == "") {
            alert("Please enter password.");
            return false;
        }

        if (this.state.cpassword == "") {
            alert("Please enter confirm password.");
            return false;
        }

        var Varnewsletter = 0;
        if (this.state.newsletter) {
            Varnewsletter = 1;
        }

        if (this.state.password != this.state.cpassword) {
            alert("Both passwords should be same.");
            return false;
        }

        if (this.state.signupvalidation == false) {
            alert("Please choose signup.");
            return false;
        }

        const clientData = {
            name: this.state.name,
            surname: this.state.surname,
            email: this.state.email,
            country: this.state.country,
            city: this.state.city,
            password: this.state.password,
            newsletter: Varnewsletter,
        };

        axios
            .post(
                process.env.REACT_APP_BASE_URL + `/authenticationAPI/ClientRegistraion`,
                clientData
            )
            .then((res) => {
                if (res.data.Status) this.setState({ redirect: true });
                else alert(res.data.Message);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    render() {
        const displayStyle = this.state.dealerStatus ? { display: "none" } : {};
        // Options for the radio buttons
        const amazonoptions = [
            { label: " Text", value: "0" },
            { label: " Email", value: "1" },
        ];
        const options = [
            { label: " Text", value: "0" },
            { label: " Email", value: "1" },
        ];
        const preoptions = [
            { label: " YES", value: "1" },
            { label: " NO", value: "0" },
        ];
        let optionsquote;
        if (localStorage.getItem("CustomerType") == "Dealer") {
            optionsquote = [{ label: " New Quote", value: true }];
        } else {
            if (this.state.vpnbygetquote) {
                optionsquote = [{ label: " New Quote", value: true }];
            } else {
                optionsquote = [
                    { label: " New Quote", value: true },
                    { label: " Update Quote", value: false },
                ];
            }
        }
        const { currentTab, vinno, totalTabs } = this.state;
        const { msrpvalue } = this.state;
        const { t } = this.props;
        var loanAmount = parseFloat(this.state.totalamountfinanced); //this.state.totalamountfinanced/
        var interestRate = parseFloat(this.state.mycustomfu); //this.state.pifrate
        var interestCRate = parseFloat(this.state.mycustomcu);
        var termMonths = parseInt(this.state.selectedTerms); //
        // var endBalanceInput =form.querySelector('.end-balance').value;
        var endBalance =
            this.state.estimatedresidualvalue[this.state.selectedIndex] !==
                undefined &&
            this.state.estimatedresidualvaluemyname[this.state.selectedIndex] > 0
                ? `${this.state.estimatedresidualvalue[
                        this.state.selectedIndex
                    ].toFixed(2)}`
                : 0;

        //var monthlyPaymentId = loanType + 'MonthlyPayment';
        var monthlyPayment =
            this.state.monthlypayment[this.state.selectedIndex] !== undefined &&
            this.state.monthlypayment[this.state.selectedIndex] > 0
                ? `${this.state.monthlypayment[this.state.selectedIndex].toFixed(2)}`
                : 0;

        var monthlyPaymentC =
            this.state.monthlypaymentc[this.state.selectedIndex] !== undefined &&
            this.state.monthlypaymentc[this.state.selectedIndex] > 0
                ? `${this.state.monthlypaymentc[this.state.selectedIndex].toFixed(2)}`
                : 0;
        var monthlyInterestRate = interestRate / 100 / 12;
        var monthlyInterestConventioanlRate = interestCRate / 100 / 12;
        var remainingBalance = loanAmount;
        var amortizationHTML = "";
        var totalPrincipal = 0;
        var totalInterest = 0;
        var ctotalInterest = 0;
        var totalMonthlyPayment = 0;
        var tabledata = "";
        let cmonthlyInterestRate = monthlyInterestConventioanlRate;
        //let monthlyPaymentc = (loanAmount * cmonthlyInterestRate * Math.pow(1 + cmonthlyInterestRate, termMonths)) / (Math.pow(1 + cmonthlyInterestRate, termMonths) - 1);
        //let totalAmountPaid = monthlyPaymentc * termMonths;
        //    ctotalInterest = totalAmountPaid - loanAmount;
        // console.log("Total Interest: $" + loanDetails.totalInterest);

        for (var i = 1; i <= termMonths; i++) {
            var interest = remainingBalance * monthlyInterestRate;
            var cinterest = remainingBalance * monthlyInterestConventioanlRate;
            var principal = monthlyPayment - interest;
            remainingBalance -= principal;
            totalPrincipal += principal;
            totalInterest += interest;
            ctotalInterest += cinterest;
            totalMonthlyPayment += monthlyPayment;
            // Round monthly payment to the nearest integer
            monthlyPayment = Math.round(monthlyPayment);
            // Format numbers with commas as thousand separators and two decimal places
            principal = principal.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
            interest = interest.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
            var monthlyPaymentFormatted = monthlyPayment.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
            var remainingBalanceFormatted = remainingBalance.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });

            // If it's the last month and it's a balloon loan, set the remaining balance to the end balance
            if (i === termMonths) {
                remainingBalance = endBalance;
                remainingBalanceFormatted = parseFloat(remainingBalance).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                });
            } else if (i === termMonths) {
                // If it's the last month and it's not a balloon loan, set the remaining balance to 0
                remainingBalance = 0;
                remainingBalanceFormatted = "0.00";
            }

            amortizationHTML += "<tr>";
            amortizationHTML += "<td>" + i + "</td>"; // Month
            amortizationHTML += "<td>$" + principal + "</td>"; // Principal
            amortizationHTML += "<td>$" + interest + "</td>"; // Interest
            amortizationHTML += "<td>$" + monthlyPaymentFormatted + "</td>"; // Monthly Payment
            amortizationHTML += "<td>$" + remainingBalanceFormatted + "</td>"; // Remaining Balance
            amortizationHTML += "</tr>";
        }

        // Add total row
        // Convert the value to a number if it's not already
        const numericValue = parseFloat(totalMonthlyPayment);
        let formattedNumber = "";
        // Check if the value is a valid number
        if (!isNaN(numericValue)) {
            // Convert the number to a fixed decimal format
            const fixedDecimalNumber = numericValue.toFixed(2);

            // Format the fixed decimal number
            formattedNumber = parseFloat(fixedDecimalNumber).toLocaleString("en-US");
        } else {
            formattedNumber = "";
        }

        amortizationHTML += "<tr>";
        amortizationHTML += "<td><strong>Total</strong></td>"; // Month
        amortizationHTML +=
            "<td><strong>$" +
            totalPrincipal.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }) +
            "</strong></td>"; // Total Principal
        amortizationHTML +=
            "<td><strong>$" +
            totalInterest.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }) +
            "</strong></td>"; // Total Interest
        amortizationHTML += "<td><strong>$" + formattedNumber + "</strong></td>"; // Total Monthly Payment
        amortizationHTML +=
            "<td><strong>$" +
            parseFloat(remainingBalance).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })+
            "</strong></td>"; // Total Remaining Balance
        amortizationHTML += "</tr>";
        tabledata = this.state.isExpanded
            ? '<table id="customers" style="width:100%"><tr><th>Month</th><th>Principal</th><th>Interest</th><th>Monthly Payment</th><th>Remaining Balance</th></tr>' +
                amortizationHTML +
                "<table>"
            : "";

        const numbersAndDecimaltf = this.state.totalamountfinanced.toString();
        const sectionstf = numbersAndDecimaltf.split(".");
        const integerParttf = sectionstf[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        const decimalParttf = sectionstf[1] ? sectionstf[1].substring(0, 2) : "";
        const formattedValuetf =
            sectionstf.length === 2
                ? `${integerParttf}.${decimalParttf}`
                : integerParttf;

        localStorage.setItem("profitAmount", totalInterest);
        localStorage.setItem("cprofitAmount", ctotalInterest.toFixed(2));
        const numbersAndDecimalstp = this.state.salestaxpercentage.toString();
        const sectionsstp = numbersAndDecimalstp.split(".");
        const integerPartstp = sectionsstp[0];
        const decimalPartstp = sectionsstp[1] ? sectionsstp[1].substring(0, 2) : "";
        const formattedValuestp =
            sectionsstp.length === 2
                ? `${integerPartstp}.${decimalPartstp}`
                : integerPartstp;
        console.log("7345");
        let numbersAndDecimalct;
        let sectionsct;
        let integerPartct;
        let decimalPartct;
        let abcd;
        if (
            this.state.filteredBankData !== "" &&
            this.state.filteredBankData &&
            this.state.filteredBankData.length > 0 &&
            this.state.bankData[0]["CuDefaultPercentage"]
        ) {
            // If bankData exists and has CuDefaultPercentage, use it

            abcd = this.state.filteredBankData[0]["CuDefaultPercentage"].toString();
            numbersAndDecimalct = abcd;
        } else if (
            this.state.bankData !== "" &&
            this.state.bankData &&
            this.state.bankData.length > 0 &&
            this.state.bankData[0]["CuDefaultPercentage"]
        ) {
            // If bankData exists and has CuDefaultPercentage, use it

            abcd = this.state.bankData[0]["CuDefaultPercentage"].toString();
            numbersAndDecimalct = abcd;
        } else {
            // Otherwise, fallback to conventionalrate
            numbersAndDecimalct = this.state.conventionalrate.toString();
        }
        console.log(typeof abcd);
        console.log(abcd);
        // Now split numbersAndDecimalct if it's defined and a string
        if (typeof numbersAndDecimalct === "string") {
            sectionsct = numbersAndDecimalct.split(".");
            integerPartct = sectionsct[0];
            decimalPartct = sectionsct[1] ? sectionsct[1].substring(0, 2) : "";
        } else {
            // Handle case where numbersAndDecimalct is not a string (e.g., it's undefined or null)
            console.error("Error: numbersAndDecimalct is not a string");
            // Provide default values or handle the error appropriately
            integerPartct = "";
            decimalPartct = "";
        }
        // Output for debugging
        let formattedValuect = "";
        if (localStorage.getItem("CustomerType") === "Dealer") {
            // Check if filteredBankData exists and has at least one element
            if (
                this.state.filteredBankData &&
                this.state.filteredBankData.length > 0
            ) {
                console.log("bankfilter");
                formattedValuect =
                    sectionsct.length === 2
                        ? `${integerPartct}.${decimalPartct}`
                        : integerPartct;
            } else if (this.state.bankData && this.state.bankData.length > 0) {
                console.log("bank");
                formattedValuect =
                    sectionsct.length === 2
                        ? `${integerPartct}.${decimalPartct}`
                        : integerPartct;

                console.log(formattedValuect);
            } else {
                formattedValuect = 6; // Handle case when filteredBankData is empty or undefined
            }
        } else {
            console.log("banknot");
            // Assuming sectionsct is defined elsewhere in your code
            formattedValuect =
                sectionsct.length === 2
                    ? `${integerPartct}.${decimalPartct}`
                    : integerPartct;
        }

        console.log("render");
        console.log(formattedValuect);
        // Use formattedValuect as needed
        // console.log('formattedValuect:', formattedValuect);
        // const numbersAndDecimalpft = this.state.pifrate.toString();
        // const sectionspft = numbersAndDecimalpft.split(".");
        // const integerPartpft = sectionspft[0];
        // const decimalPartpft = sectionspft[1] ? sectionspft[1].substring(0, 2) : "";
        // const formattedValuepft =
        //     sectionspft.length === 2
        //         ? `${integerPartpft}.${decimalPartpft}`
        //         : integerPartpft;
        let numbersAndDecimalpft;
        let sectionspft;
        let integerPartpft;
        let decimalPartpft;

        if (
            this.state.filteredBankData !== "" &&
            this.state.filteredBankData &&
            this.state.filteredBankData.length > 0 &&
            this.state.filteredBankData[0]["CuDefaultPercentage"]
        ) {
            // If filteredBankData exists and has CuDefaultPercentage, use it

            var y = +this.state.filteredBankData[0]["CuDefaultPercentage"] + 0.5;
            numbersAndDecimalpft = y.toString();
        } else if (
            this.state.bankData !== "" &&
            this.state.bankData &&
            this.state.bankData.length > 0 &&
            this.state.bankData[0]["CuDefaultPercentage"]
        ) {
            // If bankData exists and has CuDefaultPercentage, use it

            var y = +this.state.bankData[0]["CuDefaultPercentage"] + 0.5;
            numbersAndDecimalpft = y.toString();
        } else {
            // Otherwise, fallback to conventionalrate
            numbersAndDecimalpft = this.state.conventionalrate.toString();
        }

        // Now split numbersAndDecimalct if it's defined and a string
        if (typeof numbersAndDecimalpft === "string") {
            sectionspft = numbersAndDecimalpft.split(".");
            integerPartpft = sectionspft[0];
            decimalPartpft = sectionspft[1] ? sectionspft[1].substring(0, 2) : "";
        } else {
            // Handle case where numbersAndDecimalct is not a string (e.g., it's undefined or null)
            console.error("Error: numbersAndDecimalct is not a string");
            // Provide default values or handle the error appropriately
            integerPartpft = "";
            decimalPartpft = "";
        }

        let formattedValuepft = "";

        if (localStorage.getItem("CustomerType") === "Dealer") {
            // Check if filteredBankData exists and has at least one element
            if (
                this.state.filteredBankData &&
                this.state.filteredBankData.length > 0
            ) {
                formattedValuepft =
                    sectionspft.length === 2
                        ? `${integerPartpft}.${decimalPartpft}`
                        : integerPartpft;
            } else if (this.state.bankData && this.state.bankData.length > 0) {
                formattedValuepft = formattedValuepft =
                    sectionspft.length === 2
                        ? `${integerPartpft}.${decimalPartpft}`
                        : integerPartpft;
            } else {
                formattedValuepft = 6.5; // Handle case when filteredBankData is empty or undefined
            }
        } else {
            // Assuming sectionsct is defined elsewhere in your code
            formattedValuepft =
                sectionspft.length === 2
                    ? `${integerPartpft}.${decimalPartpft}`
                    : integerPartpft;
        }

        return (
            <>
                <LoginHeader />
                {/*<div style={{ textAlign: "center",color: "white", fontSize: "20px", fontWeight: "600",paddingTop: "25px",background: "#002f6c",background: "#002f6c" }}><h1 style={{color:"white"}}>Pay IT Forward - PIF</h1><hr/></div>*/}
                <FormWizard
                    shape="circle"
                    color="#e74c3c"
                    onComplete={this.state.handleComplete}
                >
                    {/*<FormWizard.TabContent title="Personal details" icon="ti-user">
                     <div style={{ textAlign: "left", paddingBottom: "10px" }}>
                     <label>
                        Name
                        <span
                            style={{ color: "#e74c3c", fontSize: "20px", fontWeight: "bold" }}
                        >
                            *
                        </span>
                    </label>
                    <br />
                    <input
                        className="form-control"
                        type="text"
                        value={this.state.name}
                         onChange={(e)=> this.setState({ name: e.target.value})}
                    />
                    </div>

<div style={{ textAlign: "left", paddingBottom: "10px" }}>
                     <label>
                        Email
                        <span
                            style={{ color: "#e74c3c", fontSize: "20px", fontWeight: "bold" }}
                        >
                            *
                        </span>
                    </label>
                    <br />
                    <input
                        className="form-control"
                        type="text"
                        value={this.state.email}
                        onChange={(e)=> this.setState({ email: e.target.value})}
                    />
</div>
<div style={{ textAlign: "left", paddingBottom: "10px" }}>
                     <label>
                        Phone Number
                        <span
                            style={{ color: "#e74c3c", fontSize: "20px", fontWeight: "bold" }}
                        >
                            *
                        </span>
                    </label>
                    <br />
                    <input
                        className="form-control"
                        type="text"
                        value={this.state.phone}
                        onChange={(e)=> this.setState({ phone: e.target.value})}
                    />
</div>
    {/* <div style={{ textAlign: "left" }}>
                <label>
                    Gender
                    <span style={{ color: "#e74c3c", fontSize: "20px", fontWeight: "bold" }}>
                        *
                    </span>
                </label>
            </div>
            <div style={{ display: "flex",justifyContent: "left" }}>
                <label style={{ display: "block" }}>
                    <input style={{ width: "20px" }}
                        className="form-control"
                        type="radio"
                        value="male"
                        selected
                        checked={this.state.gender === "male"}
                     onChange={(e)=> this.setState({ gender: e.target.value})}
                    />{" "}
                    Male
                </label>&nbsp;&nbsp;
                <label style={{ display: "block" }}>
                    <input style={{ width: "20px" }}
                        className="form-control"
                        type="radio"
                        value="female"
                        checked={this.state.gender === "female"}
                        onChange={(e)=> this.setState({ gender: e.target.value})}
                    />{" "}
                    Female
                </label>
            </div> */}

                    {/*isValid={this.checkValidateTab()} validationError={this.errorMessages()}*/}

                    {/*        </FormWizard.TabContent> */}

                    {currentTab === 0 && (
                        <div    className='wizard-tab-section'>
                            <FormWizard.TabContent
                                isValid={this.checkValidateTab()}
                                validationError={this.errorMessages()}
                                title="Vin Info"
                                icon="ti-settings"
                                currentTab={this.state.currentTab}
                                totalTabs={this.state.totalTabs}
                                onTabChange={this.handleTabChange}
                            >
                                <div style={{ textAlign: "left", paddingBottom: "10px" }}>
                                    <label>
                                        Vin No.
                                        <span
                                            style={{
                                                color: "#e74c3c",
                                                fontSize: "20px",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            *
                                        </span>
                                    </label>
                                    <br />

                                    <div className="readytext">
                                        Ready for loan docs?
                                        <i
                                            class="fas fa-arrow-right"
                                            style={{
                                                fontSize: "20px",
                                                marginLeft: "4px",
                                                color: "#4CAF50",
                                            }}
                                        ></i>
                                    </div>

                                    <div className="readyimage">
                                        <label for="yearSelect">
                                            {/*} <a href={window.location.origin+'/CommStarCU-PIF.png'} target="_blank"> 
             {/*<i class="fas fa-info-circle" style={{fontSize:"20px"}} title=""></i>*/}

                                            {/*</a>*/}
                                            <img
                                                src={window.location.origin + "/PurchaseAgreement.png"}
                                                alt="CommStarCU-PIF"
                                                width="200px"
                                            />
                                        </label>
                                    </div>

                                    <input
                                        title="vin no."
                                        className="form-control"
                                        type="text"
                                        id="vinnoid"
                                        value={this.state.vinno}
                                        onChange={(e) => this.handleVinno(e.target.value, false)}
                                        required
                                    />
                                </div>
                                <div class="text-left rem-main-div">
                                    <a class="btn modal-trigger text-left w-100 pad-0">
                                        <span>
                                            <input
                                                checked={this.state.isvinChecked}
                                                onChange={this.handleOverrideCheckboxChange}
                                                type="checkbox"
                                                id="override"
                                                class="rem-me-input"
                                            />
                                            <label for="override" class="color-cus-gray">
                                                {this.state.dealerStatus && this.state.dealerStatus
                                                    ? "Sample Quote Calculator"
                                                    : "Sample Quote Calculator"}
                                            </label>
                                        </span>
                                    </a>
                                </div>
                                <button
                                    style={{
                                        width: "140px",
                                        padding: "5px",
                                        border: "1px solid #002f6c",
                                        background: "#002f6c",
                                        color: "white",
                                        borderRadius: "5px",
                                        marginTop: "15px",
                                        fontWeight: "600",
                                    }}
                                    currentTab={this.state.currentTab}
                                    onClick={this.handleNextButtonClick}
                                >
                                    Proceed
                                </button>

                                <div class="divider" style={displayStyle}>
                                    <br />
                                    <h2>--------or----------</h2>
                                </div>
                                <br />
                                <br />

                                <div style={displayStyle}>
                                    <h1 style={{ fontWeight: "600", fontSize: "28px" }}>
                                        Vehicle Select
                                    </h1>

                                    <div className="paymentimage">
                                        <label for="yearSelect">
                                            {/*} <a href={window.location.origin+'/CommStarCU-PIF.png'} target="_blank"> 
             {/*<i class="fas fa-info-circle" style={{fontSize:"20px"}} title=""></i>*/}

                                            {/*</a>*/}
                                            <img
                                                src={this.state.cuimagepath!=''?this.state.cuimagepath:window.location.origin + "/CommStarCU-PIF.png"}
                                                
                                                alt="CommStarCU-PIF"
                                                width="200px"
                                            />
                                        </label>
                                    </div>

                                    <div className="paymenttext">
                                        Provide Payments + $100 Shopping Coupons
                                        <i
                                            class="fas fa-arrow-right"
                                            style={{
                                                fontSize: "20px",
                                                marginLeft: "4px",
                                                color: "#4CAF50",
                                            }}
                                        ></i>
                                    </div>

                                    <select
                                        id="yearSelect"
                                        class="form-control"
                                        value={this.state.yearData}
                                        onChange={(e) => this.handleYearChange(e.target.value)}
                                    >
                                        <option value="">Select Model Year</option>
                                        {this.getYears().map((year) => (
                                            <option key={year} value={year}>
                                                {year}
                                            </option>
                                        ))}
                                    </select>

                                    <select
                                        id="makeSelect"
                                        class="form-control"
                                        value={this.state.selectedMake}
                                        onChange={(e) => this.handleMakeChange(e.target.value)}
                                    >
                                        <option value="">Select Make</option>
                                        {this.state.makeOptions.map((make) => (
                                            <option key={make} value={make}>
                                                {make}
                                            </option>
                                        ))}
                                    </select>

                                    <select
                                        id="modelSelect"
                                        class="form-control"
                                        value={this.state.selectedModel}
                                        onChange={(e) => this.handleModelChange(e.target.value)}
                                    >
                                        <option value="">Select Model</option>
                                        {this.state.modelOptions.map((model) => (
                                            <option key={model} value={model}>
                                                {model}
                                            </option>
                                        ))}
                                    </select>

                                    {this.state.getselectr && this.state.loading && (
                                        <>
                                            <div
                                                class=""
                                                style={{
                                                    color: "black",
                                                    position: "fixed",
                                                    top: "15%",
                                                    left: "0",
                                                    fontSize: "22px",
                                                    width: "100%",
                                                    height: "100%",
                                                    zIndex: "9999",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                Loading...
                                            </div>
                                            <div className="loader-overlay">
                                                <div class="spinner-square">
                                                    <div class="square-1 square"></div>
                                                    <div class="square-2 square"></div>
                                                    <div class="square-3 square"></div>
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    <button
                                        type="button"
                                        style={{
                                            width: "140px",
                                            padding: "5px",
                                            border: "1px solid #002f6c",
                                            background: "#002f6c",
                                            color: "white",
                                            borderRadius: "5px",
                                            marginTop: "50px",
                                            marginLeft: "0px",
                                            fontWeight: "600",
                                        }}
                                        onClick={this.handleGetVin}
                                    >
                                        Get Quote
                                    </button>
                                </div>
                            </FormWizard.TabContent>
                        </div>
                    )}
                    {/*<FormWizard.TabContent title="Vehicle Info" icon="ti-settings"    >
                    <div style={{ textAlign: "left" , paddingBottom: "10px" }}>
                     <label>
                        Model Name
                    </label>
                    <br />
                    <input
                    disabled
                        className="form-control"
                        type="text"
                        value={"demo"}
                         onChange={(e)=> this.setState({ modalname: e.target.value})}
                    />
                    </div>

                    <div style={{ textAlign: "left" , paddingBottom: "10px" }}>
                     <label>
                        Model No.
                        
                    </label>
                    <br />
                    <input
                    disabled
                        className="form-control"
                        type="text"
                        value={"xyz"}
                         onChange={(e)=> this.setState({ modalno: e.target.value})}
                    />
                    </div>
                    <div style={{ textAlign: "left" , paddingBottom: "10px"}}>
                     <label>
                        Model Year
                    </label>
                    <br />
                    <input
                    disabled
                        className="form-control"
                        type="text"
                        value={"1992"}
                         onChange={(e)=> this.setState({ modalyear: e.target.value})}
                    />
                    </div>
                        <div style={{ textAlign: "left" , paddingBottom: "10px" }}>
                     <label>
                        Model Type
                    </label>
                    <br />
                    <input
                    disabled
                        className="form-control"
                        type="text"
                        value={"demo"}
                         onChange={(e)=> this.setState({ modaltype: e.target.value})}
                    />
                    </div>
                        <div style={{ textAlign: "left" , paddingBottom: "10px", display: "none" }}>
                     <label>
                        Engine Size
                    </label>
                    <br />
                    <input
                    disabled
                        className="form-control"
                        type="text"
                        value={"demo"}
                         onChange={(e)=> this.setState({ modaltype: e.target.value})}
                    />
                    </div>
                        <div style={{ textAlign: "left" , paddingBottom: "10px" , display: "none" }}>
                     <label>
                        Engine Type
                    </label>
                    <br />
                    <input
                    disabled
                        className="form-control"
                        type="text"
                        value={"demo"}
                         onChange={(e)=> this.setState({ modaltype: e.target.value})}
                    />
                    </div>
                </FormWizard.TabContent> */}
                    {currentTab === 1 && (
                        <>
                            <button
                                style={{
                                    width: "25px",
                                    padding: "5px",
                                    border: "1px solid #002f6c",
                                    background: "#002f6c",
                                    color: "white",
                                    borderRadius: "5px",
                                    fontWeight: "600",
                                    position: "absolute",
                                    left: "40px",
                                }}
                                currentTab={this.state.currentTab}
                                onClick={this.handlePrevButtonClick}
                            >
                                <i class="fa fa-arrow-left" aria-hidden="true"></i>
                            </button>
                            <FormWizard.TabContent
                                title="Last step"
                                icon="ti-check"
                                active={"true"}
                                currentTab={this.state.currentTab}
                                totalTabs={this.state.totalTabs}
                                onTabChange={this.handleTabChange}
                            >
                                {this.state.loading && (
                                    <>
                                        <div
                                            class=""
                                            style={{
                                                color: "black",
                                                position: "fixed",
                                                top: "15%",
                                                left: "0",
                                                fontSize: "22px",
                                                width: "100%",
                                                height: "100%",
                                                zIndex: "9999",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            Loading...
                                        </div>
                                        <div className="loader-overlay">
                                            <div class="spinner-square">
                                                <div class="square-1 square"></div>
                                                <div class="square-2 square"></div>
                                                <div class="square-3 square"></div>
                                            </div>
                                        </div>
                                    </>
                                )}

                                <div style={{ width: "100%" }}>
                                    <br /><br />
                                    {
                                        <Accordion flush className="custom-accordion">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>
                                                    {this.state.year} {this.state.carname}{" "}
                                                    {this.state.model}{" "}
                                                    {this.state.msrpvalue != undefined ? "- MSRP:" : ""}{" "}
                                                    {this.state.msrpvalue}
                                                </Accordion.Header>
                                                <Accordion.Body
                                                    style={{
                                                        textAlign: "left",
                                                        maxHeight: "260px",
                                                        overflowY: "scroll",
                                                    }}
                                                >
                                                    <div style={{ display: "flex" }}>
                                                        {
                                                            <div class="horizontal-table">
                                                                <div class="table-header">
                                                                    <div class="table-header-item">Car Name</div>
                                                                    <div class="table-header-item">Year</div>
                                                                    <div class="table-header-item">Model</div>
                                                                    <div class="table-header-item">MSRP</div>
                                                                </div>
                                                                <div class="table-content">
                                                                    <div class="table-row">
                                                                        <div class="table-cell">
                                                                            {this.state.carname}
                                                                        </div>
                                                                        <div class="table-cell">
                                                                            {this.state.year}
                                                                        </div>
                                                                        <div class="table-cell">
                                                                            {this.state.model}
                                                                        </div>
                                                                        <div class="table-cell">
                                                                            {this.state.msrpvalue}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }

                                                        <div
                                                            style={{
                                                                width: "50%",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                            }}
                                                        >
                                                            <button onClick={this.openInNewTab}>
                                                                <img
                                                                    src={this.state.pdf_url}
                                                                    alt="Sticker Image"
                                                                    style={{ width: "100%", maxHeight: "200px" }}
                                                                />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    }
                                </div>


                                <div className="icon-container">
                <button className="toggle-btn" onClick={this.toggleCalculator}>
                        <i className="fas fa-calculator"></i> 
                </button>

<div className="icon-text">
{this.state.isVisibleCalculator ? 'Hide Calculator' : 'View Calculator'}
</div>

        </div>

    

                                <div
                                    className='all-calculator-section'
                                >



                                {this.state.isVisibleCalculator && (
                                    <div    className="calculator-section">
                                        <br />

                                        <br />

                                        <div
                                            style={{
                                                textAlign: "center",
                                                fontSize: "24px",
                                                fontWeight: "500",
                                                paddingBottom: "20px",
                                                borderBottom: "1px solid #dee2e6",
                                                marginBottom: "40px",
                                                padding: "0px 5px 0px 5px",
                                                borderRadius: "0px 40px 0px 40px",
                                                background: "#ffea4f",
                                            }}
                                        >
                                            Calculator
                                        </div>

                                        <div>
                                            {this.state.showBankInfo && (
                                                <div
                                                    style={{ textAlign: "left", paddingBottom: "5px" }}
                                                >
                                                    <label>Finance Partner:</label>
                                                    <br />
                                                    <div class="input-group">
                                                        <select
                                                            className="form-control"
                                                            onChange={this.handleSelectChangeBank}
                                                            value={this.state.selectedBankId}
                                                        >
                                                            {this.state.bankData.map((bank) => (
                                                                <option key={bank.Cu_ID} value={bank.Cu_ID}>
                                                                    {bank.CompanyName}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        <div style={{ textAlign: "left", paddingBottom: "5px" }}>
                                            <label>Purchase Price:</label>
                                            <br />
                                            <div class="input-group">
                                                <div class="input-group-prepend ">
                                                    <span class="input-group-text">$</span>
                                                </div>
                                                <input
                                                    title="purchase price"
                                                    className="form-control"
                                                    type="text"
                                                    value={
                                                        this.state.purchasepricevalue
                                                            ? this.state.purchasepricevalue
                                                            : ""
                                                    }
                                                    onChange={this.handlePurchasevalue}
                                                />
                                            </div>
                                        </div>

                                        <div style={{ textAlign: "left", paddingBottom: "5px" }}>
                                            <label>Trade-in Value:</label>
                                            <br />

                                            <div class="input-group">
                                                <div class="input-group-prepend ">
                                                    <span class="input-group-text">$</span>
                                                </div>
                                                <input
                                                    title="trade in value"
                                                    className="form-control"
                                                    type="text"
                                                    value={
                                                        this.state.tradeinvalue
                                                            ? this.state.tradeinvalue
                                                            : ""
                                                    }
                                                    onInput={this.handleTradeinvalue}
                                                />
                                            </div>
                                        </div>

                                        <div style={{ textAlign: "left", paddingBottom: "5px" }}>
                                            <label>Trade-in Payoff:</label>
                                            <br />
                                            <div class="input-group">
                                                <div class="input-group-prepend ">
                                                    <span class="input-group-text">$</span>
                                                </div>
                                                <input
                                                    title="purchase payoff"
                                                    className="form-control"
                                                    type="text"
                                                    value={
                                                        this.state.tradeinpayoff
                                                            ? this.state.tradeinpayoff
                                                            : ""
                                                    }
                                                    onChange={this.handleTradeinpayoff}
                                                />
                                            </div>
                                        </div>

                                        <div style={{ textAlign: "left", paddingBottom: "5px" }}>
                                            <label>Down Payment:</label>
                                            <br />

                                            <div class="input-group">
                                                <div class="input-group-prepend ">
                                                    <span class="input-group-text">$</span>
                                                </div>
                                                <input
                                                    title="downpayment"
                                                    className="form-control"
                                                    type="text"
                                                    value={
                                                        this.state.downpayment ? this.state.downpayment : ""
                                                    }
                                                    onChange={this.handleDownpayment}
                                                />
                                            </div>
                                        </div>

                                        <div style={{ textAlign: "left", paddingBottom: "5px" }}>
                                            <label>
                                                Sales Tax
                                                {/*<span
                                                    style={{
                                                        color: "red",
                                                        fontSize: "20px",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    *
                                                </span>*/}
                                                :
                                            </label>
                                            <br />
                                            <div style={{ display: "flex" }}>
                                                <input
                                                    title="sales tax percentage"
                                                    style={{ width: "35%", marginRight: "5%" }}
                                                    className="form-control"
                                                    type="text"
                                                    value={formattedValuestp}
                                                    onChange={this.handleSalestaxpercentage}
                                                />

                                                <input
                                                    title="sales tax value"
                                                    style={{ width: "60%" }}
                                                    className="form-control"
                                                    disabled
                                                    type="text"
                                                    value={
                                                        this.state.salestax
                                                            ? parseFloat(this.state.salestax).toFixed(2)
                                                            : ""
                                                    }
                                                    onChange={this.handleSalestax}
                                                />
                                            </div>
                                        </div>

                                        <div style={{ textAlign: "left", paddingBottom: "5px" }}>
                                            <label>Insurance/warranties:</label>
                                            <br />
                                            <div class="input-group">
                                                <div class="input-group-prepend ">
                                                    <span class="input-group-text">$</span>
                                                </div>
                                                <input
                                                    title="insurance"
                                                    className="form-control"
                                                    type="text"
                                                    value={
                                                        this.state.insurance ? this.state.insurance : ""
                                                    }
                                                    onChange={this.handleInsurance}
                                                />
                                            </div>
                                        </div>

                                        <div style={{ textAlign: "left", paddingBottom: "5px" }}>
                                            <label>All other fees:</label>
                                            <br />
                                            <div class="input-group">
                                                <div class="input-group-prepend ">
                                                    <span class="input-group-text">$</span>
                                                </div>
                                                <input
                                                    title="all other fees"
                                                    className="form-control"
                                                    type="text"
                                                    value={
                                                        this.state.otherfees ? this.state.otherfees : ""
                                                    }
                                                    onChange={this.handleAllotherfees}
                                                />
                                            </div>
                                        </div>

                                        <div style={{ textAlign: "left", paddingBottom: "5px" }}>
                                            <label>Total Amount Financed</label>
                                            <br />
                                            <div class="input-group">
                                                <div class="input-group-prepend ">
                                                    <span class="input-group-text">$</span>
                                                </div>

                                                <input
                                                    title="total amount financed"
                                                    disabled
                                                    className="form-control"
                                                    type="text"
                                                    value={
                                                        formattedValuetf.replace(/,/g, "") > 0
                                                            ? formattedValuetf
                                                            : ""
                                                    }
                                                    onChange={(e) =>
                                                        this.setState({
                                                            totalamountfinanced: e.target.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div style={{ textAlign: "left", paddingBottom: "5px" }}>
                                            <label>Conventional Rate:</label>
                                            <br />

                                            <div class="input-group">
                                                <input
                                                    title="conventional rate"
                                                    className="form-control"
                                                    type="text"
                                                    value={this.state.mycustomcu}
                                                    onChange={this.handleConventionalRateChange}
                                                />

                                                <div class="input-group-append">
                                                    <span class="input-group-text">%</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ textAlign: "left", paddingBottom: "5px" }}>
                                            <label>PIF Rate:</label>
                                            <br />
                                            <div class="input-group">
                                                <input
                                                    title="pif rate"
                                                    disabled
                                                    className="form-control"
                                                    type="text"
                                                    value={this.state.mycustomfu}
                                                />

                                                <div class="input-group-append">
                                                    <span class="input-group-text">%</span>
                                                </div>
                                            </div>
                                        </div>

                                        {/*} <div style={{ textAlign: "left" , paddingBottom: "5px" }}>
                     <label>
                        Annual Mileage:
                    </label>
                    <br />
                    <input
                        className="form-control"
                        type="text"
                        value={"12,000"}
                         onChange={(e)=> this.setState({ modaltype: e.target.value})}
                    />
                    </div>
                     <div style={{ textAlign: "left"    , paddingBottom: "5px"}}>
                     <label>
                        Current odometer:
                    </label>
                    <br />
                    <input
                        className="form-control"
                        type="text"
                        value={"0-12000"}
                         onChange={(e)=> this.setState({ modaltype: e.target.value})}
                    />
                    </div> */}
                                        <div style={{ textAlign: "left" }}>
                                            <label>Notes:</label>
                                            <br />
                                            <textarea
                                                title="notes"
                                                class="form-control"
                                                value={this.state.notes}
                                                onChange={(e) =>
                                                    this.setState({ notes: e.target.value })
                                                }
                                            />
                                        </div>
                                    </div>

)}


                                    <div    className='payment-options-section'>
                                        <br />
                                        <br />
                                        <div
                                            style={{
                                                textAlign: "center",
                                                fontSize: "24px",
                                                fontWeight: "500",
                                                paddingBottom: "20px",
                                                background: "#ffea4f",
                                                borderRadius: "0px 40px 0px 40px",
                                                padding: "0px 5px 0px 5px",
                                            }}
                                        >
                                            Payment Options
                                        </div>
                                        <div className="table-responsive">
                                        <table className="table table-striped m-table">
                                            <thead>
                                                <tr>
                                                    <th>Term</th>
                                                    <th style={{ width: "28%" }}>
                                                        Estimated Residual value
                                                    </th>
                                                    <th>PIF Payment</th>
                                                    <th>Conventional Payment</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    style={
                                                        this.state.selectedOption === "12"
                                                            ? { background: "#cccccc9e" }
                                                            : {}
                                                    }
                                                >
                                                    <td>12 Month</td>
                                                    <td>
                                                        <div class="input-group ">
                                                            <div class="input-group-prepend m-input-group-prepend">
                                                                <span class="input-group-text">$</span>
                                                            </div>
                                                            <input
                                                                id="editableInput12"
                                                                disabled
                                                                className="form-control"
                                                                type="text"
                                                                    value={
                                                                    this.state.estimatedresidualvalue[0] !==
                                                                    undefined && !isNaN(this.state.estimatedresidualvalue[0])
                                                                        ? this.state.estimatedresidualvalue[0].toLocaleString("en-US", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
            })

                                                                        : ""
                                                                }
                                                                
                                                           
                                                             
                                                            />


                                                            <div
                                                                style={{
                                                                    marginTop: "10px",
                                                                    marginLeft: "5px",
                                                                    fontSize: "18px",
                                                                    marginRight: "5px",
                                                                }}
                                                            >
                                                                <FontAwesomeIcon
                                                                    title={"edit"}
                                                                    style={{
                                                                        marginRight: "8px",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    icon={faPencilAlt}
                                                                    onClick={() => this.editDataa(12)}
                                                                />
                                                                {this.state.showReset12 && (
                                                                    <FontAwesomeIcon
                                                                        title={"reset"}
                                                                        icon={faUndo}
                                                                        onClick={() => this.resetDataa(12)}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        {this.state.purchasepricevalue.replace(/,/g, "") !==
                                                            undefined &&
                                                        this.state.purchasepricevalue.replace(/,/g, "") >
                                                            0 ? (
                                                            this.state.selectedOption === "12" ? (
                                                                <span>
                                                                    {this.state.monthlypayment[0] !== undefined &&
                                                                    this.state.monthlypayment[0] > 0 ? (
                                                                        `$${

this.state.monthlypayment[0].toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })


                                                                    }`
                                                                    ) : (
                                                                        <div className="tooltip">
                                                                            <span className="question-mark">?</span>
                                                                            <span className="tooltip-text">
                                                                                Purchase price should be greator than
                                                                                end balance
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                </span>
                                                            ) : (
                                                                <span>
                                                                    {this.state.monthlypayment[0] !== undefined &&
                                                                    this.state.monthlypayment[0] > 0 ? (
                                                                        `$${

this.state.monthlypayment[0].toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })

                                                                    }`
                                                                    ) : (
                                                                        <div className="tooltip">
                                                                            <span className="question-mark">?</span>
                                                                            <span className="tooltip-text">
                                                                                Purchase price should be greator than
                                                                                end balance
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                </span>
                                                            )
                                                        ) : (
                                                            <span>
                                                                {
                                                                    <div className="tooltip">
                                                                        <span className="question-mark">?</span>
                                                                        <span className="tooltip-text">
                                                                            Amount financed required
                                                                        </span>
                                                                    </div>
                                                                }
                                                            </span>
                                                        )}

                                                        <label className="ml-2">
                                                            <input
                                                                style={{
                                                                    width: "30px",
                                                                    minHeight: "unset",
                                                                    height: "30px",
                                                                }}
                                                                className=" form-control option-input checkbox"
                                                                type="radio"
                                                                value="12"
                                                                checked={this.state.selectedOption === "12"}
                                                                onChange={() => this.changeradio(0, "12")}
                                                            />{" "}
                                                        </label>
                                                    </td>
                                                    <td>
                                                        {this.state.selectedOption === "12" ? (
                                                            <span>
                                                                {!isNaN(this.state.monthlypaymentc[0]) &&
                                                                this.state.monthlypaymentc[0] !== undefined &&
                                                                this.state.monthlypaymentc[0] !== undefined &&
                                                                this.state.monthlypaymentc[0] > 0 ? (
                                                                    `$${

this.state.monthlypaymentc[0].toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })


                                                                }`
                                                                ) : (
                                                                    <div className="tooltip">
                                                                        <span className="question-mark">?</span>
                                                                        <span className="tooltip-text">
                                                                            Amount financed required
                                                                        </span>
                                                                    </div>
                                                                )}
                                                            </span>
                                                        ) : (
                                                            <span>
                                                                {!isNaN(this.state.monthlypaymentc[0]) &&
                                                                this.state.monthlypaymentc[0] !== undefined &&
                                                                this.state.monthlypaymentc[0] > 0 ? (
                                                                    `$${
this.state.monthlypaymentc[0].toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })




                                                                }`
                                                                ) : (
                                                                    <div className="tooltip">
                                                                        <span className="question-mark">?</span>
                                                                        <span className="tooltip-text">
                                                                            Amount financed required
                                                                        </span>
                                                                    </div>
                                                                )}
                                                            </span>
                                                        )}
                                                    </td>
                                                </tr>

                                                <tr
                                                    style={
                                                        this.state.selectedOption === "24"
                                                            ? { background: "#cccccc9e" }
                                                            : {}
                                                    }
                                                >
                                                    <td>24 Month</td>
                                                    <td>
                                                        <div class="input-group ">
                                                            <div class="input-group-prepend m-input-group-prepend">
                                                                <span class="input-group-text">$</span>
                                                            </div>
                                                            <input
                                                                id="editableInput24"
                                                                disabled
                                                                className="form-control"
                                                                type="text"
                                                                 value={
                                                                    this.state.estimatedresidualvalue[1] !==
                                                                    undefined && !isNaN(this.state.estimatedresidualvalue[1])
                                                                        ? this.state.estimatedresidualvalue[1].toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })
                                                                        : ""
                                                                }
                                                              
                                                            />

                                                            <div
                                                                style={{
                                                                    marginTop: "10px",
                                                                    marginLeft: "5px",
                                                                    fontSize: "18px",
                                                                    marginRight: "5px",
                                                                }}
                                                            >
                                                                <FontAwesomeIcon
                                                                    title={"edit"}
                                                                    style={{
                                                                        marginRight: "8px",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    icon={faPencilAlt}
                                                                    onClick={() => this.editDataa(24)}
                                                                />
                                                                {this.state.showReset24 && (
                                                                    <FontAwesomeIcon
                                                                        title={"reset"}
                                                                        icon={faUndo}
                                                                        onClick={() => this.resetDataa(24)}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {this.state.purchasepricevalue.replace(/,/g, "") !==
                                                            undefined &&
                                                        this.state.purchasepricevalue.replace(/,/g, "") >
                                                            0 ? (
                                                            this.state.selectedOption === "24" ? (
                                                                <span>
                                                                    {this.state.monthlypayment[1] !== undefined &&
                                                                    this.state.monthlypayment[1] > 0 ? (
                                                                        `$${
this.state.monthlypayment[1].toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })


                                                                    }`
                                                                    ) : (
                                                                        <div className="tooltip">
                                                                            <span className="question-mark">?</span>
                                                                            <span className="tooltip-text">
                                                                                Purchase price should be greator than
                                                                                end balance
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                </span>
                                                            ) : (
                                                                <span>
                                                                    {this.state.monthlypayment[1] !== undefined &&
                                                                    this.state.monthlypayment[1] > 0 ? (
                                                                        `$${
this.state.monthlypayment[1].toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })


                                                                    }`
                                                                    ) : (
                                                                        <div className="tooltip">
                                                                            <span className="question-mark">?</span>
                                                                            <span className="tooltip-text">
                                                                                Purchase price should be greator than
                                                                                end balance
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                </span>
                                                            )
                                                        ) : (
                                                            <span>
                                                                {
                                                                    <div className="tooltip">
                                                                        <span className="question-mark">?</span>
                                                                        <span className="tooltip-text">
                                                                            Amount financed required
                                                                        </span>
                                                                    </div>
                                                                }
                                                            </span>
                                                        )}

                                                        <label className="ml-2">
                                                            <input
                                                                style={{
                                                                    width: "30px",
                                                                    minHeight: "unset",
                                                                    height: "30px",
                                                                }}
                                                                className=" form-control option-input checkbox"
                                                                type="radio"
                                                                value="24"
                                                                checked={this.state.selectedOption === "24"}
                                                                onChange={() => this.changeradio(1, "24")}
                                                            />{" "}
                                                        </label>
                                                    </td>
                                                    <td>
                                                        {this.state.selectedOption === "24" ? (
                                                            <span>
                                                                {!isNaN(this.state.monthlypaymentc[1]) &&
                                                                this.state.monthlypaymentc[1] !== undefined &&
                                                                this.state.monthlypaymentc[1] > 0 ? (
                                                                    `$${

                                                                        this.state.monthlypaymentc[1].toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })



                                                                }`
                                                                ) : (
                                                                    <div className="tooltip">
                                                                        <span className="question-mark">?</span>
                                                                        <span className="tooltip-text">
                                                                            Amount financed required
                                                                        </span>
                                                                    </div>
                                                                )}
                                                            </span>
                                                        ) : (
                                                            <span>
                                                                {!isNaN(this.state.monthlypaymentc[1]) &&
                                                                this.state.monthlypaymentc[1] !== undefined &&
                                                                this.state.monthlypaymentc[1] > 0 ? (
                                                                    `$${this.state.monthlypaymentc[1].toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })



                                                                }`
                                                                ) : (
                                                                    <div className="tooltip">
                                                                        <span className="question-mark">?</span>
                                                                        <span className="tooltip-text">
                                                                            Amount financed required
                                                                        </span>
                                                                    </div>
                                                                )}
                                                            </span>
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr
                                                    style={
                                                        this.state.selectedOption === "36"
                                                            ? { background: "#cccccc9e" }
                                                            : {}
                                                    }
                                                >
                                                    <td>36 Month</td>
                                                    <td>
                                                        <div class="input-group ">
                                                            <div class="input-group-prepend m-input-group-prepend">
                                                                <span class="input-group-text">$</span>
                                                            </div>
                                                            <input
                                                                id="editableInput36"
                                                                disabled
                                                                className="form-control"
                                                                type="text"
                                                                    value={
                                                                    this.state.estimatedresidualvalue[2] !==
                                                                    undefined && !isNaN(this.state.estimatedresidualvalue[2])
                                                                        ? this.state.estimatedresidualvalue[2].toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })
                                                                        : ""
                                                                }
                                                               
                                                            />

                                                            <div
                                                                style={{
                                                                    marginTop: "10px",
                                                                    marginLeft: "5px",
                                                                    fontSize: "18px",
                                                                    marginRight: "5px",
                                                                }}
                                                            >
                                                                <FontAwesomeIcon
                                                                    title={"edit"}
                                                                    style={{
                                                                        marginRight: "8px",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    icon={faPencilAlt}
                                                                    onClick={() => this.editDataa(36)}
                                                                />
                                                                {this.state.showReset36 && (
                                                                    <FontAwesomeIcon
                                                                        title={"reset"}
                                                                        icon={faUndo}
                                                                        onClick={() => this.resetDataa(36)}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {this.state.purchasepricevalue.replace(/,/g, "") !==
                                                            undefined &&
                                                        this.state.purchasepricevalue.replace(/,/g, "") >
                                                            0 ? (
                                                            this.state.selectedOption === "36" ? (
                                                                <span>
                                                                    {this.state.monthlypayment[2] !== undefined &&
                                                                    this.state.monthlypayment[2] > 0 ? (
                                                                        `$${

this.state.monthlypayment[2].toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })

                                                                        }`
                                                                    ) : (
                                                                        <div className="tooltip">
                                                                            <span className="question-mark">?</span>
                                                                            <span className="tooltip-text">
                                                                                Purchase price should be greator than
                                                                                end balance
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                </span>
                                                            ) : (
                                                                <span>
                                                                    {this.state.monthlypayment[2] !== undefined &&
                                                                    this.state.monthlypayment[2] > 0 ? (
                                                                        `$${

this.state.monthlypayment[2].toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })

                                                                        }`
                                                                    ) : (
                                                                        <div className="tooltip">
                                                                            <span className="question-mark">?</span>
                                                                            <span className="tooltip-text">
                                                                                Purchase price should be greator than
                                                                                end balance
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                </span>
                                                            )
                                                        ) : (
                                                            <span>
                                                                {
                                                                    <div className="tooltip">
                                                                        <span className="question-mark">?</span>
                                                                        <span className="tooltip-text">
                                                                            Amount financed required
                                                                        </span>
                                                                    </div>
                                                                }
                                                            </span>
                                                        )}

                                                        <label className="ml-2">
                                                            <input
                                                                style={{
                                                                    width: "30px",
                                                                    minHeight: "unset",
                                                                    height: "30px",
                                                                }}
                                                                className=" form-control option-input checkbox"
                                                                type="radio"
                                                                type="radio"
                                                                value="36"
                                                                checked={this.state.selectedOption === "36"}
                                                                onChange={() => this.changeradio(2, "36")}
                                                            />{" "}
                                                        </label>
                                                    </td>
                                                    <td>
                                                        {this.state.selectedOption === "36" ? (
                                                            <span>
                                                                {!isNaN(this.state.monthlypaymentc[2]) &&
                                                                this.state.monthlypaymentc[2] !== undefined &&
                                                                this.state.monthlypaymentc[2] > 0 ? (
                                                                    `$${

this.state.monthlypaymentc[2].toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })

                                                                    }`
                                                                ) : (
                                                                    <div className="tooltip">
                                                                        <span className="question-mark">?</span>
                                                                        <span className="tooltip-text">
                                                                            Amount financed required
                                                                        </span>
                                                                    </div>
                                                                )}
                                                            </span>
                                                        ) : (
                                                            <span>
                                                                {!isNaN(this.state.monthlypaymentc[2]) &&
                                                                this.state.monthlypaymentc[2] !== undefined &&
                                                                this.state.monthlypaymentc[2] > 0 ? (
                                                                    `$${
this.state.monthlypaymentc[2].toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })


                                                                }`
                                                                ) : (
                                                                    <div className="tooltip">
                                                                        <span className="question-mark">?</span>
                                                                        <span className="tooltip-text">
                                                                            Amount financed required
                                                                        </span>
                                                                    </div>
                                                                )}
                                                            </span>
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr
                                                    style={
                                                        this.state.selectedOption === "48"
                                                            ? { background: "#cccccc9e" }
                                                            : {}
                                                    }
                                                >
                                                    <td>48 Month</td>
                                                    <td>
                                                        <div class="input-group ">
                                                            <div class="input-group-prepend m-input-group-prepend">
                                                                <span class="input-group-text">$</span>
                                                            </div>
                                                            <input
                                                                id="editableInput48"
                                                                disabled
                                                                className="form-control"
                                                                type="text"
                                                                    value={
                                                                    this.state.estimatedresidualvalue[3] !==
                                                                    undefined && !isNaN(this.state.estimatedresidualvalue[3])
                                                                        ? this.state.estimatedresidualvalue[3].toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })
                                                                        : ""
                                                                }
                                                               
                                                            />
                                                            <div
                                                                style={{
                                                                    marginTop: "10px",
                                                                    marginLeft: "5px",
                                                                    fontSize: "18px",
                                                                    marginRight: "5px",
                                                                }}
                                                            >
                                                                <FontAwesomeIcon
                                                                    title={"edit"}
                                                                    style={{
                                                                        marginRight: "8px",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    icon={faPencilAlt}
                                                                    onClick={() => this.editDataa(48)}
                                                                />
                                                                {this.state.showReset48 && (
                                                                    <FontAwesomeIcon
                                                                        title={"reset"}
                                                                        icon={faUndo}
                                                                        onClick={() => this.resetDataa(48)}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {this.state.purchasepricevalue.replace(/,/g, "") !==
                                                            undefined &&
                                                        this.state.purchasepricevalue.replace(/,/g, "") >
                                                            0 ? (
                                                            this.state.selectedOption === "48" ? (
                                                                <span>
                                                                    {this.state.monthlypayment[3] !== undefined &&
                                                                    this.state.monthlypayment[3] > 0 ? (
                                                                        `$${

this.state.monthlypayment[3].toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })


                                                                        }`
                                                                    ) : (
                                                                        <div className="tooltip">
                                                                            <span className="question-mark">?</span>
                                                                            <span className="tooltip-text">
                                                                                Purchase price should be greator than
                                                                                end balance
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                </span>
                                                            ) : (
                                                                <span>
                                                                    {this.state.monthlypayment[3] !== undefined &&
                                                                    this.state.monthlypayment[3] > 0 ? (
                                                                        `$${

this.state.monthlypayment[3].toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })


                                                                        }`
                                                                    ) : (
                                                                        <div className="tooltip">
                                                                            <span className="question-mark">?</span>
                                                                            <span className="tooltip-text">
                                                                                Purchase price should be greator than
                                                                                end balance
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                </span>
                                                            )
                                                        ) : (
                                                            <span>
                                                                {
                                                                    <div className="tooltip">
                                                                        <span className="question-mark">?</span>
                                                                        <span className="tooltip-text">
                                                                            Amount financed required
                                                                        </span>
                                                                    </div>
                                                                }
                                                            </span>
                                                        )}

                                                        <label className="ml-2">
                                                            <input
                                                                style={{
                                                                    width: "30px",
                                                                    minHeight: "unset",
                                                                    height: "30px",
                                                                }}
                                                                className=" form-control option-input checkbox"
                                                                type="radio"
                                                                type="radio"
                                                                value="48"
                                                                checked={this.state.selectedOption === "48"}
                                                                onChange={() => this.changeradio(3, "48")}
                                                            />{" "}
                                                        </label>
                                                    </td>
                                                    <td>
                                                        {this.state.selectedOption === "48" ? (
                                                            <span>
                                                                {!isNaN(this.state.monthlypaymentc[3]) &&
                                                                this.state.monthlypaymentc[3] !== undefined &&
                                                                this.state.monthlypaymentc[3] > 0 ? (
                                                                    `$${

this.state.monthlypaymentc[3].toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })

                                                                    }`
                                                                ) : (
                                                                    <div className="tooltip">
                                                                        <span className="question-mark">?</span>
                                                                        <span className="tooltip-text">
                                                                            Amount financed required
                                                                        </span>
                                                                    </div>
                                                                )}
                                                            </span>
                                                        ) : (
                                                            <span>
                                                                {!isNaN(this.state.monthlypaymentc[3]) &&
                                                                this.state.monthlypaymentc[3] !== undefined &&
                                                                this.state.monthlypaymentc[3] > 0 ? (
                                                                    `$${

this.state.monthlypaymentc[3].toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })

                                                                    }`
                                                                ) : (
                                                                    <div className="tooltip">
                                                                        <span className="question-mark">?</span>
                                                                        <span className="tooltip-text">
                                                                            Amount financed required
                                                                        </span>
                                                                    </div>
                                                                )}
                                                            </span>
                                                        )}
                                                    </td>
                                                </tr>

                                                <tr
                                                    style={
                                                        this.state.selectedOption === "60"
                                                            ? { background: "#cccccc9e" }
                                                            : {}
                                                    }
                                                >
                                                    <td>60 Month</td>
                                                    <td>
                                                        <div class="input-group ">
                                                            <div class="input-group-prepend m-input-group-prepend">
                                                                <span class="input-group-text">$</span>
                                                            </div>
                                                            <input
                                                                id="editableInput60"
                                                                disabled
                                                                className="form-control"
                                                                type="text"
                                                             
                                                                 value={
                                                                    this.state.estimatedresidualvalue[4] !==
                                                                    undefined && !isNaN(this.state.estimatedresidualvalue[4])
                                                                        ? this.state.estimatedresidualvalue[4].toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })
                                                                        : ""
                                                                }
                                                                
                                                            />
                                                            <div
                                                                style={{
                                                                    marginTop: "10px",
                                                                    marginLeft: "5px",
                                                                    fontSize: "18px",
                                                                    marginRight: "5px",
                                                                }}
                                                            >
                                                                <FontAwesomeIcon
                                                                    title={"edit"}
                                                                    style={{
                                                                        marginRight: "8px",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    icon={faPencilAlt}
                                                                    onClick={() => this.editDataa(60)}
                                                                />
                                                                {this.state.showReset60 && (
                                                                    <FontAwesomeIcon
                                                                        title={"reset"}
                                                                        icon={faUndo}
                                                                        onClick={() => this.resetDataa(60)}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {this.state.purchasepricevalue.replace(/,/g, "") !==
                                                            undefined &&
                                                        this.state.purchasepricevalue.replace(/,/g, "") >
                                                            0 ? (
                                                            this.state.selectedOption === "60" ? (
                                                                <span>
                                                                    {this.state.monthlypayment[4] !== undefined &&
                                                                    this.state.monthlypayment[4] > 0 ? (
                                                                        `$${

this.state.monthlypayment[4].toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })

                                                                        }`
                                                                    ) : (
                                                                        <div className="tooltip">
                                                                            <span className="question-mark">?</span>
                                                                            <span className="tooltip-text">
                                                                                Purchase price should be greator than
                                                                                end balance
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                </span>
                                                            ) : (
                                                                <span>
                                                                    {this.state.monthlypayment[4] !== undefined &&
                                                                    this.state.monthlypayment[4] > 0 ? (
                                                                        `$${

this.state.monthlypayment[4].toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })

                                                                        }`
                                                                    ) : (
                                                                        <div className="tooltip">
                                                                            <span className="question-mark">?</span>
                                                                            <span className="tooltip-text">
                                                                                Purchase price should be greator than
                                                                                end balance
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                </span>
                                                            )
                                                        ) : (
                                                            <span>
                                                                {
                                                                    <div className="tooltip">
                                                                        <span className="question-mark">?</span>
                                                                        <span className="tooltip-text">
                                                                            Amount financed required
                                                                        </span>
                                                                    </div>
                                                                }
                                                            </span>
                                                        )}
                                                        <label className="ml-2">
                                                            <input
                                                                style={{
                                                                    width: "30px",
                                                                    minHeight: "unset",
                                                                    height: "30px",
                                                                }}
                                                                className=" form-control option-input checkbox"
                                                                type="radio"
                                                                type="radio"
                                                                value="60"
                                                                checked={this.state.selectedOption === "60"}
                                                                onChange={() => this.changeradio(4, "60")}
                                                            />{" "}
                                                        </label>
                                                    </td>
                                                    <td>
                                                        {this.state.selectedOption === "60" ? (
                                                            <span>
                                                                {!isNaN(this.state.monthlypaymentc[4]) &&
                                                                this.state.monthlypaymentc[4] !== undefined &&
                                                                this.state.monthlypaymentc[4] > 0 ? (
                                                                    `$${
this.state.monthlypaymentc[4].toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })


                                                                    }`
                                                                ) : (
                                                                    <div className="tooltip">
                                                                        <span className="question-mark">?</span>
                                                                        <span className="tooltip-text">
                                                                            Amount financed required
                                                                        </span>
                                                                    </div>
                                                                )}
                                                            </span>
                                                        ) : (
                                                            <span>
                                                                {!isNaN(this.state.monthlypaymentc[4]) &&
                                                                this.state.monthlypaymentc[4] !== undefined &&
                                                                this.state.monthlypaymentc[4] > 0 ? (
                                                                    `$${

this.state.monthlypaymentc[4].toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })


                                                                    }`
                                                                ) : (
                                                                    <div className="tooltip">
                                                                        <span className="question-mark">?</span>
                                                                        <span className="tooltip-text">
                                                                            Amount financed required
                                                                        </span>
                                                                    </div>
                                                                )}
                                                            </span>
                                                        )}
                                                    </td>
                                                </tr>

                                                <tr
                                                    style={
                                                        this.state.selectedOption === "72"
                                                            ? { background: "#cccccc9e" }
                                                            : {}
                                                    }
                                                >
                                                    <td>72 Month</td>
                                                    <td>
                                                        <div class="input-group ">
                                                            <div class="input-group-prepend m-input-group-prepend">
                                                                <span class="input-group-text">$</span>
                                                            </div>
                                                            <input
                                                                id="editableInput72"
                                                                disabled
                                                                className="form-control"
                                                                type="text"
                                                                value={
                                                                    this.state.estimatedresidualvalue[5] && !isNaN(this.state.estimatedresidualvalue[5])
                                                                        ? this.state.estimatedresidualvalue[5].toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })
                                                                        : ""
                                                                }
                                                               
                                                            />
                                                            <div
                                                                style={{
                                                                    marginTop: "10px",
                                                                    marginLeft: "5px",
                                                                    fontSize: "18px",
                                                                    marginRight: "5px",
                                                                }}
                                                            >
                                                                <FontAwesomeIcon
                                                                    title={"edit"}
                                                                    style={{
                                                                        marginRight: "8px",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    icon={faPencilAlt}
                                                                    onClick={() => this.editDataa(72)}
                                                                />
                                                                {this.state.showReset72 && (
                                                                    <FontAwesomeIcon
                                                                        title={"reset"}
                                                                        icon={faUndo}
                                                                        onClick={() => this.resetDataa(72)}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {this.state.purchasepricevalue.replace(/,/g, "") !==
                                                            undefined &&
                                                        this.state.purchasepricevalue.replace(/,/g, "") >
                                                            0 ? (
                                                            this.state.selectedOption === "72" ? (
                                                                <span>
                                                                    {this.state.monthlypayment[5] !== undefined &&
                                                                    this.state.monthlypayment[5] > 0 ? (
                                                                        `$${

this.state.monthlypayment[5].toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })

                                                                        }`
                                                                    ) : (
                                                                        <div className="tooltip">
                                                                            <span className="question-mark">?</span>
                                                                            <span className="tooltip-text">
                                                                                Purchase price should be greator than
                                                                                end balance
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                </span>
                                                            ) : (
                                                                <span>
                                                                    {this.state.monthlypayment[5] !== undefined &&
                                                                    this.state.monthlypayment[5] > 0 ? (
                                                                        `$${

this.state.monthlypayment[5].toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })

                                                                        }`
                                                                    ) : (
                                                                        <div className="tooltip">
                                                                            <span className="question-mark">?</span>
                                                                            <span className="tooltip-text">
                                                                                Purchase price should be greator than
                                                                                end balance
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                </span>
                                                            )
                                                        ) : (
                                                            <span>
                                                                {
                                                                    <div className="tooltip">
                                                                        <span className="question-mark">?</span>
                                                                        <span className="tooltip-text">
                                                                            Amount financed required
                                                                        </span>
                                                                    </div>
                                                                }
                                                            </span>
                                                        )}

                                                        <label className="ml-2">
                                                            <input
                                                                style={{
                                                                    width: "30px",
                                                                    minHeight: "unset",
                                                                    height: "30px",
                                                                }}
                                                                className=" form-control option-input checkbox"
                                                                type="radio"
                                                                value="72"
                                                                checked={this.state.selectedOption === "72"}
                                                                onChange={() => this.changeradio(5, "72")}
                                                            />{" "}
                                                        </label>
                                                    </td>
                                                    <td>
                                                        {this.state.selectedOption === "72" ? (
                                                            <span>
                                                                {!isNaN(this.state.monthlypaymentc[5]) &&
                                                                this.state.monthlypaymentc[5] !== undefined &&
                                                                this.state.monthlypaymentc[5] > 0 ? (
                                                                    `$${

this.state.monthlypaymentc[5].toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })

                                                                    }`
                                                                ) : (
                                                                    <div className="tooltip">
                                                                        <span className="question-mark">?</span>
                                                                        <span className="tooltip-text">
                                                                            Amount financed required
                                                                        </span>
                                                                    </div>
                                                                )}
                                                            </span>
                                                        ) : (
                                                            <span>
                                                                {!isNaN(this.state.monthlypaymentc[5]) &&
                                                                this.state.monthlypaymentc[5] !== undefined &&
                                                                this.state.monthlypaymentc[5] > 0 ? (
                                                                    `$${

this.state.monthlypaymentc[5].toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })


                                                                }`
                                                                ) : (
                                                                    <div className="tooltip">
                                                                        <span className="question-mark">?</span>
                                                                        <span className="tooltip-text">
                                                                            Amount financed required
                                                                        </span>
                                                                    </div>
                                                                )}
                                                            </span>
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr
                                                    style={
                                                        this.state.selectedOption === "84"
                                                            ? { background: "#cccccc9e" }
                                                            : {}
                                                    }
                                                >
                                                    <td>84 Month</td>
                                                    <td>
                                                        <div class="input-group ">
                                                            <div class="input-group-prepend m-input-group-prepend">
                                                                <span class="input-group-text">$</span>
                                                            </div>
                                                            <input
                                                                id="editableInput84"
                                                                disabled
                                                                className="form-control"
                                                                type="text"
                                                                value={
                                                                    this.state.estimatedresidualvalue[6] !==
                                                                    undefined && !isNaN(this.state.estimatedresidualvalue[6])
                                                                        ? this.state.estimatedresidualvalue[6].toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })
                                                                        : ""
                                                                }
                                                              
                                                            />
                                                            <div
                                                                style={{
                                                                    marginTop: "10px",
                                                                    marginLeft: "5px",
                                                                    fontSize: "18px",
                                                                    marginRight: "5px",
                                                                }}
                                                            >
                                                                <FontAwesomeIcon
                                                                    title={"edit"}
                                                                    style={{
                                                                        marginRight: "8px",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    icon={faPencilAlt}
                                                                    onClick={() => this.editDataa(84)}
                                                                />
                                                                {this.state.showReset84 && (
                                                                    <FontAwesomeIcon
                                                                        title={"reset"}
                                                                        icon={faUndo}
                                                                        onClick={() => this.resetDataa(84)}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {this.state.purchasepricevalue.replace(/,/g, "") !==
                                                            undefined &&
                                                        this.state.purchasepricevalue.replace(/,/g, "") >
                                                            0 ? (
                                                            this.state.selectedOption === "84" ? (
                                                                <span>
                                                                    {this.state.monthlypayment[6] !== undefined &&
                                                                    this.state.monthlypayment[6] > 0 ? (
                                                                        `$${

this.state.monthlypayment[6].toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })

                                                                        }`
                                                                    ) : (
                                                                        <div className="tooltip">
                                                                            <span className="question-mark">?</span>
                                                                            <span className="tooltip-text">
                                                                                Purchase price should be greator than
                                                                                end balance
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                </span>
                                                            ) : (
                                                                <span>
                                                                    {this.state.monthlypayment[6] !== undefined &&
                                                                    this.state.monthlypayment[6] > 0 ? (
                                                                        `$${


this.state.monthlypayment[6].toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })

                                                                        }`
                                                                    ) : (
                                                                        <div className="tooltip">
                                                                            <span className="question-mark">?</span>
                                                                            <span className="tooltip-text">
                                                                                Purchase price should be greator than
                                                                                end balance
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                </span>
                                                            )
                                                        ) : (
                                                            <span>
                                                                {
                                                                    <div className="tooltip">
                                                                        <span className="question-mark">?</span>
                                                                        <span className="tooltip-text">
                                                                            Amount financed required
                                                                        </span>
                                                                    </div>
                                                                }
                                                            </span>
                                                        )}

                                                        <label className="ml-2">
                                                            <input
                                                                style={{
                                                                    width: "30px",
                                                                    minHeight: "unset",
                                                                    height: "30px",
                                                                }}
                                                                className=" form-control option-input checkbox"
                                                                type="radio"
                                                                value="84"
                                                                checked={this.state.selectedOption === "84"}
                                                                onChange={() => this.changeradio(6, "84")}
                                                            />{" "}
                                                        </label>
                                                    </td>
                                                    <td>
                                                        {this.state.selectedOption === "84" ? (
                                                            <span>
                                                                {!isNaN(this.state.monthlypaymentc[6]) &&
                                                                this.state.monthlypaymentc[6] !== undefined &&
                                                                this.state.monthlypaymentc[6] > 0 ? (
                                                                    `$${

this.state.monthlypaymentc[6].toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })


                                                                }`
                                                                ) : (
                                                                    <div className="tooltip">
                                                                        <span className="question-mark">?</span>
                                                                        <span className="tooltip-text">
                                                                            Amount financed required
                                                                        </span>
                                                                    </div>
                                                                )}
                                                            </span>
                                                        ) : (
                                                            <span>
                                                                {!isNaN(this.state.monthlypaymentc[6]) &&
                                                                this.state.monthlypaymentc[6] !== undefined &&
                                                                this.state.monthlypaymentc[6] > 0 ? (
                                                                    `$${

                                                                        this.state.monthlypaymentc[6].toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })



                                                                }`
                                                                ) : (
                                                                    <div className="tooltip">
                                                                        <span className="question-mark">?</span>
                                                                        <span className="tooltip-text">
                                                                            Amount financed required
                                                                        </span>
                                                                    </div>
                                                                )}
                                                            </span>
                                                        )}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                        <br />







                                        {this.state.showcustomerPopup && (
                                            <div className="popup customer">
                                                <div className="popup-content">
                                                    {/* Popup content */}
                                                    <span
                                                        style={{
                                                            cursor: "pointer",
                                                            float: "right",
                                                            fontWeight: "700",
                                                            border: "1px solid white",
                                                            background: "white",
                                                            color: "#002f6c",
                                                            borderRadius: "19px",
                                                            width: "24px",
                                                        }}
                                                        title="close"
                                                        class="close-icon"
                                                        onClick={this.closecustomerPopup}
                                                    >
                                                        X
                                                    </span>
                                                    <div style={{ textAlign: "center" }}>
                                                        Customer details{" "}
                                                    </div>
                                                    {!this.state.quotedefineStatus1 && (
                                                        <div
                                                            style={{
                                                                textAlign: "left",
                                                                paddingBottom: "10px",
                                                            }}
                                                        >
                                                            <label>
                                                                Search
                                                                <span
                                                                    style={{
                                                                        color: "#e74c3c",
                                                                        fontSize: "20px",
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                    *
                                                                </span>
                                                            </label>
                                                            <br />
                                                            <input
                                                                className="form-control"
                                                                placeholder="Search"
                                                                type="text"
                                                                value={this.state.quoteid}
                                                                onKeyUp={this.handlequoteidchange}
                                                                onChange={this.handlequoteidchange}
                                                            />
                                                        </div>
                                                    )}
                                                    <div className="suggestions">
                                                        {this.state.suggestions &&
                                                            this.state.suggestions.map(
                                                                (suggestion, index) => (
                                                                    <div
                                                                        key={index}
                                                                        className="suggestion-item"
                                                                        onClick={() =>
                                                                            this.handleSuggestionClick(suggestion)
                                                                        }
                                                                    >
                                                                        {suggestion.split("--")[0]}
                                                                        {"-"}
                                                                        {suggestion.split("--")[1]}
                                                                        {"-"}
                                                                        {suggestion.split("--")[2]}
                                                                        {"-"}
                                                                        {suggestion.split("--")[3]}
                                                                    </div>
                                                                )
                                                            )}
                                                    </div>
                                                    <div
                                                        style={{ textAlign: "left", paddingBottom: "10px" }}
                                                    >
                                                        <label>
                                                            Name
                                                            <span
                                                                style={{
                                                                    color: "#e74c3c",
                                                                    fontSize: "20px",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                *
                                                            </span>
                                                        </label>
                                                        <br />
                                                       

                                                                <input
                                                                name="uniqueformid"
                                                            className="form-control"
                                                            type="hidden"
                                                            value={this.state.uniqueformid}
                                                            
                                                        />



                                                        <input
                                                            {...(!this.state.quotedefineStatus1
                                                                ? { readOnly: true }
                                                                : {})}
                                                            className="form-control"
                                                            type="text"
                                                            value={this.state.name}
                                                            onChange={(e) =>
                                                                this.setState({ name: [e.target.value] })
                                                            }
                                                        />
                                                    </div>
                                                    <div
                                                        style={{ textAlign: "left", paddingBottom: "10px" }}
                                                    >
                                                        <label>
                                                            Email
                                                            <span
                                                                style={{
                                                                    color: "#e74c3c",
                                                                    fontSize: "20px",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                *
                                                            </span>
                                                        </label>
                                                        <br />
                                                        <input
                                                            {...(!this.state.quotedefineStatus1
                                                                ? { readOnly: true }
                                                                : {})}
                                                            className="form-control"
                                                            type="text"
                                                            value={this.state.email}
                                                            onChange={(e) =>
                                                                this.setState({ email: [e.target.value] })
                                                            }
                                                        />
                                                    </div>
                                                    <div
                                                        style={{ textAlign: "left", paddingBottom: "10px" }}
                                                    >
                                                        <label>
                                                            Phone Number
                                                            <span
                                                                style={{
                                                                    color: "#e74c3c",
                                                                    fontSize: "20px",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                *
                                                            </span>
                                                        </label>
                                                        <br />
                                                        <input
                                                            {...(!this.state.quotedefineStatus1
                                                                ? { readOnly: true }
                                                                : {})}
                                                            className="form-control"
                                                            type="text"
                                                            value={this.state.phone}
                                                            onChange={this.handlePhone}
                                                        />
                                                    </div>
                                                    <div
                                                        style={{ textAlign: "left", paddingBottom: "10px" }}
                                                    >
                                                        <label>
                                                            Address
                                                            <span
                                                                style={{
                                                                    color: "#e74c3c",
                                                                    fontSize: "20px",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                *
                                                            </span>
                                                        </label>
                                                        <br />
                                                        <input
                                                            {...(!this.state.quotedefineStatus1
                                                                ? { readOnly: true }
                                                                : {})}
                                                            className="form-control"
                                                            type="text"
                                                            value={this.state.address}
                                                            onChange={(e) =>
                                                                this.setState({ address: [e.target.value] })
                                                            }
                                                        />
                                                    </div>
                                                    <div
                                                        style={{ textAlign: "left", paddingBottom: "10px" }}
                                                    >
                                                        <label>
                                                            Address2
                                                            <span
                                                                style={{
                                                                    color: "#e74c3c",
                                                                    fontSize: "20px",
                                                                    fontWeight: "bold",
                                                                }}
                                                            ></span>
                                                        </label>
                                                        <br />
                                                        <input
                                                            {...(!this.state.quotedefineStatus1
                                                                ? { readOnly: true }
                                                                : {})}
                                                            className="form-control"
                                                            type="text"
                                                            value={this.state.address2}
                                                            onChange={(e) =>
                                                                this.setState({ address2: [e.target.value] })
                                                            }
                                                        />
                                                    </div>
                                                    <div style={{ display: "flex" }}>
                                                        <div
                                                            style={{
                                                                textAlign: "left",
                                                                paddingBottom: "10px",
                                                                marginRight: "10px",
                                                            }}
                                                        >
                                                            <label>
                                                                City
                                                                <span
                                                                    style={{
                                                                        color: "#e74c3c",
                                                                        fontSize: "20px",
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                    *
                                                                </span>
                                                            </label>
                                                            <br />
                                                            <input
                                                                {...(!this.state.quotedefineStatus1
                                                                    ? { readOnly: true }
                                                                    : {})}
                                                                className="form-control"
                                                                type="text"
                                                                value={this.state.city}
                                                                onChange={(e) =>
                                                                    this.setState({ city: [e.target.value] })
                                                                }
                                                            />
                                                        </div>

                                                        <div
                                                            style={{
                                                                textAlign: "left",
                                                                paddingBottom: "10px",
                                                                marginRight: "10px",
                                                            }}
                                                        >
                                                            <label>
                                                                State
                                                                <span
                                                                    style={{
                                                                        color: "#e74c3c",
                                                                        fontSize: "20px",
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                    *
                                                                </span>
                                                            </label>
                                                            <br />
                                                            <input
                                                                {...(!this.state.quotedefineStatus1
                                                                    ? { readOnly: true }
                                                                    : {})}
                                                                className="form-control"
                                                                type="text"
                                                                value={this.state.state}
                                                                onChange={(e) =>
                                                                    this.setState({ state: [e.target.value] })
                                                                }
                                                            />
                                                        </div>

                                                        <div
                                                            style={{
                                                                textAlign: "left",
                                                                paddingBottom: "10px",
                                                                marginRight: "10px",
                                                            }}
                                                        >
                                                            <label>
                                                                Zip
                                                                <span
                                                                    style={{
                                                                        color: "#e74c3c",
                                                                        fontSize: "20px",
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                    *
                                                                </span>
                                                            </label>
                                                            <br />
                                                            <input
                                                                {...(!this.state.quotedefineStatus1
                                                                    ? { readOnly: true }
                                                                    : {})}
                                                                className="form-control"
                                                                type="text"
                                                                value={this.state.zip}
                                                                onChange={(e) =>
                                                                    this.setState({ zip: [e.target.value] })
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    {this.state.printStatus && (
                                                        <>
                                                            <div style={{ display: "flex" }}>
                                                                <div
                                                                    style={{
                                                                        textAlign: "left",
                                                                        paddingBottom: "10px",
                                                                        marginRight: "10px",
                                                                    }}
                                                                >
                                                                    <label>
                                                                        Branch
                                                                        <span
                                                                            style={{
                                                                                color: "#e74c3c",
                                                                                fontSize: "20px",
                                                                                fontWeight: "bold",
                                                                            }}
                                                                        >
                                                                            *
                                                                        </span>
                                                                    </label>
                                                                    <br />
                                                                    <input
                                                                        className="form-control"
                                                                        type="text"
                                                                        value={
                                                                            this.state.branch == "undefined" ||
                                                                            this.state.branch == undefined
                                                                                ? ""
                                                                                : this.state.branch
                                                                        }
                                                                        onChange={(e) =>
                                                                            this.setState({
                                                                                branch: [e.target.value],
                                                                            })
                                                                        }
                                                                    />
                                                                </div>

                                                                <div
                                                                    style={{
                                                                        textAlign: "left",
                                                                        paddingBottom: "10px",
                                                                        marginRight: "10px",
                                                                    }}
                                                                >
                                                                    <label>
                                                                        Cu Referral
                                                                        <span
                                                                            style={{
                                                                                color: "#e74c3c",
                                                                                fontSize: "20px",
                                                                                fontWeight: "bold",
                                                                            }}
                                                                        >
                                                                            *
                                                                        </span>
                                                                    </label>
                                                                    <br />
                                                                    <input
                                                                        className="form-control"
                                                                        type="text"
                                                                        value={this.state.cu_referral}
                                                                        onChange={(e) =>
                                                                            this.setState({
                                                                                cu_referral: [e.target.value],
                                                                            })
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div>
                                                                Pre Approved By :-
                                                                {preoptions.map((option1) => (
                                                                    <label
                                                                        key={option1.value}
                                                                        style={{
                                                                            marginTop: "10px",
                                                                            marginRight: "10px",
                                                                        }}
                                                                    >
                                                                        <input
                                                                            style={{ verticalAlign: "middle" }}
                                                                            type="radio"
                                                                            name="radioGroup1"
                                                                            value={option1.value}
                                                                            checked={
                                                                                this.state.preApprovedStatus ===
                                                                                option1.value
                                                                            }
                                                                            onChange={this.preApprovedChange}
                                                                        />
                                                                        {option1.label}
                                                                    </label>
                                                                ))}
                                                            </div>

                                                            <div>
                                                            Receive Amazon Reward By
                                                                 :-
                                                                {options.map((option) => (
                                                                    <label
                                                                        key={option.value}
                                                                        style={{
                                                                            marginTop: "10px",
                                                                            marginRight: "10px",
                                                                        }}
                                                                    >
                                                                        <input
                                                                            style={{ verticalAlign: "middle" }}
                                                                            type="radio"
                                                                            name="radioGroup"
                                                                            value={option.value}
                                                                            checked={
                                                                                this.state.amazonStatus === option.value
                                                                            }
                                                                            onChange={this.handleReceiveAmazonChange}
                                                                        />
                                                                        {option.label}
                                                                    </label>
                                                                ))}
                                                            </div>

                                                            <div>
                                                                Receive Coupon By :-
                                                                {amazonoptions.map((option2) => (
                                                                    <label
                                                                        key={option2.value}
                                                                        style={{
                                                                            marginTop: "10px",
                                                                            marginRight: "10px",
                                                                        }}
                                                                    >
                                                                        <input
                                                                            style={{ verticalAlign: "middle" }}
                                                                            type="radio"
                                                                            name="radioGroup2"
                                                                            value={option2.value}
                                                                            checked={option2.value == "0"}
                                                                             checked={
                                                                                this.state.amazonCouponStatus === option2.value
                                                                            }
        onChange={this.handleReceiveAmazonCouponChange}
                                                                        />
                                                                        {option2.label}
                                                                    </label>
                                                                ))}
                                                            </div>
                                                        </>
                                                    )}
                                                    {
                                                        this.state.coapplicantmember && (
                                                            <>
                                                                <div style={{ textAlign: "center" }}>
                                                                    Co Applicant Member{" "}
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        textAlign: "left",
                                                                        paddingBottom: "10px",
                                                                    }}
                                                                >
                                                                    <label>
                                                                        Name
                                                                        <span
                                                                            style={{
                                                                                color: "#e74c3c",
                                                                                fontSize: "20px",
                                                                                fontWeight: "bold",
                                                                            }}
                                                                        >
                                                                            *
                                                                        </span>
                                                                    </label>
                                                                    <br />
                                                                    <input
                                                                        className="form-control"
                                                                        type="text"
                                                                        value={this.state.name1}
                                                                        onChange={(e) =>
                                                                            this.setState({ name1: [e.target.value] })
                                                                        }
                                                                    />
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        textAlign: "left",
                                                                        paddingBottom: "10px",
                                                                    }}
                                                                >
                                                                    <label>
                                                                        Email
                                                                        <span
                                                                            style={{
                                                                                color: "#e74c3c",
                                                                                fontSize: "20px",
                                                                                fontWeight: "bold",
                                                                            }}
                                                                        >
                                                                            *
                                                                        </span>
                                                                    </label>
                                                                    <br />
                                                                    <input
                                                                        className="form-control"
                                                                        type="text"
                                                                        value={this.state.email1}
                                                                        onChange={(e) =>
                                                                            this.setState({
                                                                                email1: [e.target.value],
                                                                            })
                                                                        }
                                                                    />
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        textAlign: "left",
                                                                        paddingBottom: "10px",
                                                                    }}
                                                                >
                                                                    <label>
                                                                        Phone Number
                                                                        <span
                                                                            style={{
                                                                                color: "#e74c3c",
                                                                                fontSize: "20px",
                                                                                fontWeight: "bold",
                                                                            }}
                                                                        >
                                                                            *
                                                                        </span>
                                                                    </label>
                                                                    <br />
                                                                    <input
                                                                        className="form-control"
                                                                        type="text"
                                                                        value={this.state.phone1}
                                                                        onChange={this.handlePhone1}
                                                                    />
                                                                </div>

                                                                <div
                                                                    style={{
                                                                        textAlign: "left",
                                                                        paddingBottom: "10px",
                                                                    }}
                                                                >
                                                                    <label>
                                                                        Address
                                                                        <span
                                                                            style={{
                                                                                color: "#e74c3c",
                                                                                fontSize: "20px",
                                                                                fontWeight: "bold",
                                                                            }}
                                                                        >
                                                                            *
                                                                        </span>
                                                                    </label>
                                                                    <br />
                                                                    <input
                                                                        className="form-control"
                                                                        type="text"
                                                                        value={this.state.address1}
                                                                        onChange={(e) =>
                                                                            this.setState({
                                                                                address1: [e.target.value],
                                                                            })
                                                                        }
                                                                    />
                                                                </div>

                                                                <div
                                                                    style={{
                                                                        textAlign: "left",
                                                                        paddingBottom: "10px",
                                                                    }}
                                                                >
                                                                    <label>
                                                                        Address2
                                                                        <span
                                                                            style={{
                                                                                color: "#e74c3c",
                                                                                fontSize: "20px",
                                                                                fontWeight: "bold",
                                                                            }}
                                                                        ></span>
                                                                    </label>
                                                                    <br />
                                                                    <input
                                                                        className="form-control"
                                                                        type="text"
                                                                        value={this.state.address21}
                                                                        onChange={(e) =>
                                                                            this.setState({
                                                                                address21: [e.target.value],
                                                                            })
                                                                        }
                                                                    />
                                                                </div>

                                                                <div style={{ display: "flex" }}>
                                                                    <div
                                                                        style={{
                                                                            textAlign: "left",
                                                                            paddingBottom: "10px",
                                                                            marginRight: "10px",
                                                                        }}
                                                                    >
                                                                        <label>
                                                                            City
                                                                            <span
                                                                                style={{
                                                                                    color: "#e74c3c",
                                                                                    fontSize: "20px",
                                                                                    fontWeight: "bold",
                                                                                }}
                                                                            >
                                                                                *
                                                                            </span>
                                                                        </label>
                                                                        <br />
                                                                        <input
                                                                            className="form-control"
                                                                            type="text"
                                                                            value={this.state.city1}
                                                                            onChange={(e) =>
                                                                                this.setState({
                                                                                    city1: [e.target.value],
                                                                                })
                                                                            }
                                                                        />
                                                                    </div>

                                                                    <div
                                                                        style={{
                                                                            textAlign: "left",
                                                                            paddingBottom: "10px",
                                                                            marginRight: "10px",
                                                                        }}
                                                                    >
                                                                        <label>
                                                                            State
                                                                            <span
                                                                                style={{
                                                                                    color: "#e74c3c",
                                                                                    fontSize: "20px",
                                                                                    fontWeight: "bold",
                                                                                }}
                                                                            >
                                                                                *
                                                                            </span>
                                                                        </label>
                                                                        <br />
                                                                        <input
                                                                            className="form-control"
                                                                            type="text"
                                                                            value={this.state.state1}
                                                                            onChange={(e) =>
                                                                                this.setState({
                                                                                    state1: [e.target.value],
                                                                                })
                                                                            }
                                                                        />
                                                                    </div>

                                                                    <div
                                                                        style={{
                                                                            textAlign: "left",
                                                                            paddingBottom: "10px",
                                                                            marginRight: "10px",
                                                                        }}
                                                                    >
                                                                        <label>
                                                                            Zip
                                                                            <span
                                                                                style={{
                                                                                    color: "#e74c3c",
                                                                                    fontSize: "20px",
                                                                                    fontWeight: "bold",
                                                                                }}
                                                                            >
                                                                                *
                                                                            </span>
                                                                        </label>
                                                                        <br />
                                                                        <input
                                                                            className="form-control"
                                                                            type="text"
                                                                            value={this.state.zip1}
                                                                            onChange={(e) =>
                                                                                this.setState({
                                                                                    zip1: [e.target.value],
                                                                                })
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    {this.state.quoteStatus && (
                                                        <>
                                                            <input
                                                                id="coapplicant"
                                                                type="checkbox"
                                                                onChange={this.appendcoapplicant}
                                                            />
                                                            <label for="coapplicant">
                                                                {" "}
                                                                &nbsp;Add CoApplicant
                                                            </label>
                                                        </>
                                                    )}
                                                    <br />
                                                    <span style={{ display: "none" }}>
                                                        {" "}
                                                        Would You Like To Accept Quote?{" "}
                                                    </span>{" "}
                                                    &nbsp;&nbsp;
                                                </div>
                                                {localStorage.getItem("CustomerType") !== "Dealer" &&
                                                    this.state.printStatus && (
                                                        <>
                                                            <div className="popup-buttons">
                                                                {/* "Next" button */}
                                                                
                                                               <button
                                                                    onClick={this.handleNextClick2}
                                                                    style={{
                                                                        borderColor: "#f8ea55",
                                                                        borderRadius: "4px",
                                                                        boxSizing: "border-box",
                                                                        borderWidth: "1px",
                                                                        backgroundColor: "#f8ea55",
                                                                        fontSize: "14px",
                                                                        fontWeight: "600",
                                                                        padding: "6px 12px",
                                                                        color: "#002f6c",
                                                                        minWidth: "140px",
                                                                        marginRight: "5px",
                                                                    }}
                                                                 >
                                                                    Generate Coupon
                                                                </button>

                                                            

                                                            </div>
                                                        </>
                                                    )}
                                                {!this.state.quotedefineStatus1 &&
                                                    this.state.quoteStatus && (
                                                        <>
                                                            <div className="popup-buttons">
                                                                {/* "Next" button */}
                                                                <button
                                                                    onClick={this.handleNextClick1Update}
                                                                    style={{
                                                                        borderColor: "rgb(0 0 255)",
                                                                        borderRadius: "4px",
                                                                        boxSizing: "border-box",
                                                                        borderWidth: "2px",
                                                                        backgroundColor: "transparent",
                                                                        fontSize: "14px",
                                                                        fontWeight: "600",
                                                                        padding: "6px 12px",
                                                                        color: "white",
                                                                        minWidth: "140px",
                                                                        marginRight: "5px",
                                                                    }}
                                                                >
                                                                    Update
                                                                </button>
                                                            </div>
                                                        </>
                                                    )}

                                                {this.state.quotedefineStatus1 &&
                                                    this.state.quoteStatus && (
                                                        <>
    <div className="popup-buttons">
                                                                {/* "Next" button */}
                                                                <button
                                                                    onClick={()=>this.handleNextClick1('signed')}
                                                                    style={{
                                                                        borderColor: "rgb(0 0 255)",
                                                                        borderRadius: "4px",
                                                                        boxSizing: "border-box",
                                                                        borderWidth: "2px",
                                                                        backgroundColor: "transparent",
                                                                        fontSize: "14px",
                                                                        fontWeight: "600",
                                                                        padding: "6px 12px",
                                                                        color: "white",
                                                                        minWidth: "140px",
                                                                        marginRight: "5px",
                                                                    }}
                                                                >
                                                                    Live Signature
                                                                </button>
                                                            </div>
 {this.state.showSignedPopup && (
                                            <div className="popup" style={{background:"white",display:"none"}}>
                                                <div className="popup-content">
                                     <span
                                                        style={{
                                                            cursor: "pointer",
                                                            float: "right",
                                                            fontWeight: "700",
                                                            border: "1px solid white",
                                                            background: "white",
                                                            color: "#002f6c",
                                                            borderRadius: "19px",
                                                            width: "24px",
                                                        }}
                                                        title="close"
                                                        class="close-icon"
                                                        onClick={this.closesignedPopup}
                                                    >
                                                        X
                                                    </span>
<div style={{"width":"100%","marginTop":"0px"}}>
            </div>
</div>
</div>
)}
    {this.state.isEmail && ( /* If email option is selected, show email input and send button */
<div>
<input type="email" placeholder="Enter email for signing" onChange={this.handleEmailChange} />
<button onClick={this.sendEmail2}>Send Email</button>
</div>
            )}
            {this.state.signingUrl && ( /* If live signing URL is available, show it to the user */
<div>
<p>Sign the document live by following this <a href={this.state.signingUrl} target="_blank" rel="noopener noreferrer">link</a>.</p>
</div>
            )}
                                                            <div className="popup-buttons">
                                                                {/* "Next" button */}
                                                                <button
                                                                    onClick={()=>this.handleNextClick1('normal')}
                                                                    style={{
                                                                        borderColor: "rgb(0 0 255)",
                                                                        borderRadius: "4px",
                                                                        boxSizing: "border-box",
                                                                        borderWidth: "2px",
                                                                        backgroundColor: "transparent",
                                                                        fontSize: "14px",
                                                                        fontWeight: "600",
                                                                        padding: "6px 12px",
                                                                        color: "white",
                                                                        minWidth: "140px",
                                                                        marginRight: "5px",
                                                                    }}
                                                                >
                                                                    Save
                                                                </button>
                                                            </div>
                                                        </>
                                                    )}
                                            </div>
                                        )}
                                        {this.state.showPopup1 && (
                                            <div className="popup model2" >
                                                <div className="popup-content">
                                                    {/* Popup content */}
                                                    <span
                                                        style={{
                                                            cursor: "pointer",
                                                            float: "right",
                                                            fontWeight: "700",
                                                            border: "1px solid white",
                                                            background: "white",
                                                            color: "#002f6c",
                                                            borderRadius: "19px",
                                                            width: "24px",
                                                        }}
                                                        title="close"
                                                        class="close-icon"
                                                        onClick={this.closePopup1}
                                                    >
                                                        X
                                                    </span>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            width: "100%",
                                                            justifyContent: "space-evenly",
                                                        }}
                                                    ></div>
                                                    <div
                                                        class="horizontal-table1 ajay2"
                                                        style={{ display: "none" }}
                                                    >
                                                        <div class="table-header1">
                                                            <div class="table-header-item">MSRP</div>
                                                            <div class="table-header-item">PIF rate</div>
                                                            <div class="table-header-item">
                                                                Monthly Pif Amount
                                                            </div>

                                                            {/* <div class="table-header-item">Monthly Conventional Amount</div>*/}
                                                            {/*<div class="table-header-item">Total Estimated Value</div>*/}
                                                            <div class="table-header-item">Loan Term</div>
                                                        </div>
                                                        <div class="table-content1">
                                                            <div class="table-row1">
                                                                <div class="table-cell">
                                                                    ${this.state.msrpvalue}
                                                                </div>
                                                                <div class="table-cell">
                                                                    ${this.state.pifrate}
                                                                </div>
                                                                <div class="table-cell">
                                                                    {
                                                                        <span>
                                                                            {this.state.monthlypayment[
                                                                                this.state.selectedIndex
                                                                            ] !== undefined &&
                                                                            this.state.monthlypayment[
                                                                                this.state.selectedIndex
                                                                            ] > 0 ? (
                                                                                `$${this.state.monthlypayment[
                                                                                    this.state.selectedIndex
                                                                                ].toFixed(2)}`
                                                                            ) : (
                                                                                <div className="tooltip">
                                                                                    <span
                                                                                        className="question-mark"
                                                                                        style={{ color: "white" }}
                                                                                    >
                                                                                        ?
                                                                                    </span>
                                                                                    <span
                                                                                        className="tooltip-text"
                                                                                        style={{ background: "black" }}
                                                                                    >
                                                                                        Amount financed required
                                                                                    </span>
                                                                                </div>
                                                                            )}
                                                                        </span>
                                                                    }
                                                                </div>
                                                                {/*<div class="table-cell">
{
    <span>
        {this.state.monthlypaymentc[this.state.selectedIndex] !== undefined && this.state.monthlypaymentc[this.state.selectedIndex] !== 0 ? `$${this.state.monthlypaymentc[this.state.selectedIndex].toFixed(2)}` : (
            <div className="tooltip">
                <span className="question-mark" style={{color:"white"}}>?</span>
                <span className="tooltip-text" style={{background:"black"}}>Amount financed required</span>
            </div>
        )}
    </span> 
}
                        </div>*/}
                                                                {/*<div class="table-cell">{this.state.totalamountfinanced}</div>*/}
                                                                <div class="table-cell">
                                                                    {this.state.selectedTerms} {"Month"}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    Do you want to create new Quote
                                                    {localStorage.getItem("CustomerType") == "Dealer" || this.state.vpnbygetquote == true
                                                        ? ""
                                                        : " or update quote?"}
                                                    {
                                                        <div>
                                                            {/*checked={this.state.quotedefineStatus1 === option.value}*/}
                                                            {optionsquote.map((option) => (
                                                                <label
                                                                    key={option.value}
                                                                    style={{
                                                                        marginTop: "10px",
                                                                        marginRight: "10px",
                                                                    }}
                                                                >
                                                                    <input
                                                                        style={{ verticalAlign: "middle" }}
                                                                        type="radio"
                                                                        name="radioGroup"
                                                                        value={option.value}
                                                                        onChange={
                                                                            this.handleQuoteDefineStatusChange
                                                                        }
                                                                    />
                                                                    {option.label}
                                                                </label>
                                                            ))}
                                                        </div>
                                                    }
                                                    <span style={{ display: "none" }}>
                                                        {" "}
                                                        Would You Like To Remove?
                                                    </span>{" "}
                                                    &nbsp;&nbsp;
                                                </div>
                                            </div>
                                        )}


     {this.state.showcustomerPopup2 && (
                                            <div className="popup residual residual_model_box">
                                                <div className="popup-content">
                                                    {/* Popup content */}
                                                <h6 style={{"color":"white"}}>Selected Terms:-{this.state.popupmonth} Months</h6>
                                                    <span
                                                        style={{
                                                            cursor: "pointer",
                                                            float: "right",
                                                            fontWeight: "700",
                                                            border: "1px solid white",
                                                            background: "white",
                                                            color: "#002f6c",
                                                            borderRadius: "19px",
                                                            width: "24px",
                                                        }}
                                                        title="close"
                                                        class="close-icon"
                                                        onClick={this.closecustomerPopup2}
                                                    >
                                                        X
      
                                                    </span>

<input
                                                                id="editableInput1222"
                                                                
                                                                className="form-control"
                                                                type="text"
                                                                    value={this.state.estimateresidualvaluepopup}
                                                                onChange={this.handlerv}
                                                              
                                                             
                                                            />


                                                       <button type="button"     style={{
                                                                    borderColor: "#f8ea55",
                                                                    borderRadius: "4px",
                                                                    boxSizing: "border-box",
                                                                    borderWidth: "1px",
                                                                    backgroundColor: "#f8ea55",
                                                                    fontSize: "14px",
                                                                    fontWeight: "600",
                                                                    padding: "6px 12px",
                                                                    color: "#002f6c",
                                                                    minWidth: "140px",
                                                                    marginRight: "5px",
                                                                    marginTop: "5px",
                                                                }}
                                                             onClick={()=>this.updatevalue(this.state.popupmonth)}>Update</button>     


</div>
</div>

                                                    )}




                                        {this.state.showPopup && (
                                            <div className="popup">
                                                <div className="popup-content">
                                                    {/* Popup content */}
                                                    <span
                                                        style={{
                                                            cursor: "pointer",
                                                            float: "right",
                                                            fontWeight: "700",
                                                            border: "1px solid white",
                                                            background: "white",
                                                            color: "#002f6c",
                                                            borderRadius: "19px",
                                                            width: "24px",
                                                        }}
                                                        title="close"
                                                        class="close-icon"
                                                        onClick={this.closePopup}
                                                    >
                                                        X
                                                    </span>
                                                    <div
                                                        className="model-top-section"
                                                    >
                                                        <div>
                                                            Loan Term: {this.state.selectedTerms} {"Month"}
                                                        </div>
                                                        <div>PIF Rate: {this.state.mycustomfu}</div>
                                                        <div>
                                                            Monthly Pif Amount:{" "}
                                                            {
                                                                <span>
                                                                    {this.state.monthlypayment[
                                                                        this.state.selectedIndex
                                                                    ] !== undefined &&
                                                                    this.state.monthlypayment[
                                                                        this.state.selectedIndex
                                                                    ] > 0 ? (
                                                                        (() => {
                                                                            const numbersAndDecimal =
                                                                                this.state.monthlypayment[
                                                                                    this.state.selectedIndex
                                                                                ]
                                                                                    .toString()
                                                                                    .replace(/[^0-9.]/g, ""); // Keep only digits and dot
                                                                            const sections =
                                                                                numbersAndDecimal.split("."); // Split at the dot, if present
                                                                            const integerPart = sections[0].replace(
                                                                                /\B(?=(\d{3})+(?!\d))/g,
                                                                                ","
                                                                            ); // Add commas for thousand separators
                                                                            const decimalPart = sections[1]
                                                                                ? sections[1].substring(0, 2)
                                                                                : ""; // Allow up to 4 decimal places
                                                                            const formattedValue =
                                                                                sections.length === 2
                                                                                    ? `${integerPart}.${decimalPart}`
                                                                                    : integerPart;
                                                                            return `$${formattedValue}`;
                                                                        })()
                                                                    ) : (
                                                                        <div className="tooltip">
                                                                            <span
                                                                                className="question-mark"
                                                                                style={{ color: "white" }}
                                                                            >
                                                                                ?
                                                                            </span>
                                                                            <span
                                                                                className="tooltip-text"
                                                                                style={{ background: "black" }}
                                                                            >
                                                                                Amount financed required
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                </span>
                                                            }
                                                        </div>
                                                        <button
                                                            style={{ color: "white", borderColor: "white" }}
                                                            class="btn btn-outline-secondary"
                                                            onClick={this.toggleTable}
                                                        >
                                                            {" "}
                                                            {this.state.isExpanded
                                                                ? "Hide Amortization"
                                                                : "Show Amortization"}
                                                        </button>
                                                    </div>
                                                    <div
                                                        class="horizontal-table1 ajay"
                                                        style={{ display: "none" }}
                                                    >
                                                        <div class="table-header1">
                                                            <div class="table-header-item">MSRP</div>
                                                            <div class="table-header-item">PIF rate</div>
                                                            <div class="table-header-item">
                                                                Monthly Pif Amount
                                                            </div>

                                                            {/* <div class="table-header-item">Monthly Conventional Amount</div>*/}
                                                            {/*<div class="table-header-item">Total Estimated Value</div>*/}
                                                            <div class="table-header-item">Loan Term</div>
                                                        </div>
                                                        <div class="table-content1">
                                                            <div class="table-row1">
                                                                <div class="table-cell">
                                                                    ${this.state.msrpvalue}
                                                                </div>
                                                                <div class="table-cell">
                                                                    ${this.state.pifrate}
                                                                </div>
                                                                <div class="table-cell">
                                                                    {
                                                                        <span>
                                                                            {this.state.monthlypayment[
                                                                                this.state.selectedIndex
                                                                            ] !== undefined &&
                                                                            this.state.monthlypayment[
                                                                                this.state.selectedIndex
                                                                            ] > 0 ? (
                                                                                `$${this.state.monthlypayment[
                                                                                    this.state.selectedIndex
                                                                                ].toFixed(2)}`
                                                                            ) : (
                                                                                <div className="tooltip">
                                                                                    <span
                                                                                        className="question-mark"
                                                                                        style={{ color: "white" }}
                                                                                    >
                                                                                        ?
                                                                                    </span>
                                                                                    <span
                                                                                        className="tooltip-text"
                                                                                        style={{ background: "black" }}
                                                                                    >
                                                                                        Amount financed required
                                                                                    </span>
                                                                                </div>
                                                                            )}
                                                                        </span>
                                                                    }
                                                                </div>
                                                                {/*<div class="table-cell">
                    {
                        <span>
                            {this.state.monthlypaymentc[this.state.selectedIndex] !== undefined && this.state.monthlypaymentc[this.state.selectedIndex] !== 0 ? `$${this.state.monthlypaymentc[this.state.selectedIndex].toFixed(2)}` : (
                                <div className="tooltip">
                                    <span className="question-mark" style={{color:"white"}}>?</span>
                                    <span className="tooltip-text" style={{background:"black"}}>Amount financed required</span>
                                </div>
                            )}
                        </span> 
                    }
                        </div>*/}
                                                                {/*<div class="table-cell">{this.state.totalamountfinanced}</div>*/}
                                                                <div class="table-cell">
                                                                    {this.state.selectedTerms} {"Month"}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div
                                                        style={{
                                                            maxHeight: "200px",
                                                            overflowY: "scroll",
                                                            marginBottom: "10px",
                                                        }}
                                                        dangerouslySetInnerHTML={{ __html: tabledata }}
                                                    />
                                                    <span style={{ display: "none" }}>
                                                        {" "}
                                                        Would You Like To Accept Quote?{" "}
                                                    </span>{" "}
                                                    &nbsp;&nbsp;
                                                </div>
                                                <div className="popup-buttons">
                                                    {/* "Next" button */}
                                                    {localStorage.getItem("CustomerType") !== "Dealer" &&
                                                        this.state.quotedefineStatus1 && (
                                                            <button
                                                                onClick={this.handlePrintClick}
                                                                style={{
                                                                    borderColor: "#f8ea55",
                                                                    borderRadius: "4px",
                                                                    boxSizing: "border-box",
                                                                    borderWidth: "1px",
                                                                    backgroundColor: "#f8ea55",
                                                                    fontSize: "14px",
                                                                    fontWeight: "600",
                                                                    padding: "6px 12px",
                                                                    color: "#002f6c",
                                                                    minWidth: "140px",
                                                                    marginRight: "5px",
                                                                }}
                                                            >
                                                                Generate Coupon
                                                            </button>
                                                        )}

                                                    {!this.state.vpnbygetquote && (
                                                        <button
                                                            onClick={this.handleNextClick}
                                                            style={{
                                                                borderColor: "green",
                                                                borderRadius: "4px",
                                                                boxSizing: "border-box",
                                                                borderWidth: "1px",
                                                                background:
                                                                    "linear-gradient(rgb(179, 223, 178), rgb(24, 227, 1))",
                                                                fontSize: "14px",
                                                                fontWeight: "600",
                                                                padding: "6px 12px",
                                                                color: "white",
                                                                minWidth: "140px",
                                                                marginRight: "5px",
                                                            }}
                                                        >
                                                            Accept Quote
                                                        </button>
                                                    )}

                                                    {/* "Cancel" button */}
                                                    {!this.state.vpnbygetquote && (
                                                        <button
                                                            onClick={this.handleCancelClick}
                                                            style={{
                                                                borderColor: "red",
                                                                borderRadius: "4px",
                                                                boxSizing: "border-box",
                                                                borderWidth: "2px",
                                                                backgroundColor: "red",
                                                                fontSize: "14px",
                                                                fontWeight: "600",
                                                                padding: "6px 12px",
                                                                color: "white",
                                                                minWidth: "140px",
                                                                marginRight: "5px",
                                                            }}
                                                        >
                                                            Decline Quote
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        )}

                                        <button
                                            style={{
                                                textTransform: "uppercase",
                                                fontSize: "16px",
                                                fontWeight: "600",
                                                color: "white",
                                                borderRadius: "20px",
                                                border: "2px solid green",
                                                background:
                                                    "linear-gradient(to bottom, #b3dfb2, #18e301)",
                                                color: "white",
                                            }}
                                            class="btn btn-outline-secondary"
                                            type="button"
                                            onClick={this.finalQuote}
                                        >
                                            Confirm Quote
                                        </button>

                                        <div
                                            style={{
                                                marginTop: "40px",
                                                fontSize: "20px",
                                                fontWeight: "600",
                                            }}
                                        >
                                            Customize your Pay-It-forward Loan!
                                        </div>
                                        <div
                                            style={{
                                                marginTop: "10px",
                                                fontSize: "16px",
                                                fontWeight: "500",
                                            }}
                                        >
                                            GAIN more Equity when you CLICK On THE pencil ICON AND
                                            Reduce The Residual Value(END BALANCE).
                                        </div>
                                        {this.state.showCancelMessage && (
                                            <div
                                                className="message"
                                                style={{
                                                    fontSize: "1.1rem",
                                                    margin: "10px",
                                                    color: "green",
                                                    fontWeight: "500",
                                                }}
                                            >
                                                Thank you for visit, Please visit next time.
                                            </div>
                                        )}

                                        {this.state.showAcceptMessage && (
                                            <div
                                                className="message"
                                                style={{
                                                    fontSize: "1.1rem",
                                                    margin: "10px",
                                                    color: "green",
                                                    fontWeight: "500",
                                                }}
                                            >
                                                Quote accepted
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </FormWizard.TabContent>
                            <div style={{ display: "flex", paddingTop: "30px" }}>
                                <a
                                    href="/dashboard"
                                    style={{
                                        color: "red",
                                        fontSize: "24px",
                                        fontWeight: "bold",
                                        margin: "0 auto",
                                        display: "flex",
                                    }}
                                >
                                    <img
                                        src="/assets/img/logo.png"
                                        style={{ width: "250px" }}
                                        alt="Logo"
                                    />
                                </a>
                            </div>
                        </>
                    )}
                </FormWizard>
                {/* add style */}
                <style>{`
                @import url("https://cdn.jsdelivr.net/gh/lykmapipo/themify-icons@0.1.2/css/themify-icons.css");
            `}</style>
            </>
        );
    }
}
export default Dashboard;
